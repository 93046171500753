import React, { useState } from "react";
import {EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton} from "react-share"
import "./Pages.css";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const PopUp = props => {

    const cpbrdData = window.location.href+"home/"+props.content.id;


    const [companyName, setCompanyName] = useState("");
    const [jobRole, setJobRole] = useState("");
    const [rating, setRating] = useState(0);
    const [applicationProcess, setApplicationProcess] = useState("");
    const [interviewProcess, setInterviewProcess] = useState("");
    const [date, setDate] = useState("");
    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const [suggestions, setSuggestions] = useState("");

    const view = () => {
        setCompanyName(companyName);
        setJobRole(jobRole);
        setRating(rating);
        setApplicationProcess(applicationProcess);
        setInterviewProcess(interviewProcess);
        setDate(date);
        setInterviewQuestions(interviewQuestions);
        setSuggestions(suggestions);
    }


    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={props.handleClose}>x</span>
          <div>
              <p>- Please copy the below link and share.</p>
          <div className="">
                <input type="text" value={cpbrdData} className="share-inputBox" disabled></input>


                
                <CopyToClipboard text={cpbrdData}>
                    {/* <i className="far fa-copy codeblock-copy" /> */}
                    <i className="share-copyLink"><button>copy link</button></i>
                </CopyToClipboard>


          </div>
          <br/>
          <div style={{textAlign:"center"}}> --- OR ---</div>
          <div>

          <div className="text-center share-div">
            <strong>Share via : </strong>
                    <EmailShareButton 
                    url={cpbrdData} 
                    to = "intereviewed@gmail.com"
                    subject="subject"
                    body={"hey there, pls share my link" + <a href={cpbrdData}>Link</a>}
                    ><span className="feedback-icon fas fa-envelope"> Email</span> </EmailShareButton>
                    <FacebookShareButton url={cpbrdData}> <span className="feedback-icon fab fa-facebook"> Facebook </span></FacebookShareButton>
                    <WhatsappShareButton url={cpbrdData}><span className="feedback-icon fab fa-whatsapp"> Whatsapp </span></WhatsappShareButton>
                    <TwitterShareButton url={cpbrdData}><span className="feedback-icon fab fa-twitter"> Twitter </span></TwitterShareButton>
                </div>

          </div>


          <div className="contribution-container div1">
            <div className= "title">Update the added Interview Experience
            </div>
            <hr/>
            <br/>
            <form method="POST" onSubmit="">
                <div className="user-details">
                    <div className="input-box">
                        <span className="details">
                            Company Name</span><br/>
                        <input 
                            type="text" 
                            placeholder="Enter Company Name" 
                            value={props.content.companyName}
                            onChange={(event) => {setCompanyName(event.target.value)}}
                        />

                    </div>

                    <div className="input-box">
                        <span className="details">
                        Title of job</span><br/>
                        <input 
                          type="text" 
                          placeholder="Enter Job Title"
                          value={props.content.jobRole} 
                          onChange={(event) => {setJobRole(event.target.value)}}/>
                        
                    </div>


                    <div className="input-box">
                        <span className="details">
                            Application Process</span><br/>
                        <textarea 
                          type="text" 
                          placeholder="Your Application Process ..."
                          value={props.content.applicationProcess} 
                          onChange={(event) => {setApplicationProcess(event.target.value)}}/>
                        
                    </div>


                    <div className="input-box">
                        <span className="details">
                            Interview Process</span><br/>
                        <textarea 
                          type="text" 
                          placeholder="Enter Your Interivew Process ..."
                          value={props.content.interviewProcess} 
                          onChange={(event) => {setInterviewProcess(event.target.value)}}/>
                        
                    </div>

                    <div className="input-box">
                        <span className="details">
                            Interview Questions </span><br/>
                        <textarea 
                        type="text" 
                        placeholder="Enter Your Interview Questions ..." 
                        value={props.content.interviewQuestions}
                        onChange={(event) => {setInterviewQuestions(event.target.value)}}/>
                        
                    </div>

                    {/* {display} */}

                    {/* <div className="input-box">
                        <span className="details">
                            Add more Interview Questions.</span><br/>
                            <input type="button" value="+" onClick={onClickAddQuestion}/>                    
                    </div> */}
                    
                    <div className="input-box">
                        <span className="details">
                            Suggestions</span><br/>
                        <textarea 
                          type="text" 
                          placeholder="Enter Your Suggestions to Viewers..."
                          value={props.content.suggestions}
                          onChange={(event) => {setSuggestions(event.target.value)}}/>
                        
                    </div>

                </div>
                <div className="button">
                        <input type="submit" value="Submit"/>
                        
                    </div>
                
            </form>
            
            </div>

          <br/>
          </div>
          {view}
        </div>
      </div>
    );
  };

export default PopUp;