
import React, {useState} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../Footerpage";
import { NavLink } from 'react-router-dom'
import { Tools } from '../Tools/ToolsPage'
//import { JsonFormatter } from 'react-json-formatter'

import { useDropzone } from "react-dropzone";



import ReactJson from 'react-json-view'
import { FaSave } from "react-icons/fa";




function Base64ToImage(){

 
    const [urlString, setUrlString] = useState("data:text/plain;base64,aGVsbG8gd29ybGQ=");
    const [outputString, setOutputString] = useState("");
    const [encodeString, setEncodeString] = useState("");
    
    const [base64data, setBase64Data] = useState("");
    const [base64Split, setBase64Split] = useState("");
    const [base64Html, setBase64Html] = useState("");
    const [base64Css, setBase64Css] = useState("");
    const [checkJson, setCheckJson] = useState(false);
    const [clickjsonData, setClickJsonData] = useState("");
    // const [jsonError, setJsonError] = useState("");

    const [clipboard, setClipBoard] = useState("");
    // const [checkCollapse, setCheckCollapse] = useState(true);

    const [errorMsg, setErrorMsg] = useState("");
    const [CheckErrorMsg, setCheckErrorMsg] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(null);

    var fileInput = React.createRef();
 
    const currentTool = "Base64 To Image"
    var cpbrdData1 = "copy";
    const [outputData, setOutputData] = useState([]);

    
    const EncodeUrl = () => {

        //alert(acceptedFiles[0]);
        //setSelectedFile(URL.createObjectURL(acceptedFiles[0]));

        //setOutputData(dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt'))
        setOutputData(dataURLtoFile(urlString,'intereviews.jpg'))

        //encodeImageFileAsURL(acceptedFiles[0]);

    }



    const diffToast = () => {

        setClipBoard(JSON.stringify(clickjsonData, null, '\t'));

        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    const handleInputString = (event) => {

        setUrlString(event.target.value)
        //setEncodeString(base32Encode(event.target.value));
    }

    function dataURLtoFile(dataurl, filename) {
 
        
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
        
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            //alert("mime" + n);
            var img = new Image();
            img.src = dataurl;            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        //alert(new File([u8arr], filename, {type:mime}))
        //return new File([u8arr], filename, {type:mime});
        return img;
    }

    function Base64ToImage(base64img, callback) {
        var img = new Image();
        img.onload = function() {
            callback(img);
        };
        img.src = base64img;
    }

    // Base64ToImage(base64img, function(img) {
        
    // }
    
    //Usage example:
    var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt');
    console.log("file : "+file);



    function encodeImageFileAsURL(element) {
        alert(element)
        var file = element;
        var reader = new FileReader();
        reader.onloadend = function() {
          console.log('RESULT', reader.result)
          alert("Result : "+reader.result)
          setCheckJson(true);
          //setBase64Data(reader.result);
          setBase64Html("<img src = '"+reader.result+"'");
          setBase64Css("background-image: url("+reader.result+");");
          setBase64Split(reader.result.split(",")[1])

        }
        reader.readAsDataURL(file);

      }


      function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
        console.log('page to reload')
    }


   const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  
   const files = acceptedFiles.map(file => (
    <>
    <h4>Preview</h4>
    <ul>
     <li key={file.path}>
       {file.path} - {file.size} bytes
       <img src={URL.createObjectURL(file)} className="dragPreviewImg" alt={file.path}/>       
     </li>
     </ul>
     </>
   ));

    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>Base64 To Image Converter</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Base64 To Image Converter.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">

                            {/* <div className="JSON-title">
                            <h1>JSON Formatter</h1>
                            </div> */}
                            <ToastContainer/>

                            <div className="blog-page-content">
                                <div>

                                    {/* <div className="json-output-top"> */}
                                    <div><h5><strong>Paste or Enter you plain text below : </strong></h5></div>
                                    {/* </div> */}
                                    <div className="jsonFormat-textarea" >
                                        <textarea type="text" placeholder="Enter your PLAIN Data here ..." onChange={(event) => {setUrlString(event.target.value);setCheckJson(true);}} />
                                    </div>



                                    {/* <div className="text-center json-copyLink convert-button-size1"><button onClick={EncodeUrl}>Convert to Base64</button>
                                    </div> */}
                    
                    {/* <i className="share-copyLink"><button onClick={DecodeUrl}>Decode</button></i> */}
                    
                    {/* <CopyToClipboard text={jsonData}>
                        <i className="share-copyLink" title="copy" ><button onClick={diffToast}>Copy</button></i>
                    </CopyToClipboard> */}



                    {/* <pre>
        <code>
          {JSON.stringify(jsonData, null, 2)}
        </code>
      </pre> */}
        <br/>



        {checkJson ?
        <>
        <img src={"data:image/jpeg;base64,"+urlString} className="dragPreviewImg"/>
        <br/>
        <br/>
        <br/>
        <a href={"data:image/jpeg;base64,"+urlString} download>                                    
        <div className="text-center json-copyLink convert-button-size1"><button >Download from here</button>
        </div></a></>
        :
            <div className="previewSpace">
                Preview
            </div>
        }
      <br/>

                    </div>
                    <br/>
                </div>
            </div>

        </div>

        <div className="blogLayout">

<div className="json-innerPage-layout">
    <div className="blog-page-content">
    <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
    <strong> About</strong></h5></div>
        <hr/>
        <div><h5>Base64 To Image Converter</h5></div>
        <div>Checkout this online Base64 To Image Converter Tool, which will helps to convert Image file to Base64 String.
               </div>
        <div></div>

    </div>
</div>
</div>

</div>

<div>                

<div className="tools-topics-div">
    <div className="tools-topics">
        <div className="title">
        <b className="fas fa-cog" />
             <strong> Other Tools</strong>
        </div>
        {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

        return(
            <div className="tools-topic-value" >   
            <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                <div className="blog-all-topics-name">
                    {items.name}
                </div>       
            </NavLink>
           </div>
           )
        })}
    </div>
</div>
<div className="tools-topics-div">
    <div>
        {/* <!-- side-vertical --> */}
        <ins class="adsbygoogle"
             style={{display:'block'}}
            data-ad-client="ca-pub-8123049304648947"
            data-ad-slot="5905496439"
            data-ad-format="auto"
            data-full-width-responsive="true">     
        </ins>
    </div>
</div>
</div>

</div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default Base64ToImage;


