import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Route,
  //Redirect,
  Switch,
} from 'react-router-dom';

import {Helmet} from "react-helmet";

import Home from './components/Home'
import About from './components/About';
import Navbar from './components/Navbar';
import Contributions from './components/Contributions';
import InterviewQuestions from './components/InterviewQuestions';
import Java from './components/InterviewQuestionPages/Java';
import Blog from './components/InterviewQuestionPages/Blog';
import GmailPaginationUsingReact from './components/blogPages/GmailPaginationUsingReact';
import SquareRootOfGivenNumber from './components/blogPages/SquareRootOfGivenNumber';
import PowerOfANumber from './components/blogPages/PowerOfANumber';
import TryWithResources from './components/blogPages/TryWithResources';
import TryWithResourcesOnJava9 from './components/blogPages/TryWithResourcesOnJava9';
import LoginApprovalPage from './components/database/LoginApprovalPage';
import IndividualReview from './components/IndividualReview'
import CompanyReview from './components/InterviewQuestionPages/CompanyReview';
import OutsideAlerter from './components/OutsideAlerter';
import PassValueToOnClickHandlerInReact from './components/blogPages/PassValueToOnClickHandlerInReact';
import NPMStartErrorDueToWatcherLimitIsLessInLinux from './components/blogPages/NPMStartErrorDueToWatcherLimitIsLessInLinux';
import URLEncoderAndDecoder from './components/Tools/URLEncoderAndDecoder';
import WindowEventsInReact from './components/blogPages/WindowEventsInReact';
import ConnectingGoogleAnalyticsToReactProject from './components/blogPages/ConnectingGoogleAnalyticsToReactProject';

import { useLocation } from "react-router-dom";
import ErrorPage from './components/personalPages/ErrorPage';
import InstagramDownloader from './components/Tools/InstagramDownloader';
import JsonFormatter from './components/Tools/JsonFormatters';
import JsonToXml from './components/Tools/JsonToXml';
import JsonToCSV from './components/Tools/JsonToCSV';
import Base64Encode from './components/Tools/Base64Encode';
import Base64Decode from './components/Tools/Base64Decode';
import XmlFormatter from './components/Tools/XmlFormatter';
import ToolsPage from './components/Tools/ToolsPage';
import Base32Encode from './components/Tools/Base32Encode';
import Base32Decode from './components/Tools/Base32Decode';
import DocxToPdf from './components/Tools/DocxToPdf';
import HtmlEncode from './components/Tools/HtmlEncode';
import HtmlDecode from './components/Tools/HtmlDecode';
import JpgToPng from './components/Tools/JpgToPng';
import ImageToBase64 from './components/Tools/ImageToBase64';
import Base64ToImage from './components/Tools/Base64ToImage';
import AlexaRankChecker from './components/Tools/AlexaRankChecker';
import QRCodeScanner from './components/Tools/QRCodeScanner';
import QRCodeGenerator from './components/Tools/QRCodeGenerator';
import YoutubeDownloader from './components/Tools/Downloader/YoutubeDownloader';
import TwitterQRCodeGenerator from './components/Tools/QRCode/TwitterQRCodeGenerator';
import YoutubeQRCode from './components/Tools/QRCode/YoutubeQRCode';
import InstagramQRCode from './components/Tools/QRCode/InstagramQRCode';
import JsonToString from './components/Tools/JsonUtilities/JsonToString';
import TextQRCodeGenerator from './components/Tools/QRCode/TextQRCodeGenerator';
import SmsQRCodeGenerator from './components/Tools/QRCode/SmsQRCodeGenerator';
import CallQRCodeGenerator from './components/Tools/QRCode/CallQRCodeGenerator';
import WifiQRCodeGenerator from './components/Tools/QRCode/WifiQRCodeGenerator';
import MailQRCodeGenerator from './components/Tools/QRCode/MailQRCodeGenerator';
import UrlQRCodeGenerator from './components/Tools/QRCode/UrlQRCodeGenerator';
import RegexChecker from './components/Tools/StringUtilities/RegexChecker';


// import createHistory from 'history/createBrowserHistory'

// import ReactGA from "react-ga";




// const history = createHistory()
// ReactGA.initialize('G-YCSZ358NQF');
// alert("checking..")
// history.listen((location, action) => {
//     ReactGA.pageview(location.pathname + location.search);
//     console.log(location.pathname)
// });


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


const App = () => {




  return (
    <Router >
      <ScrollToTop/>
      <Helmet>
        <meta charSet="utf-8" />
        <title>InteReviews</title>
        <link rel="canonical" href="https://wwww.intereviews.com" />
        
      </Helmet>
      <header>
      <Navbar/>
      </header>
      <main>
        <Switch>
          <Route path="/" exact>
            <Home/>
          </Route>
          <Route path="/home" exact>
            <Home/>
          </Route>
          <Route path="/about" exact>
            <About/>
          </Route>
          <Route path="/contributions" exact>
            <Contributions/>
          </Route>
          <Route path="/interviewQuestions" exact>
            <InterviewQuestions/>
          </Route>

          <Route path="/blog" exact>
            <Blog/>
          </Route>
          <Route path="/about/login" component={LoginApprovalPage} exact/>
          
          <Route path="/home/:ids" component={IndividualReview} exact/>

          <Route path="/interviewQuestions/:topics" component={Java} exact/>
          <Route path="/blog/gmail-pagination-view-using-react" component={GmailPaginationUsingReact} exact/>
          <Route path="/blog/square-root-of-given-number" component={SquareRootOfGivenNumber} exact/>
          <Route path="/blog/power-of-a-number" component={PowerOfANumber} exact/>
          <Route path="/blog/Try-With-Resources" component={TryWithResources} exact/>
          <Route path="/blog/Try-With-Resources-on-Java-9" component={TryWithResourcesOnJava9} exact/>
          <Route path="/blog/How-to-pass-values-to-OnClickHandler-in-react" component={PassValueToOnClickHandlerInReact} exact/>
          <Route path="/blog/NPM-Start-Error-Due-To-Watcher-Limit-Reached-in-Linux" component={NPMStartErrorDueToWatcherLimitIsLessInLinux} exact/>
          <Route path="/blog/Window-Events-in-react" component={WindowEventsInReact} exact/>
          <Route path="/blog/How-to-connect-Google-Analytics-in-React-Project" component={ConnectingGoogleAnalyticsToReactProject} exact/>
          

          <Route path="/about/outside" component={OutsideAlerter} exact/>

          <Route path="/URL-Encoder-Decoder" component={URLEncoderAndDecoder} exact/>
          <Route path="/tools/InstagramDownloader" component={InstagramDownloader} exact/>
          <Route path="/tools/URL-Encoder-Decoder" component={URLEncoderAndDecoder} exact/>
          <Route path="/tools/JSON-Formatter" component={JsonFormatter} exact/>
          <Route path="/tools/JSON-To-XML" component={JsonToXml} exact/>
          <Route path="/tools/JSON-To-CSV" component={JsonToCSV} exact/>
          <Route path="/tools/Base64-Encode" component={Base64Encode} exact/>
          <Route path="/tools/Base64-Decode" component={Base64Decode} exact/>
          <Route path="/tools/Base32-Encode" component={Base32Encode} exact/>
          <Route path="/tools/Base32-Decode" component={Base32Decode} exact/>
          <Route path="/tools/XML-Formatter" component={XmlFormatter} exact/>
          <Route path="/tools/DOCX-To-PDF" component={DocxToPdf} exact/>
          <Route path="/tools/HTML-Encode" component={HtmlEncode} exact/>
          <Route path="/tools/HTML-Decode" component={HtmlDecode} exact/>
          <Route path="/tools/JPG-To-PNG" component={JpgToPng} exact/>
          <Route path="/tools/Image-To-Base64" component={ImageToBase64} exact/>
          <Route path="/tools/Base64-To-Image" component={Base64ToImage} exact/>
          <Route path="/tools/Alexa-Rank-Checker" component={AlexaRankChecker} exact/>
          <Route path="/tools/QR-Code-Scanner" component={QRCodeScanner} exact/>
          <Route path="/tools/Youtube-Downloader" component={YoutubeDownloader} exact/>

          <Route path="/tools/JSON-To-String" component={JsonToString} exact/>
          <Route path="/tools/QR-Code-Generator" component={QRCodeGenerator} exact/>
          <Route path="/tools/Twitter-QR-Code-Generator" component={TwitterQRCodeGenerator} exact/>
          <Route path="/tools/Youtube-QR-Code-Generator" component={YoutubeQRCode} exact/>
          <Route path="/tools/Instagram-QR-Code-Generator" component={InstagramQRCode} exact/>
          <Route path="/tools/Text-QR-Code-Generator" component={TextQRCodeGenerator} exact/>
          <Route path="/tools/SMS-QR-Code-Generator" component={SmsQRCodeGenerator} exact/>
          <Route path="/tools/Call-QR-Code-Generator" component={CallQRCodeGenerator} exact/>
          <Route path="/tools/WIFI-QR-Code-Generator" component={WifiQRCodeGenerator} exact/>
          <Route path="/tools/Mail-QR-Code-Generator" component={MailQRCodeGenerator} exact/>
          <Route path="/tools/URL-QR-Code-Generator" component={UrlQRCodeGenerator} exact/>
          
          <Route path="/tools/Regex-Checker" component={RegexChecker} exact/>

          <Route path="/tools" component={ToolsPage} exact/>
          <Route path="/:companySearchName" component={CompanyReview} exact/>

          <Route path="*" component={ErrorPage} />

          {/* <Redirect to="/"/> */}
        </Switch>
      </main>
    </Router>
  );
}



export default App;
