
import React, {useRef} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiniFooter from "../MiniFooter";
import { NavLink } from 'react-router-dom'
//import {EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton} from "react-share"

import { BlogPages } from '../InterviewQuestionPages/Blog'

import {Helmet} from 'react-helmet';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


function PassValueToOnClickHandlerInReact(){

    const cpbrdData1 = "//OnClickHandler Function\nconst onClickHandler = ( event ) => {\n\tconsole.log( event.currentTarget.dataset.value ) ;\n};\n\n//Html code and Calling the Above Functions\n<div onClick={ onClickHandler } data-value=\"onClickValue\" > Click Here </div>";
    const cpbrdData2 = "//OnClickHandler Function\nconst onClickHandler = ( value ) => {\n\tconsole.log( value ) ;\n};\n\n//Html code and Calling the Above Functions\n<div onClick={ () => onClickHandler( \"onClickValue\" ) }> Click Here </div>";

    const relatedTopics = "React"

    const myRef = useRef(null);
    const scrollPage = () => myRef.current.scrollIntoView() 

    // const popUpAlert= () => {
    //     alert("Copied to Clipboard");
    // }

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }


    return(
        <>
        
        <Helmet>
                <title>How to pass value to OnClikHandler in React</title>
                <meta name="description" content=" In this tutorial, we are going to learn about passing value to OnClickHandler on ReactJs. " />
        </Helmet>

        <div className="blogLayout">
            <div className="blog-page-layout">
                <h1>
                <div className="title">
                    How to pass value to OnClikHandler in React
                </div>
                </h1>
                <ToastContainer/>
                <br/>
                <div className="blog-page-tag-border">
                <div className="parent">
                    <div className="blog-page-tag" onClick={scrollPage}>ReactJs</div>
                </div>
                </div>
                <hr/>

                <div className="blog-page-content">
                    <div>
                        <strong>In this tutorial,</strong> we are going to learn about passing value to <strong>OnClickHandler</strong> on <strong>ReactJs</strong>.
                    </div>
                    <br/>
                    <div>
                        We can pass the values on two ways.
                    </div>

                    <div>
                        <ul>
                            <br/>
                            <li>
                                <p>
                                <div>
                                
                                <strong>Example 1 </strong> : <strong>data-xyz</strong> attributes in a DOM element will read by <strong>dataset</strong>.
                                </div>
                                <br/>   
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData1}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">


                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classParrotGreen">{"//OnClickHandler Function"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const "}</c>
                                            <c className="codeblock-setconst">{" onClickHandler "}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classname">{" event "}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-tagvalue">{" => "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classname bold-font">{"console"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-setconst">{"log"}</c>
                                            <c className="codeblock-classnameEqual">{"( "}</c>
                                            <c className="codeblock-classname">{"event"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-classname">{"currentTarget"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-classname">{"dataset"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-classname">{"value"}</c>
                                            <c className="codeblock-classnameEqual">{" ) "}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>
                                        


                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c></c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <p></p>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classParrotGreen">{"//Html code and Calling the Above Functions"}</c>
                                        </code>
                                        </div>

                                    
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-classname">{" onClick"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-setconst">{" onClickHandler "}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-classname">{" data-value"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-classnamevalue">{"\"onClickValue\" "}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                            <c className="codeblock-classnameEqual">{" Click Here "}</c>
                                            <c className="codeblock-tag">{"</"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>


{/* const filterTopic = (event) => {
        setBlogListCount(BlogPages.length)
       // alert(event.currentTarget.dataset.div_name);
       const selectTopic = event.currentTarget.dataset.div_name; */}




                                        
                                        </div>

                                    </div>

                                    <br/>

                                </p>
                            </li>

                            <li>
                                <p>
                                <div>
                                
                                <strong>Example 2 : </strong> by using inner Arrow Functions.
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData2}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">


                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classParrotGreen">{"//OnClickHandler Function"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const "}</c>
                                            <c className="codeblock-setconst">{" onClickHandler "}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classname">{" value "}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-tagvalue">{" => "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classname bold-font">{"console"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-setconst">{"log"}</c>
                                            <c className="codeblock-classnameEqual">{"( "}</c>
                                            <c className="codeblock-classname">{"value"}</c>
                                            <c className="codeblock-classnameEqual">{" ) "}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>
                                        


                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c></c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <p></p>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classParrotGreen">{"//Html code and Calling the Above Functions"}</c>
                                        </code>
                                        </div>

                                    
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-classname">{" onClick"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-classnameEqual">{" () "}</c>
                                            <c className="codeblock-tagvalue">{" => "}</c>
                                            <c className="codeblock-setconst">{" onClickHandler"}</c>
                                            <c className="codeblock-classnameEqual">{"( "}</c>
                                            <c className="codeblock-classnamevalue">{"\"onClickValue\" "}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                            <c className="codeblock-classnameEqual">{" Click Here "}</c>
                                            <c className="codeblock-tag">{"</"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>



                                        
                                        </div>

                                    </div>

                                    <br/>

                                </p>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
            <div>

            <div className="blog-topics-div">
                        <div className="blog-topics">
                            <div className="title">
                                Related Topics
                            </div>
                            {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                            //if (items.tag === "React"){

                                return(
                                    <div className="blog-related-topic-value">   
                                    <NavLink className="nav-link" to={items.link} exact>
                                        <div className="blog-all-topics-name">
                                            {items.name}
                                        </div>       
                                    </NavLink>
                                   </div>
                                   )
                            //}
                            })}

                            {/* <div className=" blog-topic-value">
                                <i className="fab fa-react"/>React
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-java"/>Java
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-js-square"/>JavaScript
                            </div> */}
                        </div>
                    </div>

                    <div>
                    {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                        
                    </ins>

                    </div>

            </div>
        </div>
        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>
        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href="https://web.whatsapp.com/send?text=https://interreview.com" target="_blank" rel="noreferrer">
                        <span className="fab fa-whatsapp share-invert"/>
                        </a>

                        <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site http://www.website.com.&amp;to=interreview@gmail.com"
   title="Share by Email">
                               <span className="fas fa-envelope share-invert"/>
                        </a>

                        {/* <span className = "fab fa-instagram share-invert"/> */}


                        <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.interreview.com/" title="fb" target="_blank">                        
                            <span className = "fab fa-facebook share-invert"/> 
                        </a>
                        <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=Interreview !&amp;url=https://www.interreview.com/&amp;hashtags" title="tw" target="_blank"> 
                            <span className = "fab fa-twitter share-invert"/> 
                        </a>
                        {/* <span className = "fab fa-snapchat share-invert"></span> */}
                        <a rel="noreferrer" href="https://telegram.me/share/url?url=https://www.interreview.com/ &amp;text=Interreview" title="tw" target="_blank"> 
                        <span className = "fab fa-telegram share-invert"></span>                
                        </a>
                    </div>
                    <h ref={myRef} />
                    <br/>
                    <hr/>
                    <br/>
                    <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                    //if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    //}
                })}

            </div>





                    </div>
                </div>

                <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <MiniFooter></MiniFooter>
        </>
    )

}

export default PassValueToOnClickHandlerInReact;