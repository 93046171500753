import React, { Component } from 'react'
import "./Pages.css"
import {NavLink} from 'react-router-dom'
import {WhatsappShareButton, LinkedinShareButton, PinterestShareButton} from "react-share"

class MiniFooter extends Component {
    render(){
        return(
            <div className="footer-content">
                <div>
                <NavLink to="/" exact className="footer-line">
                    <img src="/logo2.png" className="footer-app-logo" alt="logo"/>                                        
                    <img src="/logo192.png" className="footer-app-logo" alt="logo"/>

                </NavLink> 

                </div>
                <hr/>
                
                <div className="text-center share-div">
                    <WhatsappShareButton url="https://intereviews.com"><span className="fab fa-whatsapp share-icon" ></span></WhatsappShareButton>
                    {/* <a href="https://web.whatsapp.com/send?text=https://intereviews.com" target="_blank" rel="noreferrer">
                        <span className="fab fa-whatsapp share-icon"/>
                    </a> */}
                    {/* <span className = "fab fa-instagram share-icon"/> */}
                    <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.intereviews.com/" title="fb" target="_blank">                        
                        <span className = "fab fa-facebook share-icon"/> 
                    </a>
                    <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=intereviews !&amp;url=https://www.intereviews.com/&amp;hashtags" title="tw" target="_blank"> 
                        <span className = "fab fa-twitter share-icon"/> 
                    </a>
                    <LinkedinShareButton url="https://www.intereviews.com"><span className="fab fa-linkedin share-icon" ></span> </LinkedinShareButton>
                    {/* <span className = "fab fa-snapchat share-icon"> </span> */}
                    <a rel="noreferrer" href="https://telegram.me/share/url?url=https://www.intereviews.com/ &amp;text=intereviews" title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-icon"></span>     
                    <PinterestShareButton url="https://www.intereviews.com"><span className="fab fa-pinterest share-icon" ></span> </PinterestShareButton>           
                </a>
                </div>
                <div className="text-center share-div">
                    <a href="mailto:?subject=About Intereviews &amp;body=Suggestions :  &amp;to=contact%40intereviews.com" title="Share by Email">
                        <span className="feedback-icon fas fa-envelope"> Email Us</span>
                    </a>

                    {/* <a href=""> */}
                    <span className="feedback-icon fab fa-twitter"> Follow Us</span>
                    {/* </a> */}
                    <a href="https://www.facebook.com/harshit.rock.50/"  target="_blank" rel="noreferrer">
                    <span className="feedback-icon fab fa-facebook"> Like Us</span>
                    </a>


                </div>
                <div className="text-center ">
                    <NavLink to="/" exact className="footer-line">
                        <span className="footer-link">Intereviews.com</span>
                    </NavLink> 
                    <span>|  </span><i className="far fa-copyright"></i>copyright 2021
                    <NavLink to="/" exact className="footer-line"> </NavLink>
                </div>

            </div>
        );
    }
}

export default MiniFooter;