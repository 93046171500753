
import React, {useRef} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiniFooter from "../MiniFooter";
import { NavLink } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { BlogPages } from '../InterviewQuestionPages/Blog'

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


function TryWithResourcesOnJava9(){

    const cpbrdData1 = "class InterReviews {\n\tpublic static void main( String[] args ) {\n\t\ttry ( InputStream istream = new FileInputStream ( \"HLJ.txt\" ) ; ) {\n\t\t\tSystem.out.println ( \"character : \" + (char) istream.read() ) ;\n\t\t} catch ( Exception ex ) {\n\t\t\tex.printStackTrace() ;\n\t\t}\n\t}\n}";
    const cpbrdData2 = "class InterReviews {\n\tpublic static void main( String[] args ) {\n\t\tInputStream istream = new FileInputStream ( \"HLJ.txt\" ) ;\n\t\ttry ( istream ) {\n\t\t\tSystem.out.println ( \"character : \" + (char) istream.read() ) ;\n\t\t} catch ( Exception ex ) {\n\t\t\tex.printStackTrace() ;\n\t\t}\n\t}\n}";

    const relatedTopics = "Java"

    const myRef = useRef(null);
    const scrollPage = () => myRef.current.scrollIntoView() 
    //this.myRef = React.createRef() 

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }
    
    return(
        <>

            <Helmet>
                <title>Try-With-Resources on Java 9</title>
                <meta name="description" content=" In this tutorial, we are going to learn about Update of Try-With-Resource on ReactJs. " />
            </Helmet>

        <div className="blogLayout">
            <div className="blog-page-layout">
                <h1>
                <div className="title">
                    Try-With-Resources on Java 9
                </div>
                </h1>
                <ToastContainer/>
                <br/>
                <div className="blog-page-tag-border">
                <div className="parent">
                    <div className="blog-page-tag" onClick={scrollPage}>Java</div>
                </div>
                </div>
                <hr/>

                <div className="blog-page-content">
                    <div>
                        <strong>In this tutorial,</strong> we are going to learn about Update of <strong>Try-With-Resource</strong> on <strong>Java 9</strong>.
                    </div>
                    <br/>
                    <div>
                        It was first introduce on <strong>Java 7</strong> and then got some update on <strong>Java 9</strong>.
                    </div>
                    <br/>
                    <div>
                        <ul>
                            <li>
                                <strong>Try-With-Resources</strong> are mainly used to close the <strong>resource automatically</strong>.
                            </li>
                            <li>Resources are : Files, Network, Connections ect.,</li>
                            <li>it uses AutoCloseable interface to close the resources automatically.</li>
                            <li><strong>Java 7</strong> has some limitations to implement <strong>Try-With-Resource</strong>, Where they resolved those limitation on <strong>Java 9 Update</strong></li>

                        </ul>
                    </div>

                    <div>
                        <ul>
                            <br/>
                            <li>
                                <p>
                                <div>
                                
                                <strong>Try-With-Resources</strong> Example.
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData1}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classname bold-font">{"class "}</c>
                                                <c className="codeblock-classnamevalue">{"InterReviews"}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" public "}</c>
                                                <c className="codeblock-classname bold-font">{"static"}</c>
                                                <c className="codeblock-tagvalue bold-font">{" void"}</c>
                                                <c className="codeblock-classnamevalue bold-font">{" main"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"String"}</c>
                                                <c className="codeblock-classnameEqual">{"[] "}</c>
                                                <c className="codeblock-css">{" args"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" try  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" InputStream"}</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-classname">{" new "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" FileInputStream"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"HLJ.txt\" "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" ; "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" System"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"out"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"println"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"character : \" "}</c>
                                                <c className="codeblock-classnameEqual">{" + "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-tagvalue bold-font">{"char"}</c>
                                                <c className="codeblock-classnameEqual">{") "}</c>                                                
                                                <c className="codeblock-css">{"istream"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"read"}</c>
                                                <c className="codeblock-classnameEqual">{"("}</c>
                                                <c className="codeblock-classnameEqual">{")"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>

                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                                <c className="codeblock-classname bold-font">{" catch  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" Exception "}</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"printStackTrace"}</c>
                                                <c className="codeblock-classnameEqual">{"() ; "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classnameEqual">{"}"}</c>
                                            </code>
                                            </div>
                                        
                                        </div>

                                    </div>

                                    <br/>
                                    <div>
                                        - If we check the above code, it will automatically closes the Input file
                                    </div>

                                </p>
                            </li>

                            <li>
                                <p>
                                <div>
                                
                                Resource Declared on Outside the <strong>Try-With-Resources</strong> block.
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData2}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classname bold-font">{"class "}</c>
                                                <c className="codeblock-classnamevalue">{"InterReviews"}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" public "}</c>
                                                <c className="codeblock-classname bold-font">{"static"}</c>
                                                <c className="codeblock-tagvalue bold-font">{" void"}</c>
                                                <c className="codeblock-classnamevalue bold-font">{" main"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"String"}</c>
                                                <c className="codeblock-classnameEqual">{"[] "}</c>
                                                <c className="codeblock-css">{" args"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-tagvalue bold-font">{" InputStream"}</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-classname">{" new "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" FileInputStream"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"HLJ.txt\" "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" ; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" try  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" System"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"out"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"println"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"character : \" "}</c>
                                                <c className="codeblock-classnameEqual">{" + "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-tagvalue bold-font">{"char"}</c>
                                                <c className="codeblock-classnameEqual">{") "}</c>                                                
                                                <c className="codeblock-css">{"istream"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"read"}</c>
                                                <c className="codeblock-classnameEqual">{"("}</c>
                                                <c className="codeblock-classnameEqual">{")"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>

                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                                <c className="codeblock-classname bold-font">{" catch  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" Exception "}</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"printStackTrace"}</c>
                                                <c className="codeblock-classnameEqual">{"() ; "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classnameEqual">{"}"}</c>
                                            </code>
                                            </div>
                                        
                                        </div>

                                    </div>

                                    <br/>
                                    <div>
                                        - If we execute the Above code on Java 7 it will give an Exception, <strong>InputStream</strong> and <strong>FileOutputStream</strong> are used on a single <strong>Try-With-Resources</strong>
                                    </div>

                                </p>
                            </li>
                            <li>
                                <p>
                                    Click on the below link to check the update of <strong>Try-With-Resource</strong> on <strong>Java 9</strong>
                                </p>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
            <div>

            <div className="blog-topics-div">
                        <div className="blog-topics">
                            <div className="title">
                                Related Topics
                            </div>
                            {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{

                            return(
                                <div className="blog-related-topic-value">   
                                <NavLink className="nav-link" to={items.link} exact>
                                    <div className="blog-all-topics-name">
                                        {items.name}
                                    </div>       
                                </NavLink>
                               </div>
                               )
                            })}

                            {/* <div className=" blog-topic-value">
                                <i className="fab fa-react"/>React
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-java"/>Java
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-js-square"/>JavaScript
                            </div> */}
                        </div>
                    </div>
                    <div>
                                            {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                        
                    </ins>
                    </div>

            </div>
        </div>

        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                <span className="shareText">Share : </span>
                <a href={"https://web.whatsapp.com/send?text= Try With Resources On Java 9 "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> Try With Resources On Java 9</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u="+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=Interreview"} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
            </div>
                    <h ref={myRef} />
                    <br/>
                    <hr/>
                    <br/>
                    <div className=""  >
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{

                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                })}

            </div>





                    </div>
                </div>

                <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <MiniFooter></MiniFooter>
        </>
    )

}

export default TryWithResourcesOnJava9;