
import React, {useState} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../Footerpage";
import { NavLink } from 'react-router-dom'
import { Tools } from '../Tools/ToolsPage'
//import { JsonFormatter } from 'react-json-formatter'

import { useDropzone } from "react-dropzone";



import ReactJson from 'react-json-view'




function ImageToBase64(){

 
    
    const [base64data, setBase64Data] = useState("");
    const [base64Split, setBase64Split] = useState("");
    const [base64Html, setBase64Html] = useState("");
    const [base64Css, setBase64Css] = useState("");
    const [checkJson, setCheckJson] = useState(false);
    const [clickjsonData, setClickJsonData] = useState("");
    // const [jsonError, setJsonError] = useState("");

    const [clipboard, setClipBoard] = useState("");
    // const [checkCollapse, setCheckCollapse] = useState(true);

    const [errorMsg, setErrorMsg] = useState("");
    const [CheckErrorMsg, setCheckErrorMsg] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(null);

    var fileInput = React.createRef();
 
    const currentTool = "Image To Base64"
    var cpbrdData1 = "copy";
     

    
    const EncodeUrl = () => {

        //alert(acceptedFiles[0]);
        //setSelectedFile(URL.createObjectURL(acceptedFiles[0]));
        encodeImageFileAsURL(acceptedFiles[0]);
        setCheckJson(true);

    }



    const diffToast = () => {

        setClipBoard(JSON.stringify(clickjsonData, null, '\t'));

        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }


    function encodeImageFileAsURL(element) {
        //alert(element)
        var file = element;
        var reader = new FileReader();
        reader.onloadend = function() {
          console.log('RESULT', reader.result)
          //alert("Result : "+reader.result)

          //setBase64Data(reader.result);
          //document.getElementById('output').innerText = reader.result;
        //   var output = document.getElementById('output');
          
        //   output.innerText = reader.result

          setBase64Html("<img src = '"+reader.result+"' />");
          setBase64Css("background-image: url("+reader.result+");");
          setBase64Split(reader.result.split(",")[1])

        }
        reader.readAsDataURL(file);

      }



   const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  
   const files = acceptedFiles.map(file => (
    <>
    <h4>Preview</h4>
    <ul>
     <li key={file.path}>
       {file.path} - {file.size} bytes
       <img src={URL.createObjectURL(file)} className="dragPreviewImg" alt={file.path}/>  
       
            
     </li>
     </ul>
     </>
   ));

   const base64SplitDisplay = (element) => {
    var file = element;
    var reader = new FileReader();
    reader.onloadend = function() {
      console.log('RESULT', reader.result)

      //setBase64Data(reader.result);
       return (<div> {reader.result.split(",")[1]}</div>)

    }
    reader.readAsDataURL(file);
   }

   function Base64HtmlDisplay(element){

    var file = element;
    var reader = new FileReader();

    reader.readAsDataURL(file)    

    reader.onloadend = function() {
      console.log('RESULT', reader.result)
      //setBase64Data(reader.result);
      alert("html ; "+reader.result);
      var output = document.getElementById('output');
      output.value = reader.result

       return (<div>{"<img123 src = '"+reader.result+"' />"}</div>)

    }


//    alert(" html 123 : "+reader.readAs);

   }


//    const AlexaRank = require('alexa-rank-nodejs').default;

// (async () => {
//     console.log("website Rank 123 : ");
//     var data2 = await AlexaRank.topsite();
//     console.log("website Rank 000 : "+data2);
//     var data = await AlexaRank.siteinfo('www.google.com');

//     console.log("website Rank 345 : "+data);
// })()




function refreshPage() {
    setTimeout(()=>{
        window.location.reload(false);
    }, 0);
    console.log('page to reload')
}



    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>Image To Base64 Converter</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Image to Base64 Converter.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">

                            {/* <div className="JSON-title">
                            <h1>JSON Formatter</h1>
                            </div> */}
                            <ToastContainer/>

                            <div className="blog-page-content">
                                <div>

                                    {/* <div className="json-output-top"> */}
                                    <div><h5><strong>Select Your Image and Convert : </strong></h5></div>
                                    {/* </div> */}
                                    {/* <div className="jsonFormat-textarea" >
                                        <textarea type="text" placeholder="Enter your JSON Data here ..." onChange={(event) => {setJsonData(event.target.value)}} />
                                    </div> */}

                            <div className="">
                                <div>
                            <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <div className="drag-area">
                            <div className="dragArea-icon"><i className="fas fa-cloud-upload-alt"></i></div>
                            <p className="dragArea-text">Drag &amp; Drop to Upload File</p>
                            <p className="dragArea-text">Or</p>
                            <p className="dragArea-button">click to select files</p>
                            </div>
                            </div>
                            <br/>
                            <aside>

                                {files}
                            </aside>
                            </section>
                            </div>
                            <div>

                            </div>
                            </div>
                            <hr/>


                                    <div className="text-center json-copyLink convert-button-size1"><button onClick={EncodeUrl}>Convert to Base64</button>
                                        {/* <PrettyPrintJson data={jsonData } /> */}
                                    </div>
                    
                    {/* <i className="share-copyLink"><button onClick={DecodeUrl}>Decode</button></i> */}
                    
                    {/* <CopyToClipboard text={jsonData}>
                        <i className="share-copyLink" title="copy" ><button onClick={diffToast}>Copy</button></i>
                    </CopyToClipboard> */}



                    {/* <pre>
        <code>
          {JSON.stringify(jsonData, null, 2)}
        </code>
      </pre> */}
        <br/>
      <br/>
      <div></div>
        <div className="json-output-border">
            {checkJson ?
            <div>
                <div>
                <div className="json-output-top">
                    <div >
                        <div>
                            <strong className="jsonblock-title">Base64 String : </strong>

                            <CopyToClipboard text={base64Split}>
                               <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="json-border">
                    {/* {base64SplitDisplay(acceptedFiles[0])} */}
                    {base64Split}
                        
                </div>
                </div>

                <br/>
                <div>
                    <div className="json-output-top">
                        <div >
                            <div>
                            <strong className="jsonblock-title">HTML {"<"}img{">"} code : </strong>

                            <CopyToClipboard text={base64Html}>
                               <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                            </CopyToClipboard>
                        </div>
                    </div>
                    </div>
                    <div className="json-border">
                        {/* <div id="output"></div> */}
                        {/* {alert("check : "+Base64HtmlDisplay(acceptedFiles[0]))} */}
                        {base64Html}
                        {/* {"<"}img src = '{base64data}'{"/>"} */}
                    </div>
                </div>

                <br/>
                <div>
                    <div className="json-output-top">
                        <div >
                            <div>
                            <strong className="jsonblock-title">CSS Background Source : </strong>

                            <CopyToClipboard text={base64Css}>
                               <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                            </CopyToClipboard>
                        </div>
                    </div>
                    </div>
                    <div className="json-border">
                        {base64Css}
                        {/* background-image: url({base64data}); */}
        
                    </div>
                </div>

            </div>
            : CheckErrorMsg ? <div className="errorShow">{errorMsg}</div> : ""
          }
        </div>

                    </div>
                    <br/>

                </div>
            </div>

        </div>

        <div className="blogLayout">

<div className="json-innerPage-layout">
    <div className="blog-page-content">
    <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
    <strong> About</strong></h5></div>
        <hr/>
        <div><h5>Image To Base64 Converter</h5></div>
        <div>Checkout this online Image To Base64 Converter Tool, which will helps to convert Image file to Base64 String.
               </div>
        <div></div>

    </div>
</div>
</div>

</div>

<div>                

<div className="tools-topics-div">
    <div className="tools-topics">
        <div className="title">
        <b className="fas fa-cog" />
             <strong> Other Tools</strong>
        </div>
        {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

        return(
            <div className="tools-topic-value" >   
            <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                <div className="blog-all-topics-name">
                    {items.name}
                </div>       
            </NavLink>
           </div>
           )
        })}
    </div>
</div>
<div className="tools-topics-div">
    <div>
        {/* <!-- side-vertical --> */}
        <ins class="adsbygoogle"
             style={{display:'block'}}
            data-ad-client="ca-pub-8123049304648947"
            data-ad-slot="5905496439"
            data-ad-format="auto"
            data-full-width-responsive="true">     
        </ins>
    </div>
</div>
</div>

</div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default ImageToBase64;


