
import React, {useState} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../Footerpage";
import { NavLink } from 'react-router-dom'
import { Tools } from '../Tools/ToolsPage'
//import { JsonFormatter } from 'react-json-formatter'

import ReactJson from 'react-json-view'




function JsonFormatters(){

 
    const [jsonData, setJsonData] = useState("");
    const [checkJson, setCheckJson] = useState(false);
    const [clickjsonData, setClickJsonData] = useState("");
    const [jsonError, setJsonError] = useState("");

    const [clipboard, setClipBoard] = useState("");
    const [checkCollapse, setCheckCollapse] = useState(true);
    const [jsonSpacing, setJsonSpacing] = useState('4');

    const [errorMsg, setErrorMsg] = useState("");
    const [CheckErrorMsg, setCheckErrorMsg] = useState(false);

 
    const currentTool = "JSON Formatter"
    const cpbrdData1 = "copy";

    const JsonStyle = {
        propertyStyle: { color: 'red' },
        stringStyle: { color: 'green' },
        numberStyle: { color: 'darkorange' }
      }
    

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            setErrorMsg(e.message.toString());
            const myArray = errorMsg.split(" ")            
            //alert(myArray.length+" "+myArray[myArray.length-1])
            setCheckErrorMsg(true);
            return false;
        }
        return true;
    }

    const EncodeUrl = () => {

        //alert(encodeURIComponent(urlString));
        //setJsonData(JSON.stringify(jsonData, null, "\t")); // stringify with tabs inserted at each level
       // setJsonData(JSON.stringify(jsonData, null, 4));    // stringify with 4 spaces at each level
        //document.body.innerHTML = "";
        //var jsonForm = document.getElementsByClassName('jsonForm');

        //jsonForm.innerHTML = JSON.stringify(jsonData, null, 4);
        

        //alert(document.body.appendChild(document.createTextNode(JSON.stringify(jsonData, null, 4))).toString());

        

        //setJsonData(encodeURIComponent(jsonData));
        if (IsJsonString(jsonData)) {
            //alert("123")
            setCheckJson(true);
            //setClickJsonData(jsonData);
            setJsonError("");
            setErrorMsg("");
            setCheckErrorMsg(false);
            setClickJsonData(JSON.parse(jsonData))
            
        } else {
            setClickJsonData("{}");
            setCheckJson(false);
            setJsonError("JSON Data is NOT RIGHT !")
        }
        
    }


    const diffToast = () => {

        setClipBoard(JSON.stringify(clickjsonData, null, '\t'));

        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    const changeCollapse = () => {
        setCheckCollapse(false);
    }

    const changeExpand = () => {
        setCheckCollapse(true);
    }

    // const changeJsonSpace = (val) => {
    //     alert(val);
    //     //setCheckCollapse(true);
    // }

    function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
        console.log('page to reload')
    }

    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>JSON Formatter &amp; Validator</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Free Online JSON Formatter &amp; Validator.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">

                            {/* <div className="JSON-title">
                            <h1>JSON Formatter</h1>
                            </div> */}
                            <ToastContainer/>

                            <div className="blog-page-content">
                                <div>

                                    {/* <div className="json-output-top"> */}
                                    <div><h5><strong>Paste Your JSON Data Below : </strong></h5></div>
                                    {/* </div> */}
                                    <div className="jsonFormat-textarea" >
                                        <textarea type="text" placeholder="Enter your JSON Data here ..." onChange={(event) => {setJsonData(event.target.value)}} />
                                        {/* <pre className="jsonForm"></pre> */}
                                    </div>

                                    <br/>

                                    <div className="text-center json-copyLink convert-button-size1"><button onClick={EncodeUrl}>Format/Validate</button>
                                        {/* <PrettyPrintJson data={jsonData } /> */}
                                    </div>
                    
                    {/* <i className="share-copyLink"><button onClick={DecodeUrl}>Decode</button></i> */}
                    
                    {/* <CopyToClipboard text={jsonData}>
                        <i className="share-copyLink" title="copy" ><button onClick={diffToast}>Copy</button></i>
                    </CopyToClipboard> */}



                    {/* <pre>
        <code>
          {JSON.stringify(jsonData, null, 2)}
        </code>
      </pre> */}
        <br/>
      <br/>
      <div></div>
        <div className="json-output-border">
            {checkJson ?
                <div>
                {/* <div><h5><strong>Formatted View : </strong></h5></div> */}
                <div className="json-output-top">
                    <div >
                        <div>
                            <strong className="jsonblock-title">Formatted View : </strong>



                            <s className ="collapseblock">
                                <label for="cars" >JSON Template  :</label>
                                <select onChange={(event) => {setJsonSpacing(event.target.value)}} className="collapseblockSelect">
                                    <option value="0">Compact</option>
                                    <option value="1">One space</option>
                                    <option value="2">Two space</option>
                                    <option value="3">Three space</option>
                                    <option value="4" selected>Four space</option>
                                    <option value="5">Five space</option>
                                    <option value="\t">1 Tap space</option>
                                </select>
                            </s>

                            <CopyToClipboard text={clipboard}>
                               <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                            </CopyToClipboard>
                            <i className ="collapseblock-copy"><button  onClick={changeExpand} ><i className="far fa-plus-square" />Expand All</button></i>
                            <i className ="collapseblock-copy"><button onClick={changeCollapse} ><i className="far fa-minus-square" />Collapse All</button></i>
                        </div>
                    </div>
                </div>
                <div className="json-border">


                    {/* <JsonFormatter json={clickjsonData} tabWith='4' JsonStyle={JsonStyle} /> */}
               
  
                     <ReactJson src={clickjsonData}  indentWidth={jsonSpacing} iconStyle="triangle" collapsed={ checkCollapse ? false : 0} />
                        
                </div>
                </div>
            : CheckErrorMsg ? <div className="errorShow">{errorMsg}</div> : ""
          }
        </div>

                    </div>
                    <br/>

                </div>
            </div>

        </div>

        <div className="blogLayout">

<div className="json-innerPage-layout">
    <div className="blog-page-content">
    <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
    <strong> About</strong></h5></div>
        <hr/>
        <div><h5><strong>JSON Formatter &amp; Validator</strong></h5></div>
        <div>Checkout this online JSON Formatter &amp; Validator Tool, which will helps to Validate JSON Data and show in the Formatted View.
               </div>
        <div></div>

    </div>
</div>
</div>

</div>

<div>                

<div className="tools-topics-div">
    <div className="tools-topics">
        <div className="title">
        <b className="fas fa-cog" />
             <strong> Other Tools</strong>
        </div>
        {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

        return(
            <div className="tools-topic-value">   
            <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                <div className="blog-all-topics-name" >
                    {items.name}
                </div>       
            </NavLink>
           </div>
           )
        })}
    </div>
</div>
<div className="tools-topics-div">
    <div>
        {/* <!-- side-vertical --> */}
        <ins class="adsbygoogle"
             style={{display:'block'}}
            data-ad-client="ca-pub-8123049304648947"
            data-ad-slot="5905496439"
            data-ad-format="auto"
            data-full-width-responsive="true">     
        </ins>
    </div>
</div>
</div>

</div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default JsonFormatters;