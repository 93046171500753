
import React, {useState} from "react";
import "./../../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../../Footerpage";
//import { JsonFormatter } from 'react-json-formatter'
import { NavLink } from 'react-router-dom'
import { Tools } from '../../Tools/ToolsPage'

import $ from 'jquery'

import json2xml from 'json2xml'

import ReactJson from 'react-json-view'

import XMLViewer from 'react-xml-viewer'

function JsonToString(){

    //const [jsonData, setJsonData] = useState("[1, {\"data\":{\"apikey\":\"******************************285e7\",\"integId\":\"34000000752137\",\"status\":1,\"zuid\":\"691945352\"},\"status\":\"success\"}]");

    const [jsonData, setJsonData] = useState("");
    const [checkJson, setCheckJson] = useState(false);
    const [clickjsonData, setClickJsonData] = useState("");
    const [jsonError, setJsonError] = useState("");
    const [jsonType, setJsonType] = useState("");

    const [clipboard, setClipBoard] = useState("");
    const [checkCollapse, setCheckCollapse] = useState(true);
    const [jsonSpacing, setJsonSpacing] = useState("10");

    const [errorMsg, setErrorMsg] = useState("");
    const [CheckErrorMsg, setCheckErrorMsg] = useState(false);


    const currentTool = "JSON To XML"

    // const PrettyPrintJson = ({data}) => {
    //     // (destructured) data could be a prop for example
    //     //alert(data);
    //     return (<div><pre>{ JSON.stringify(data, null, '\t') }</pre></div>);
    // }

    const JsonStyle = {
        propertyStyle: { color: 'red' },
        stringStyle: { color: 'green' },
        numberStyle: { color: 'darkorange' }
      }
    

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            setErrorMsg(e.message.toString());
            setCheckErrorMsg(true);
            return false;
        }
        return true;
    }

    const EncodeUrl = () => {

        //alert(encodeURIComponent(urlString));
        //setJsonData(JSON.stringify(jsonData, null, "\t")); // stringify with tabs inserted at each level
       // setJsonData(JSON.stringify(jsonData, null, 4));    // stringify with 4 spaces at each level
        //document.body.innerHTML = "";
        //var jsonForm = document.getElementsByClassName('jsonForm');

        //jsonForm.innerHTML = JSON.stringify(jsonData, null, 4);
        

        //alert(document.body.appendChild(document.createTextNode(JSON.stringify(jsonData, null, 4))).toString());

        

        //setJsonData(encodeURIComponent(jsonData));
        if (IsJsonString(jsonData)) {
        //     //alert("123")
        setClickJsonData(JSON.stringify(jsonData))
             setCheckJson(true);
        //     //setClickJsonData(jsonData);
            setJsonError("");
            setErrorMsg("");
            setCheckErrorMsg(false);
        //     var jsonParserData = JSON.parse(jsonData); 

        //     var j = OBJtoXML(JSON.parse(jsonData))

        //     if (Array.isArray(jsonParserData)){
        //         jsonParserData = json2xml(jsonParserData)
        //         //alert(jsonParserData)
        //         jsonParserData = "<JSONArray>"+jsonParserData+"</JSONArray>"                
        //         setClickJsonData(jsonParserData)
        //         setJsonType("JSONArray");
        //         //alert("array");

        //     } else {
        //         jsonParserData = json2xml(jsonParserData)
        //         //alert(jsonParserData);
        //         jsonParserData = "<JSONObject>"+jsonParserData+"</JSONObject>"                
        //         setClickJsonData(jsonParserData)
        //         setJsonType("JSONObject");

        //         //setClickJsonData(JSON.parse(jsonData))
        //     }

            
        } else {
            setClickJsonData("{}");
            setCheckJson(false);
            setJsonError("JSON Data is NOT RIGHT !")
        }

    }


    function JsonToString(jsonData) {

    }

    function InnerJsonToString(jsonData) {

        var out;
        if (jsonData)
        //out = ;

        return out;

    }


    const diffToast = () => {

        setClipBoard(JSON.stringify(clickjsonData, null, '\t'));

        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    const changeCollapse = () => {
        setCheckCollapse(false);
    }

    const changeExpand = () => {
        setCheckCollapse(true);
    }

    const changeCollapseAndExpand = () => {
        setCheckCollapse(!checkCollapse)
    }

    const xml = '<hello>World</hello>'



    // function OBJtoXML(obj) {
    //     var xml = '';
    //     for (var prop in obj) {
    //       xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
    //       if (obj[prop] instanceof Array) {
    //         for (var array in obj[prop]) {
    //           xml += "<" + prop + ">";
    //           xml += OBJtoXML(new Object(obj[prop][array]));
    //           xml += "</" + prop + ">";
    //         }
    //       } else if (typeof obj[prop] == "object") {
    //         xml += OBJtoXML(new Object(obj[prop]));
    //       } else {
    //         xml += obj[prop];
    //       }
    //       xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    //     }
    //     var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    //     console.log("xml :"+xml);
    //     return xml
    //   }


    function XMLSpacing(inp) {
        
    }

    // function formatXml(xml, tab) { // tab = optional indent value, default is tab (\t)
    //     var formatted = '', indent= '';
    //     tab = tab || '\t';
    //     xml.split(/>\s*</).forEach(function(node) {
    //         if (node.match( /^\/\w/ )) indent = indent.substring(tab.length); // decrease indent by one 'tab'
    //         formatted += indent + '<' + node + '>\r\n';
    //         if (node.match( /^<?\w[^>]*[^\/]$/ )) indent += tab;              // increase indent
    //     });
    //     return formatted.substring(1, formatted.length-3);
    // }

    // function CopyXml(xml) {

    //     xml = OBJtoXML(JSON.parse(xml))
    //     var formatted = '';
    //     var reg = /(>)(<)(\/*)/g;
    //     xml = xml.replace(reg, '$1\r\n$2$3');
    //     var pad = 0;
    //     $.each(xml.split('\r\n'), function(index, node) {
    //         var indent = 0;
    //         if (node.match( /.+<\/\w[^>]*>$/ )) {
    //             indent = 0;
    //         } else if (node.match( /^<\/\w/ )) {
    //             if (pad != 0) {
    //                 pad -= 5;
    //             }
    //         } else if (node.match( /^<\w[^>]*[^\/]>.*$/ )) {
    //             indent = 5;
    //         } else {
    //             indent = 0;
    //         }
    
    //         var padding = '';
    //         for (var i = 0; i < pad; i++) {
    //             padding += ' ';
    //         }
    

    //         // console.log(node);
    //         // console.log(node.search(">"));
    //         // var pos = node.search("</")
    //         // node = node.substring(0,node.search("</")+2)+"   "+node.substring(node.search("</")+2,node.length)
    //         // node = node.substring(0,node.search(">"))+"   "+node.substring(node.search(">"),node.length)
    //         // node = node.substring(0,node.search(">")+1)+"   "+node.substring(node.search(">")+1,node.length)
    //         // if (pos != node.search("<"))
    //         // node = node.substring(0,node.search("<")+1)+"   "+node.substring(node.search("<")+1,node.length)
    //         // node = node.substring(0,node.search("</"))+"   "+node.substring(node.search("</"),node.length)

    //         // node = node.substring(0,node.split(">", 2).join(">").length)+"   "+node.substring(node.split(">", 2).join(">").length,node.length)

    //         //node.fontcolor("green");

    //         //console.log(node);
    //         formatted += padding + node + '\r\n';
    //         pad += indent;
    //     });
    //     return formatted;
    // }

    function formatXml(xml) {
        var formatted = '';
        var reg = /(>)(<)(\/*)/g;
        xml = xml.replace(reg, '$1\r\n$2$3');
        var pad = 0;
        $.each(xml.split('\r\n'), function(index, node) {
            var indent = 0;
            if (node.match( /.+<\/\w[^>]*>$/ )) {
                indent = 0;
            } else if (node.match( /^<\/\w/ )) {
                if (pad != 0) {
                    pad -= 10;
                }
            } else if (node.match( /^<\w[^>]*[^\/]>.*$/ )) {
                indent = 10;
            } else {
                indent = 0;
            }
    
            var padding = '';
            for (var i = 0; i < pad; i++) {
                padding += ' ';
            }
    

            console.log(node);
            console.log("search:</ ==> "+node.search("</"));
            var pos = node.search("</")
            if (pos != -1)
            node = node.substring(0,node.search("</")+2)+"   "+node.substring(node.search("</")+2,node.length)
            node = node.substring(0,node.search(">"))+"   "+node.substring(node.search(">"),node.length)
            node = node.substring(0,node.search(">")+1)+"   "+node.substring(node.search(">")+1,node.length)
            if (pos != node.search("<"))
            node = node.substring(0,node.search("<")+1)+"   "+node.substring(node.search("<")+1,node.length)
            node = node.substring(0,node.search("</"))+"   "+node.substring(node.search("</"),node.length)

            node = node.substring(0,node.split(">", 2).join(">").length)+"   "+node.substring(node.split(">", 2).join(">").length,node.length)

            node.fontcolor("red");
            

            console.log("nodeValue : "+node);
            formatted += padding + node + '\r\n';
            pad += indent;
        });
        
        // var text = "<author>" +  
        // "<FirstName>Bob</FirstName>" +  
        // "<LastName>Ross</LastName>" +  
        // "</author>";  
        // //formatted = formatted.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;').replace(/ /g, '&nbsp;').replace(/\n/g,'<br />');
        // var parser = new DOMParser();  
        // var xmlDoc = parser.parseFromString(text, "text/xml");
        // console.log(xmlDoc);
        
        //alert(formatted )
        return formatted;
    }

    function formatColoredXml(xml) {

        function esc(s){return s.replace(/[-\/&<> ]/g,function(c){         // Escape special chars
            return c==' '?'&nbsp;':'&#'+c.charCodeAt(0)+';';});}

        var formatted = '<div>';
        var reg = /(>)(<)(\/*)/g;
        xml = xml.replace(reg, '$1\r\n$2$3');
        var pad = 0;
        $.each(xml.split('\r\n'), function(index, node) {
            var indent = 0;
            if (node.match( /.+<\/\w[^>]*>$/ )) {
                indent = 0;
            } else if (node.match( /^<\/\w/ )) {
                if (pad != 0) {
                    pad -= Number(jsonSpacing);
                }
            } else if (node.match( /^<\w[^>]*[^\/]>.*$/ )) {
                indent = Number(jsonSpacing);
            } else {
                indent = 0;
            }
    
            var padding = '';
            for (var i = 0; i < pad; i++) {
                padding += ' ';
            }
    

            console.log(node);
            console.log("search: </  : "+node.search("</"));
            var pos = node.search("</")
            if (pos != -1)
            node = node.substring(0,node.search("</")+2)+"  "+node.substring(node.search("</")+2,node.length)
            node = node.substring(0,node.search(">"))+"   "+node.substring(node.search(">"),node.length)
            node = node.substring(0,node.search(">")+1)+"   "+node.substring(node.search(">")+1,node.length)
            if (pos != node.search("<"))
            node = node.substring(0,node.search("<")+1)+"   "+node.substring(node.search("<")+1,node.length)
            if (pos != -1 && pos != 0)
            node = node.substring(0,node.search("</"))+"   "+node.substring(node.search("</"),node.length)

            node = node.substring(0,node.split(">", 2).join(">").length)+"   "+node.substring(node.split(">", 2).join(">").length,node.length)
            

            node = esc(padding +node)
            pos = node.search("&#60;&#47;")
            if (pos != node.search("&#60;"))
            node = node.substring(0,node.search("&#60;")+5)+"  <k class='xtc'> "+node.substring(node.search("&#60;")+5,node.length)
            if (node.search("&#60;&#47;") != -1)
            node = node.substring(0,node.search("&#60;&#47;")+10)+" <k class='xtc'> "+node.substring(node.search("&#60;&#47;")+10,node.length)
            node = node.substring(0,node.search("&#60;&#47;"))+" </k> "+node.substring(node.search("&#60;&#47;"),node.length)
            node = node.substring(0,node.search("&#62;")+5)+"  <k class='xmlData'> "+node.substring(node.search("&#62;")+5,node.length)
            node = node.substring(0,node.search("&#62;"))+"  </k> "+node.substring(node.search("&#62;"),node.length)

            node = node.substring(0,node.split("&#62;", 2).join("&#62;").length)+"  </k> "+node.substring(node.split("&#62;", 2).join("&#62;").length,node.length)
            //xmlData
            
            console.log("nodeValue : "+node);
            formatted += "<div class=\"\">" +node + '</div>';
            pad += indent;
        });
        formatted = formatted + "</div>"
        console.log("formatted : "+formatted)
        return formatted;
    }


    function formatXmlView(xml,colorize,indent) { 
        function esc(s){return s.replace(/[-\/&<> ]/g,function(c){         // Escape special chars
          return c==' '?'&nbsp;':'&#'+c.charCodeAt(0)+';';});}            
        var sm='<div class="xmt">',se='<div class="xel">',sd='<div class="xdt">',
            sa='<div class="xat">',tb='<div class="xtb">',tc='<div class="xtc">',
            ind=indent||'  ',sz='</div>',tz='</div>',re='',is='',ib,ob,at,i;
        if (!colorize) sm=se=sd=sa=sz='';   
        xml.match(/(?<=<).*(?=>)|$/s)[0].split(/>\s*</).forEach(function(nd){
          ob=('<'+nd+'>').match(/^(<[!?\/]?)(.*?)([?\/]?>)$/s);             // Split outer brackets
          ib=ob[2].match(/^(.*?)>(.*)<\/(.*)$/s)||['',ob[2],''];            // Split inner brackets 
          at=ib[1].match(/^--.*--$|=|('|").*?\1|[^\t\n\f \/>"'=]+/g)||['']; // Split attributes
          if (ob[1]=='</') is=is.substring(ind.length);                     // Decrease indent
          re+=tb+tc+esc(is)+tz+tc+sm+esc(ob[1])+sz+se+esc(at[0])+sz;
          for (i=1;i<at.length;i++) re+=(at[i]=="="?sm+"="+sz+sd+esc(at[++i]):sa+' '+at[i])+sz;
          re+=ib[2]?sm+esc('>')+sz+sd+esc(ib[2])+sz+sm+esc('</')+sz+se+ib[3]+sz:'';
          re+=sm+esc(ob[3])+sz+tz+tz;
          if (ob[1]+ob[3]+ib[2]==='<>') is+=ind;                             // Increase indent
        });
        console.log("colorText : "+re);
        return re;
      }
      
    
      function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
        console.log('page to reload')
    }


    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>JSON To String</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Free Online JSON To String Converter.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">
                {/* <div className="JSON-title">
                <h1>JSON Formatter</h1>
                </div> */}
                <ToastContainer/>

                <div className="blog-page-content">
                    <div>

                    {/* <div className="json-output-top"> */}
                    <div><h5><strong>Paste Your JSON Data Below : </strong></h5></div>
                    {/* </div> */}
                    <div className="jsonFormat-textarea" >
                        <textarea type="text" placeholder="Enter your JSON Data here ..." onChange={(event) => {setJsonData(event.target.value)}} />
                        {/* <pre className="jsonForm"></pre> */}
                    </div>

                    <br/>

                    <div className="text-center json-copyLink convert-button-size1"><button onClick={EncodeUrl}>Convert</button>
                    {/* <PrettyPrintJson data={jsonData } /> */}
                    </div>
                    
                    {/* <i className="share-copyLink"><button onClick={DecodeUrl}>Decode</button></i> */}
                    
                    {/* <CopyToClipboard text={jsonData}>
                        <i className="share-copyLink" title="copy" ><button onClick={diffToast}>Copy</button></i>
                    </CopyToClipboard> */}



                    {/* <pre>
        <code>
          {JSON.stringify(jsonData, null, 2)}
        </code>
      </pre> */}
        <br/>
      <br/>
      <div></div>
        <div className="json-output-border">
            {checkJson ?
                <div>
                {/* <div><h5><strong>Formatted View : </strong></h5></div> */}
                <div className="json-output-top">
                    <div >
                        <div>
                            <strong className="jsonblock-title">Formatted View : </strong>

                            <CopyToClipboard text={clickjsonData}>
                               <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="json-border">

                    {/* {json2xml(JSON.parse(jsonData))} */}

                    {/* <XMLViewer xml={clickjsonData} collapsible={true} indentSize={6}/> */}

                    {/* <XMLViewer xml={xml} /> */}

                    {/* 
                    
                    <pre lang="xml" className="xmlView"> {formatXml(OBJtoXML(JSON.parse(jsonData)))}</pre>
                    <hr/> */}
                    {/* {formatXml(OBJtoXML(JSON.parse(jsonData)))} */}
                    {/* <div className="xmlView">

                    <div onClick={changeCollapseAndExpand} > <i className={checkCollapse ? "far fa-plus-square xtc":"far fa-minus-square xtc"} />&#60;&nbsp;&nbsp;&nbsp;<k className="xtc">{jsonType}</k> &nbsp;&nbsp;&nbsp;&#62;</div>
                    {checkCollapse ? <div className="xmlDataView" dangerouslySetInnerHTML={{__html: formatColoredXml(OBJtoXML(JSON.parse(jsonData)))}}></div>
                    :
                    null
                }

                    <div>&#60;&#47;&nbsp;&nbsp;&nbsp;<k className="xtc">{jsonType}</k> &nbsp;&nbsp;&nbsp;&#62;</div>
                    </div> */}


                    {/* <hr/>
                    <div dangerouslySetInnerHTML={{__html: formatXmlView(OBJtoXML(JSON.parse(jsonData)))}}></div>
                    <hr/> */}

                    {/* <JsonFormatter json={clickjsonData} tabWith='4' JsonStyle={JsonStyle} /> */}
               
  
                     {/* <ReactJson src={clickjsonData}  indentWidth={jsonSpacing} iconStyle="triangle" collapsed={ checkCollapse ? false : 0} /> */}
                    {clickjsonData}                     
                </div>
                </div>
            : CheckErrorMsg ? <div className="errorShow">{errorMsg}</div> : ""
          }
        </div>

                    </div>
                    <br/>

                </div>
            </div>
            <div>

            <div className="blog-topics-div">
                        {/* <div className="blog-topics">
                            <div className="title">
                                Related Topics
                            </div>
                            {BlogPages.map((items)=>{
                        if (items.tag === "React"){

                            return(
                                <div className="blog-related-topic-value">   
                                <NavLink className="nav-link" to={items.link} exact>
                                    <div className="blog-all-topics-name">
                                        {items.name}
                                    </div>       
                                </NavLink>
                               </div>
                               )
                            }})}


                        </div> */}
                    </div>

            </div>
        </div>

        <div className="blogLayout">

            <div className="json-innerPage-layout">
                <div className="blog-page-content">
                    <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
                        <strong> About</strong></h5>
                    </div>
                    <hr/>
                    <div><h5><strong>JSON To String Converter</strong></h5></div>
                    <div>Checkout this online JSON To String Converter Tool, which will helps the Programmers/Developers to add json input String in their code for testing Or to Convert the output of the API calls.
                        <br/>
                     So, sit back and use this tool to convert the JSON to String format.
                    </div>
                    <br/>
                    <div>
                    <div><h5><strong>What is JSON</strong></h5></div>
                    JavaScript Object Notation, also known as JSON, is a lightweight data-interchange format it is an open standard record configuration and information trade group. JSON is a language-free information position. It was gotten from JavaScript, however, numerous cutting edge programming dialects incorporate code to create and parse JSON-position information. The official Internet media type for JSON is application/JSON. JSON filename utilize the extension .JSON.
                    </div>
                        <br/>

                                <div><h5><strong>Example : </strong></h5></div>
                            <div className="tools-in-example"> &#123;"Website":"Intereviews"&#125; </div>
                            <br/>
                            <div> <strong>Output:</strong> </div>
                            <div className="tools-out-example"> "&#123;\"Website\":\"Intereviews\"&#125;" </div>


                    <div></div>

                </div>
            </div>
        </div>

        </div>
        <div>                

<div className="tools-topics-div">
    <div className="tools-topics">
        <div className="title">
        <b className="fas fa-cog" />
             <strong> Other Tools</strong>
        </div>
        {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

        return(
            <div className="tools-topic-value">   
            <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                <div className="blog-all-topics-name">
                    {items.name}
                </div>       
            </NavLink>
           </div>
           )
        })}
    </div>
</div>
<div className="tools-topics-div">
    <div>
        {/* <!-- side-vertical --> */}
        <ins class="adsbygoogle"
             style={{display:'block'}}
            data-ad-client="ca-pub-8123049304648947"
            data-ad-slot="5905496439"
            data-ad-format="auto"
            data-full-width-responsive="true">     
        </ins>
    </div>
</div>
</div>
</div>


        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default JsonToString;