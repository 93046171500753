import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Axios } from "axios";
import qs from 'qs';
import { put } from "request";

const cheerio = require('cheerio');
const request = require('request');
const axios = require("axios")





//import Loader from 'react-loader-spinner';

function InstagramDownloader() {



useEffect (() => {

  // Axios.getHtmlContent("https://api.coinstats.app/public/v1/coins?skip=0&limit=10").then(
  //   (response) => {
  //     console.log("response : "+response);
  //   }
  // );

}, []); 


  const [WriteUrl, SetWriteUrl]= useState("");
  const [loaderPop, setLoaderPop] = useState(false);
  const [totalReactPackages, setTotalReactPackages] = useState("");

  

  async function fetchHTML(url) {
    const headers = { 'Access-Control-Allow-Origin': "*" }
    const res = await fetch (url, headers)
    alert(res);
    const { data } = await axios.get(url)

    return cheerio.load(data)
  }

  

  // Print the full HTML
  //console.log(`Site HTML: ${$.html}\n\n`)


  async function load() {
    const response = await fetch(
      "https://www.instagram.com/p/CaxJu4WpvtT"
    );
    const text = await response.text();
    //setRemoteData(text);
    console.log(text);
  }


  const getHtmlContent  = async() =>  {

    const data = { 'bar': 123 };
    const url = "https://www.instagram.com/p/CaxJu4WpvtT";

    //request(url, cb);


    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url,
    };

    // const response123 = axios(options);
    // console.log(response123);

    const config = {
      method: 'get',
      url: 'https://www.instagram.com/p/CaxJu4WpvtT',
      headers: { 'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9' }
  }

    const {response } = await axios.get('https://www.instagram.com/p/CaxJu4WpvtT/?_a=1',{
      headers : {

        Accept : 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        authority: 'www.instagram.com',
        //'access-control-expose-headers': 'X-IG-Set-WWW-Claim',
        //'content-type': 'text/html; charset=utf-8',
        //'cross-origin-opener-policy': 'same-origin-allow-popups;report-to="coop"',
        'Access-Control-Allow-Origin': '*', 
        "Access-Control-Allow-Credentials": true, 
        //'cache-control': 'max-age=0'
        //Host: 'www.instagram.com',
        //TE:'Trailers',
        'Access-Control-Request-Method': 'GET',
        Origin: 'https://www.instagram.com',





        //'Referrer Policy' : 'strict-origin-when-cross-origin'

        'User-Agent' : "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.77 Safari/537.36",

      },
    });//"https://api.coinstats.app/public/v1/coins?skip=0&limit=10")//'https://dog.ceo/api/breeds/list/all')
    //response.headers['content-type'];
  console.log(response.data)

    // const header = { 'Access-Control-Allow-Origin': "*" }
    // await axios("https://www.instagram.com/p/CaxJu4WpvtT", header)
    // .then(res => {
    //   const htmlData = res.data
    //   const $ = cheerio.load(htmlData);
    //   const values = []
    //    console.log($);

    // })

  };

  function cb(error, res, html) {
    if (error){
      console.log(error);
    } else {
      console.log("html data is getting ");

      const $ =  cheerio.load(html);

      console.log($)

    }
  }


  async function  downloadInstagramAnyVideo() {

    





    const headers = { 'Access-Control-Allow-Origin': "*" }

    //);
  //res.header('Access-Control-Allow-Headers', "*"

    const $ = await fetchHTML("https://www.instagram.com/p/CaxJu4WpvtT", headers);

    alert($)


    var instaurl = "https://www.instagram.com/p/"+WriteUrl.split("/")[4];

    // fetch('https://api.npms.io/v2/search?q=react')
    // .then(response => response.json())
    // .then(data => setTotalReactPackages(data));
    // alert(totalReactPackages);
    //this.setState({ totalReactPackages: data.total })


    request({
        instaurl
    }, (err, res, body) => {
    
        if (err) return console.error(err);
    
        let $ = cheerio.load(body);
    
        let title = $('title');
    
        alert("fafafa ");

        console.log(title.text());
    });    




    alert(instaurl);
    //https://www.instagram.com/tv/CaxJu4WpvtT/?utm_medium=copy_link


    let $1 = cheerio.load(instaurl); 
    alert($1);

    if($('meta[property="og:type"]').attr('content') === "video") {

    alert("video")
    // .then(response => response.json())
    // .then(json => {
    //   window.open(json.url, "_blank");
    //   setLoaderPop(false);
    // });

    //res.status(200).json({"url": $('meta[property="og:video"]').attr('content')});
    }
    if($('meta[property="og:type"]').attr('content') === "instapp:photo") {
        alert("photo");
     // res.status(200).json({"url": $('meta[property="og:image"]').attr('content')});
    }
//   e.preventDefault();
//     if(WriteUrl){
//         setLoaderPop(true);
//   const req = { 
//     url:WriteUrl
//   };

//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify(req)
//   };

//   fetch('/api', requestOptions)
//   .then(response => response.json())
//   .then(json => {
//     window.open(json.url, "_blank");
//     setLoaderPop(false);
//   });
//     }
};


const curlpageTest = async() => {


  //const {response } = await axios.get("https://webcache.googleusercontent.com/search?q=cache:www.intereviews.com");
  //console.log("response 123 "+response);

  var curl = require('curlrequest');
  var request = curl.request("https://webcache.googleusercontent.com/search?q=cache:www.intereviews.com");

  console.log("request 123 : " + request);



  var options = { url: "https://webcache.googleusercontent.com/search?q=cache:www.intereviews.com", include: true };
 
curl.request(options, function (err, parts) {
    parts = parts.split('\r\n');
    var data = parts.pop()
      , head = parts.pop();

      console.log(data);
});

  const querystring = require("querystring");
  const { Curl } = require("node-libcurl");
  

  const curlTest = Curl;


//https://webcache.googleusercontent.com/search?q=cache:' . urlencode($url)

curlTest.setOpt(Curl.option.URL, "https://webcache.googleusercontent.com/search?q=cache:www.intereviews.com");
curlTest.setOpt(Curl.option.POST, true);
curlTest.setOpt(
	Curl.option.POSTFIELDS,
	querystring.stringify({
		name: "section",
		job: "webdev",
	})
);

curlTest.on("end", function (statusCode, data, headers) {
	console.info("Status code " + statusCode);
	console.info("***");
	console.info("Our response: " + data);
	console.info("***");
	console.info("Length: " + data.length);
	console.info("***");
	console.info("Total time taken: " + this.getInfo("TOTAL_TIME"));

	this.close();
});


const terminate = curlTest.close.bind(curlTest);
curlTest.on("error", terminate);

curlTest.perform();


};


const handleKeypress = e => {

  if (e.code === "Enter") {
    downloadInstagramAnyVideo(e);
  }
};



  return (<Container >

    <Section>

    <Title>Insta Media Downloader</Title>
    {loaderPop?
      
    //   <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
    "String not Found ":    <Actionwidget>
    
    <Input
    placeholder="Enter the Insta Video Url"
    onChange={e=>SetWriteUrl(e.target.value)}
    onKeyPress={handleKeypress}
    />

    <Button 
    onClick={downloadInstagramAnyVideo} 
    
    >Download Now</Button>


<Button 
    onClick={curlpageTest} 
    
    >Download Now123</Button>

  </Actionwidget>}
    </Section>

    </Container>);
}

export default InstagramDownloader;

const Container = styled.div`
background-color:#0d1117;
width:100%;
height:100vh;
display:grid;
place-items:center;



`;

const Section = styled.div`
display:flex;
flex-direction: column;
padding:10px;
text-align:center;
`;

const Actionwidget = styled.div`
display:flex;
padding:10px;
background-color:#161b22;
border-radius:5px;
box-shadow:0 1px 3px gray;
text-align:center;

`;

const Input = styled.input`
display:flex;
    flex-grow: 1;
    height:40px;
    border-radius:1px;
    overflow:hidden;
    margin-left:4px;
    background-color:#0d1117;
    :focus-within{
        box-shadow: 0 0 0 2px #0d1117;
    }
    color:#c9d1d9;
    // outline: none;
    // border-radius: 10px;
    // border-color: violet;
    border-style: solid;

    /* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    height:33px;
    font-size:10px;
}
 
`;

const Button = styled.button`
background-color: #f24822;  
display:flex;
justify-content:center;
align-items:center;

border: 2px solid #0d1117;
padding:4px 25px;
margin-left:10px;
cursor:pointer;
color:#ffffffa1;
font-weight:bold;
font-family: 'Roboto', cursive;

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    height:40px;
    font-size:10px;
}
`;

const Title = styled.h1`

font-family: 'Press Start 2P', cursive;
color: #f24822;
display:flex;
font-size:50px;


/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    font-size:25px;
    text-align: justify;
    text-justify: inter-word;
    text-align:center;
}


`;