
import React, {useRef} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Helmet } from "react-helmet";
import MiniFooter from "../MiniFooter";
import { NavLink } from 'react-router-dom'
//import {EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton} from "react-share"

import { BlogPages } from '../InterviewQuestionPages/Blog'

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function NPMStartErrorDueToWatcherLimitIsLessInLinux(){

    const cpbrdData1 = "cat /proc/sys/fs/inotify/max_user_watches";
    const cpbrdData2 = "sudo sysctl fs.inotify.max_user_watches=524288\n sudo sysctl -p";
    const cpbrdData3 = "echo fs.inotify.max_user_watches=524288 | sudo tee -a /etc/sysctl.conf\n sudo sysctl -p";

    const relatedTopics = "React";

    const myRef = useRef(null);
    const scrollPage = () => myRef.current.scrollIntoView() 

    // const popUpAlert= () => {
    //     alert("Copied to Clipboard");
    // }

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    // const scrollPage = () => {
    //     window.scrollTo(0, 2100);
    // }


    return(
        <>
            <Helmet>
                <title>NPM Start error due to Watcher limit is less in Linux</title>
                <meta name="description" content=" In this tutorial, we are going to learn about how to change the System limit of file watchers in Linux. " />
            </Helmet>


        <div className="blogLayout">
            <div className="blog-page-layout">
                <h1><div className="title">
                NPM Start error due to Watcher limit is less in Linux
                </div></h1>
                <ToastContainer/>
                <br/>
                <div className="blog-page-tag-border">
                <div className="parent">
                    <div className="blog-page-tag" onClick={scrollPage}>ReactJs</div>
                </div>
                </div>
                <hr/>

                <div className="blog-page-content">
                    <div>
                        <strong>In this tutorial,</strong> we are going to learn about how to change the System limit of file watchers in Linux.   
                    </div>
                    <br/>
                    <div>
                       
                        
                    </div>

                    <div>
                        <ul>
                            <br/>
                            <li>
                                <p>
                                <div>
                                
                                While running npm we will get this error as :   
                                </div>
                                <br/>   
                                <pre><code>
                                <div className="blog-page-codeblock">

                                        <div className="blogpage-codeblock-inner">

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-tagvalue">{"events"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{" : "}</c>
                                        <c className="codeblock-classnameEqual">{"377"}</c>
                                        </code>
                                        </div>
                                        <br/>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-colorRed">{"throw er"}</c>
                                        <c className="codeblock-classnameEqual">{";"}</c> 
                                        <c className="codeblock-classParrotGreen">{" // Unhandled 'error' event"}</c>
                                        </code>
                                        </div>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classnameEqual">{"^"}</c>
                                        </code>
                                        </div>
                                        <br/>
                                        
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-colorRed">{"Error"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classname">{" ENOSPC"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classnameEqual">{" System limit for number of file watchers reached, watch "}</c> 
                                        <c className="codeblock-classnameEqual">{"'"}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/public"}</c>
                                        <c className="codeblock-classnameEqual">{"'"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at FSWatcher."}</c>
                                        <c className="codeblock-tag">{"<"}</c>
                                        <c className="codeblock-tagvalue">{"computed"}</c>
                                        <c className="codeblock-tag">{">"}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"internal/fs/watchers"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"218"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"26"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at Object.watch"}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"fs"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"1582"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"34"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at createFsWatchInstance "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/lib/nodefs-handler"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"38"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"15"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at setFsWatchListener "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/lib/nodefs-handler"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"81"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"15"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at FSWatcher.NodeFsHandler._watchWithNodeFs "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/lib/nodefs-handler"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"223"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"14"}</c>
                                        <c className="codeblock-classnameEqual">{")  "}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at FSWatcher.NodeFsHandler._handleDir "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/lib/nodefs-handler"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"429"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"19"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at FSWatcher."}</c>
                                        <c className="codeblock-tag">{"<"}</c>
                                        <c className="codeblock-tagvalue">{"anonymous"}</c>
                                        <c className="codeblock-tag">{"<"}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/lib/nodefs-handler"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"477"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"19"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at FSWatcher. "}</c>
                                        <c className="codeblock-tag">{"<"}</c>
                                        <c className="codeblock-tagvalue">{"anonymous"}</c>
                                        <c className="codeblock-tag">{"<"}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/lib/nodefs-handler"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"482"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"16"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at FSReqCallback.oncomplete "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"fs"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"193"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"5"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classnameEqual">{"Emitted 'error' event on FSWatcher instance at:"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at FSWatcher._handleError "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/index"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"260"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"10"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at createFsWatchInstance "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/lib/nodefs-handler"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"40"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"5"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at setFsWatchListener "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/node_modules/webpack-dev-server/node_modules/chokidar/lib/nodefs-handler"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"81"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"15"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" ["}</c>
                                        <c className="codeblock-tagvalue">{"... "}</c>
                                        <c className="codeblock-classnameEqual">{" lines matching original stack trace "}</c>
                                        <c className="codeblock-tagvalue">{"  ..."}</c>
                                        <c className="codeblock-classnameEqual">{"]"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" at FSReqCallback.oncomplete "}</c>
                                        <c className="codeblock-classnameEqual">{"("}</c>
                                        <c className="codeblock-tagvalue">{"fs"}</c>
                                        <c className="codeblock-classnameEqual">{"."}</c>
                                        <c className="codeblock-classname">{"js"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"193"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classblue3">{"5"}</c>
                                        <c className="codeblock-classnameEqual">{")"}</c>
                                        <c className="codeblock-classnameEqual">{" {"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-setconst">{"    errno"}</c>
                                        <c className="codeblock-classnameEqual">{": "}</c>
                                        <c className="codeblock-colorRed">{" -28"}</c>
                                        <c className="codeblock-classnameEqual">{","}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-setconst">{"    syscall"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classnameEqual">{" '"}</c>
                                        <c className="codeblock-classname">{"watch"}</c>
                                        <c className="codeblock-classnameEqual">{"'"}</c>
                                        <c className="codeblock-classnameEqual">{","}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-setconst">{"    code"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classnameEqual">{" '"}</c>
                                        <c className="codeblock-classname">{"ENOSPC"}</c>
                                        <c className="codeblock-classnameEqual">{"'"}</c>
                                        <c className="codeblock-classnameEqual">{","}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-setconst">{"    path"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classnameEqual">{" '"}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/public"}</c>
                                        <c className="codeblock-classnameEqual">{"'"}</c>
                                        <c className="codeblock-classnameEqual">{","}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-setconst">{"    filename"}</c>
                                        <c className="codeblock-classnameEqual">{":"}</c>
                                        <c className="codeblock-classnameEqual">{" '"}</c>
                                        <c className="codeblock-tagvalue">{"/home/local/Sreeram/Desktop/reackLearning/myfirstreactapplication/public"}</c>
                                        <c className="codeblock-classnameEqual">{"'"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-classnameEqual">{" }"}</c>
                                        </code>
                                        </div>






{/* const filterTopic = (event) => {
        setBlogListCount(BlogPages.length)
       // alert(event.currentTarget.dataset.div_name);
       const selectTopic = event.currentTarget.dataset.div_name; */}




                                        
                                        </div>

                                    </div>
                                    </code></pre>
                                    <br/>
                                    <div>
                                        <ul>
                                            <li>
                                                Above Error is due to the limit exceeded by the files watcher in the Linux System.
                                            </li>
                                            <li>
                                                in Linux, there is package name called <strong>inotify</strong>, which is used for observing directories, files and file system events.
                                            </li>
                                            <li>while starting/reloading the Angular or React Project it will need to track all the files of project</li>
                                            <li>It will be solved by increasing the limit of the inotify watcher </li>
                                            <li>for Ubuntu-64bit System the inotify limit is set to 8192</li>
                                        </ul>
                                </div>

                                    <br/>

                                </p>
                            </li>

                            <li>
                                <p>

                                <div>                            
                                    for checking current inotify watch limit.
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData1}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">


                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-tagvalue">{"$ "}</c>
                                        <c className="codeblock-classnameEqual">{"cat /proc/sys/fs/inotify/max_user_watches"}</c>
                                        </code>
                                        </div>
                                        
                                        </div>

                                    </div>

                                    <br/>

                                </p>
                            </li>

                            <li>
                                <p>
                                <div>
                                
                                Command for setting the new Limit Temporary.
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData2}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">

<pre>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-tagvalue">{"$ "}</c>
                                        <c className="codeblock-classnameEqual">{"sudo sysctl fs.inotify.max_user_watches=524288"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-tagvalue">{"$ "}</c>
                                        <c className="codeblock-classnameEqual">{"sudo sysctl -p"}</c>
                                        </code>
                                        </div>
                                        </pre>
                                        </div>

                                    </div>

                                    <br/>

                                </p>
                            </li>
                            <li>
                                <p>
                                <div>
                                
                                Command for setting the new Limit Permanent.
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData3}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">


                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-tagvalue">{"$ "}</c>
                                        <c className="codeblock-classnameEqual">{"echo fs.inotify.max_user_watches=524288 | sudo tee -a /etc/sysctl.conf"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-tagvalue">{"$ "}</c>
                                        <c className="codeblock-classnameEqual">{"sudo sysctl -p"}</c>
                                        </code>
                                        </div>
                                        
                                        </div>
                                    </div>

                                    <br/>

                                </p>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
            <div>

            <div className="blog-topics-div">
                        <div className="blog-topics">
                            <div className="title">
                                Related Topics
                            </div>
                            {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                        //if (items.tag === "React"){

                            return(
                                <div className="blog-related-topic-value">   
                                <NavLink className="nav-link" to={items.link} exact>
                                    <div className="blog-all-topics-name">
                                        {items.name}
                                    </div>       
                                </NavLink>
                               </div>
                               )
                            //}
                            })}
                        </div>
                    </div>

                    <div>
                    {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                        
                    </ins>
                    </div>

            </div>
        </div>
        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>
        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href="https://web.whatsapp.com/send?text=https://interreview.com" target="_blank" rel="noreferrer">
                        <span className="fab fa-whatsapp share-invert"/>
                        </a>

                        <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site http://www.website.com.&amp;to=interreview@gmail.com"
   title="Share by Email">
                               <span className="fas fa-envelope share-invert"/>
                        </a>

                        {/* <span className = "fab fa-instagram share-invert"/> */}


                        <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.interreview.com/" title="fb" target="_blank">                        
                            <span className = "fab fa-facebook share-invert"/> 
                        </a>
                        <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=Interreview !&amp;url=https://www.interreview.com/&amp;hashtags" title="tw" target="_blank"> 
                            <span className = "fab fa-twitter share-invert"/> 
                        </a>
                        {/* <span className = "fab fa-snapchat share-invert"></span> */}
                        <a rel="noreferrer" href="https://telegram.me/share/url?url=https://www.interreview.com/ &amp;text=Interreview" title="tw" target="_blank"> 
                        <span className = "fab fa-telegram share-invert"></span>                
                        </a>
                    </div>
                    <h ref={myRef} />
                    <br/>
                    <hr/>
                    <br/>
                    <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                    //if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    //}
                })}

            </div>





                    </div>
                </div>

                <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <MiniFooter></MiniFooter>
        </>
    )

}

export default NPMStartErrorDueToWatcherLimitIsLessInLinux;