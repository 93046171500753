import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import Footerpage from './Footerpage'
import "./Pages.css"
//import {interviewExperienceData} from './database/FirebaseCURDOperations'
import { doc, getDoc } from 'firebase/firestore';
import {db} from './database/firebase-config'
//import { set } from 'firebase/database';
import { NavLink } from 'react-router-dom';
import {EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton} from "react-share"

import {Helmet} from 'react-helmet';

function IndividualReview(props){


    

    const {ids} = useParams();


    //const [contributionList, setContributionList] = useState([]);
    const [searchList, setSearchList] = useState({});

    //pagination code


    //const [pageNumber, setPageNumber] = useState(0);
    //const perPage = 5;
    //const pageVisited = pageNumber * perPage;
    
    //const interviewExperienceCollectionRef = collection(db, "Interview-experience")


    const [sidebar, setSidebar] = useState(false);

    useEffect (()=> {

        const getInterviewExperience = async () => {
            const docValue = doc(db, "Interview-experience", ids); 
            const data = await getDoc(docValue);
            setSearchList(data.data());

            console.log("data: "+data.data().companyName);

        };
    
        getInterviewExperience();


        const handleWindowClick = () => {setSidebar(false);window.removeEventListener('click', handleWindowClick)}

            if(sidebar) {
                window.addEventListener('click', handleWindowClick);
              } else {
                window.removeEventListener('click', handleWindowClick)
              }
              
              
    
    }, [sidebar, setSidebar, ids]);


        /* Dropdown code : */

        //const options = ["CopyLink", "Email", "Facebook"]

        //const [selected, setSelected] = useState("Menu");
        //const [expanded, setExpanded] = useState(false);
    
        // function expand() {
        //     setExpanded(true);
        // }
    
        // function close() {
        //     setExpanded(false);
        // }
    
        // function select(event) {
        //     const value = event.target.textContent;
        // }
    

        const showSidebar = () => {
            // alert(" shareButton")
            setSidebar(!sidebar);

        }

        const shareUrl = window.location.href;



        // window.addEventListener('click', () => {
        //     alert("");
        //     setSidebar(false)})
      

            /*End Dropdown List*/


    //pagination Variables

    //const [pageValue, setPageValue] = useState(pageVisited+perPage);

    // const displayList = contributionList.slice(pageVisited, pageVisited + perPage).map((interview) => {
    //     console.log("interview : "+interview.id)

    //     var questionsList = interview.interviewQuestions
    //     var out = "";
    //     if (questionsList != null){
    //         for (var i = 0; i < questionsList.length; i++){
    //             out  = out  + "- " +questionsList[i]+" \n"
    //         }
    //     }
    //     //alert(interview.rating);
    //     var rating1 = "fa fa-star ";
    //     var rating2 = "fa fa-star ";
    //     var rating3 = "fa fa-star ";
    //     var rating4 = "fa fa-star ";
    //     var rating5 = "fa fa-star ";

    //     if (parseInt(interview.rating) != 0) {
    //         switch(parseInt(interview.rating)){
    //             case 5 : rating5 = rating5 + "checked";
    //             case 4 : rating4 = rating4 + "checked";
    //             case 3 : rating3 = rating3 + "checked";
    //             case 2 : rating2 = rating2 + "checked";
    //             case 1 : rating1 = rating1 + "checked";
    //         }

    //     }




    //     console.log("interview : "+interview.interviewQuestions)
    //                     return (
    //                         <div>

    //                                 <div className="inteview-view">
    //                                     <div className="company-name-div">
    //                                         <div className="company-Name">
    //                                             {interview.companyName}
    //                                         </div>
    //                                         <div className="comp-name-share">
    //                                             <div className="home-share"> 
    //                                                  <div onClick=""><i className="fas fa-share-alt"/>Share</div> 
    //                                                  <div className="dropdown" tabIndex={0} onFocus={expand} onBlur={close} >
    //                                                     <div>{selected}</div>
    //                                                         {expanded ? (
    //                                                             <div className={"dropdown-options-list"}>
    //                                                             {options.map((O) => (
    //                                                             <div className={"dropdown-option"} onClick={select}>
    //                                                                 {O}
    //                                                             </div>
    //                                                             ))}
    //                                                             </div>
    //                                                         ) : null}
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                     <hr/>
    //                                     <div className="company-name-div">
    //                                         <div className="company-Name">{interview.jobRole}</div>
    //                                         <div >Date : {interview.date}</div>
    //                                     </div>
    //                                     <div>
    //                                         <div className="inteview-title">rating</div>

    //                                         <span className={rating1}></span>
    //                                         <span className={rating2}></span>
    //                                         <span className={rating3}></span>
    //                                         <span className={rating4}></span>
    //                                         <span className={rating5}></span>
    //                                     </div>
    //                                     <br/>
    //                                     <div className="inteview-title">Application Process</div>
    //                                     <div> - {interview.applicationProcess}</div>
    //                                     <br/>
    //                                     <div className="inteview-title">Interivew Process</div>
    //                                     <div> - {interview.interviewProcess} </div>
    //                                     <br/>
    //                                     <div className="inteview-title">Inteview Questions</div>
                                        
    //                                     {(() => {
    //                                                 if (questionsList != null){
    //                                                     var out = "";
    //                                                     for (var i = 0; i < questionsList.length; i++){

    //                                                         out  = out  + "- " +questionsList[i]+" ,"
    //                                                     }
    //                                                     return (
    //                                                         <div>{out}</div>
    //                                                       )

    //                                                 }

    //                                     })()}
    //                                     {/* //console.log(" interviewQuestions : "+interview.inerviewQuestions)
    //                                         interview.interviewQuestions.map((interviewDetails)=> {
    //                                         console.log("interview : "+interviewDetails)
    //                                         return(
    //                                             <div>
    //                                                 <h1>{interviewDetails}</h1>
    //                                             </div>
    //                                         )
    //                                     })
    //                                     }  */}
    //                                     {/* <div> - {interview.inerviewQuestions}</div> */}
    //                                     <br/>
    //                                     <div className="inteview-title">Suggestions</div>
    //                                     <div> - {interview.suggestions}</div>
    //                                     <br/>
                
    //                                 </div>
    //                             </div>
    //                     )

    // })



    // const display =  interviewExperienceData.map((interviewDetails) => {
    //     alert("interviewQuestions :" +interviewDetails)
    //                     console.log("interviewDetails : "+interviewDetails)
    //                     return(
    //                     interviewDetails.map((interview)=> {
    //                     console.log("interview : "+interview.companyName)
    //                     return(
    //                         <div>
    //                             <h1>{interview.companyName}</h1>
    //                         </div>
    //                     );
    //                 })
    //                     )

    //             })


    //Search Result.
        //const [searchText, setSearchText] = useState("");
        
        // const searchHandler = (event) => {
        //     alert();
        //     event.preventDefault();

        //     const newSearchList = [];
        //     contributionList.map((interview) => {
        //         if(interview.companyName.toLowerCase() === searchText.toLowerCase()){
        //             newSearchList.push(interview);
        //         }
        //     });
        //     if (newSearchList.length > 0){
        //         setSearchList(newSearchList);
        //     } else {
        //         setSearchList(contributionList);
        //         alert("No Search result Found ")
        //     }

        // }
    

        var rating1 = "fa fa-star ";
        var rating2 = "fa fa-star ";
        var rating3 = "fa fa-star ";
        var rating4 = "fa fa-star ";
        var rating5 = "fa fa-star ";

        if (parseInt(searchList.rating) !== 0) {
            switch(parseInt(searchList.rating)){
                case 5 : 
                rating5 = rating5 + "checked";
                rating4 = rating4 + "checked";
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 4 : 
                rating4 = rating4 + "checked";
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 3 : 
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 2 : 
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 1 : 
                rating1 = rating1 + "checked";
                break;
                default : break;
            }

        }
        console.log("rating : "+searchList.rating )


        // const interviewQuestionsDisplay = () => {

        //     alert();
        //     var questionsList = searchList.interviewQuestions
        //     var out = "";
        //     if (questionsList != null){
        //         for (var i = 0; i < questionsList.length; i++){
        //             out  = out  + "- " +questionsList[i]+" \n"
        //         }
        //     }

        //     if (questionsList != null){
        //         var out = "";
        //         for (var i = 0; i < questionsList.length; i++){

        //             out  = out  + "- " +questionsList[i]+" ,"
        //         }
        //     }
        //     return (
        //         <div>{out}</div>
        //       )

        // }

        return(
        <div>

            <Helmet>
                <title>{searchList.companyName}</title>
                <meta name="description" content="in this page, we can view the shared interview experience." />
            </Helmet>

            <div>
                <div>
                    <div className="container iq-java-content">
                    
                    <NavLink className="recent-indivdiual-button" to="/contributions" exact><i className="fas fa-user-plus"></i>Add an Interview</NavLink>
                    <br/>


                    {/* {display} */}
                    {/* {displayList } */}
                    {/* checking <FirebaseCURDOperations/> */}
                    <br/>
                    <div className="inteview-view">
                        <div className="company-name-div">
                            <div className="company-Name">
                                {searchList.companyName}
                            </div>
                            <div className="comp-name-share">
                                {/* <div className="fas fa-share-alt"> 
                                    Share 
                                </div> */}
                                <div class="dropdown" >
                                  <div onClick={showSidebar} > <i className="fas fa-share-alt"/>Share</div>
                                  {sidebar ?
                                      <div class="dropdown-content">
                    <div className="eachShareItems"><EmailShareButton url={shareUrl}><span className="fas fa-envelope lightShareItems" onClick={showSidebar}> Email</span> </EmailShareButton></div>
                    <div className="eachShareItems"><FacebookShareButton url={shareUrl}> <span className="fab fa-facebook lightShareItems" onClick={showSidebar}> Facebook </span></FacebookShareButton></div>
                    <div className="eachShareItems"><WhatsappShareButton url={shareUrl}><span className="fab fa-whatsapp lightShareItems" onClick={showSidebar}> Whatsapp </span></WhatsappShareButton></div>
                    <div className="eachShareItems"><TwitterShareButton url={shareUrl}><span className="fab fa-twitter lightShareItems" onClick={showSidebar}> Twitter </span></TwitterShareButton></div>
                    <div className="eachShareItems"><span className="fa fa-clone lightShareItems" onClick={showSidebar}> Copy Link </span></div>
                                      </div>
                                    : null}
                                </div>
                             </div>
                        </div>
                        <hr/>
                        <div className="watermarked-home">
                        <img src="../logo.png" alt="Notebook"/>
                        <div className="content">
                        <div className="company-name-div">
                            <div className="company-Name">{searchList.jobRole}</div>
                            <div >Date : {searchList.date}</div>
                        </div>
                        <div>
                            <div className="inteview-title">rating</div>
                            <span className={rating1}></span>
                            <span className={rating2}></span>
                            <span className={rating3}></span>
                            <span className={rating4}></span>
                            <span className={rating5}></span>
                        </div>
                        <br/>
                <div className="inteview-title">Application Process</div>
                <div> - {searchList.applicationProcess}</div>
                <br/>
                <div className="inteview-title">Interivew Process</div>
                <div> - {searchList.interviewProcess} </div>
                <br/>
                <div className="inteview-title">Inteview Questions</div>
                {(() => {
                    if (searchList.interviewQuestions != null){
                    var out = "";
                    for (var i = 0; i < searchList.interviewQuestions.length; i++){

                        out  = out  + "- " +searchList.interviewQuestions[i]+" ,"
                    }
                    return (
                        <div>{out}</div>
                    )

                    }

                })()}
                <br/>
                <div className="inteview-title">Suggestions</div>
                <div> - {searchList.suggestions}</div>
                <br/>
                </div>

                </div>
            </div>
            <br/>

            <NavLink to="/" exact className="back-btn-val">
            <div className="back-btn">
               <c> <i className= "fas fa-chevron-left"/> Back To Home</c>
            </div>
            </NavLink>

            </div>
            </div>
            </div>
            <br/>
            <Footerpage/>
        </div>
        
        )
}


export default IndividualReview;