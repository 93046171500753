

import React from 'react'
import '../Sidebar'
import "../Pages.css"
import { NavLink } from 'react-router-dom'

import { BlogPages } from '../InterviewQuestionPages/Blog'
import Navbar from '../Navbar'
import MiniFooter from '../MiniFooter';

import { Helmet } from 'react-helmet'

const topic1 = "Encode / Decode";
const topic2 = "Formatters"
const topic3 = "Converter";
const topic4 = "SEO Tools";
const topic5 = "QR code Tools";
const topic6 = "Downloaders";
const topic7 = "String Utilities";

export const newTools = [
    {
        name : "JSON To CSV",
        link : "/tools/",
        implemented : false,
        url : ""
    },
    {
        name : "PNG To JPG",
        link : "/tools/",
        implemented : false       
    },
    {
        name : "JPG To PNG",
        link : "/tools/",
        implemented : false,
        ref : "https://coderrocketfuel.com/article/convert-png-to-svg-or-jpg-to-png-with-node-js-and-jimp"        
    },
    {
        name : "Instagram tag Viewer",
        link : "/tools/",
        implemented : false
    },
    {
        name : "painter tool",
        ref : "https://www.geeksforgeeks.org/how-to-draw-with-mouse-in-html-5-canvas/",
        implemented : false
    },
    {
        name : "Docx To PDF",
        link : "/tools/",
        implemented : false,
        ref : "https://iq.opengenus.org/word-to-pdf-javascript/",
        ref2 : "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file",
        ref3 : "https://github.com/coolwanglu/pdf2htmlEX",
        ref4 : "https://www.cloudhadoop.com/nodejs-convert-docx-pdf/",
        
    },
    {
        key : 13,
        name : "Alexa Rank Checker",
        link : "/tools/Alexa-Rank-Checker",
        implemented : true,
        url : "",
        topic : "SEO Tools"
    },





    {
        key : 16,
        name : "Youtube Downloader",
        link : "/tools/Youtube-Downloader",
        implemented : true,
        url : "https://www.youtube.com/watch?v=6jGyYtiJLW8",
        ref1 : "https://www.youtube.com/watch?v=eBVR49Ddwm8",
        ref2 : "https://www.youtube.com/watch?v=62wl6FuoLc8",
                
        topic : topic6
    },
    {
        key : 17,
        name : "File Downloader",
        link : "/tools/QR-Code-Generator",
        implemented : true,
        url : "https://www.youtube.com/watch?v=gXuzkwRivfg",
        topic : topic6
    },



]




export const Topics = [
    topic1, 
    topic2, 
    topic3,
    topic5,
    topic7,
]

export const Tools = [
    {
        key : 1,
        name : "Base64 Encode",
        link : "/tools/Base64-Encode",
        implemented : true,
        topic : topic1,
        ref : ["https://base64.guru/learn/base64-characters",
        "https://stackoverflow.com/questions/201479/what-is-base-64-encoding-used-for"]
    },
    {
        key : 2,
        name : "Base64 Decode",
        link : "/tools/Base64-Decode",
        implemented : true,
        topic : topic1
    },
    {
        key : 3,
        name : "Base32 Encode",
        link : "/tools/Base32-Encode",
        implemented : true,
        topic : topic1
    },
    {
        key : 4,
        name : "Base32 Decode",
        link : "/tools/Base32-Decode",
        implemented : true,
        topic : topic1
    },
    {
        key : 5,
        name : "URL Encoder/Decoder",
        link : "/tools/URL-Encoder-Decoder",
        implemented : true,
        topic : topic1
    },
    {
        key : 6,
        name : "HTML Encode",
        link : "/tools/HTML-Encode",
        implemented : true,
        topic : topic1
    },
    {
        key : 7,
        name : "HTML Decode",
        link : "/tools/HTML-Decode",
        implemented : true,
        topic : topic1
    },
    {
        key : 8,
        name : "JSON Formatter",
        ref : "https://github.com/ronny1020/react-json-formatter/blob/master/src/index.js",
        link : "/tools/JSON-Formatter",
        implemented : true,
        topic : topic2
    },
    {
        key : 9,
        name : "XML Formatter",
        link : "/tools/XML-Formatter",
        implemented : true,
        topic : topic2
    },
    {
        key : 10,
        name : "JSON To XML",
        link : "/tools/JSON-To-XML",
        implemented : true,
        url : "",
        topic : topic3
    },
    {
        key : 11,
        name : "Image To Base64",
        link : "/tools/Image-To-Base64",
        implemented : true,
        url : "",
        topic : topic3
    },
    {
        key : 12,
        name : "Base64 To Image",
        link : "/tools/Base64-To-Image",
        implemented : true,
        url : "",
        topic : topic3
    },
    {
        key : 13,
        name : "JPG To PNG",
        link : "/tools/JPG-To-PNG",
        implemented : true,
        url : "",
        topic : topic3
    },
    {
        key : 14,
        name : "QR Code Scanner",
        link : "/tools/QR-Code-Scanner",
        implemented : true,
        url : "",
        topic : topic5
    },
    {
        key : 15,
        name : "JSON To String",
        link : "/tools/JSON-To-String",
        implemented : true,
        url : "",
        topic : topic3
    },
    {
        key : 16,
        name : "QR Code Generator",
        link : "/tools/QR-Code-Generator",
        implemented : true,
        url : "",
        topic : topic5
    },
    {
        key : 17,
        name : "Regex Checker",
        link : "/tools/Regex-Checker",
        implemented : true,
        topic : topic7
    },
    {
        key : 18,
        name : "JSON To String",
        link : "/tools/JSON-To-String",
        implemented : true,
        url : "",
        topic : topic7
    },


]


function ToolsPage (){
    


    Navbar("tools");

    function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
        console.log('page to reload')
    }

        return(
            <div>
                <Helmet>
                    <title>Tools</title>
                    <meta name="description" content="In this page, we provided topic wise important Interview Questions. " />
                </Helmet>
            <div className="parent">
            {/* <div className="iq-sidebar"> <Sidebar/></div> */}
            <div className=" iq-content-tags" >
                <h1><div className="title ">Tools </div></h1>
                <hr/>
                <br/>
                <div className="iq-content">

                {
                Topics.map((items1, index) => {

                    return (
                        <>
                        <hr/>
                        <div className="tools-content">
                        <div className="tools-title">
                            <h3 className="text-gradient">{items1} </h3>
                        </div>
                        <div className="iq-content">    

                {Tools.filter(items => items.topic === items1).map((items,index)=>{

                    return(
                    <div className="" onClick={refreshPage}>    
                    <NavLink className="nav-link" to={items.link} exact>
                    <i className="fab tools-content-value" >
                    <span>{items.name}</span>
                    <i className="fas fa-arrow-right css-rightArrow" />
                    </i>
                    </NavLink>
                    </div>
                    )
                    

                })}
                </div>
                <br/>
                <br/>
                <hr style={{width: "93%"}}/>
                </div>

                </>
                    )

            }
            )}
                </div>
                
            </div>

            <div className="iq-whatsNew" >
                <div className="title">What's New</div>
                <hr/>
                <NavLink className="iq-whatsNew-blog" to={"/blog/"} exact>
                <div>&gt; /blog  </div>
                </NavLink>
                <ul>
                {BlogPages.map((items)=>{
                    return(
                    <li>
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="iq-whatsNew-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </li>
                    )

                })}
                </ul>

            </div>


            </div>

            <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <MiniFooter/>
            </div>
        
        )
}


export default ToolsPage;













