
import React, {useState} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom'
import { Tools } from '../Tools/ToolsPage'

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../Footerpage";


// const tools = [

// ]

function Base64Decode(){

    const [urlString, setUrlString] = useState("");
    const [outputString, setOutputString] = useState("");
    const [encodeString, setEncodeString] = useState("");
    const [checkBox, setCheckBox] = useState(false);

    // const clipboard = "";
    const currentTool = "Base64 Decode"


    const EncodeUrl = () => {
        //alert(encodeURIComponent(urlString));

        //setUrlString(btoa(urlString));        
        setOutputString(atob(urlString));
    }

    // const DecodeUrl = () => {
    //     setUrlString(atob(urlString));
    // } 

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    const handleCheckBox = () => {
        setCheckBox(!checkBox);
    }
    const handleInputString = (event) => {

        setUrlString(event.target.value)
        setEncodeString(atob(event.target.value));
    }

    function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
        console.log('page to reload')
    }    

    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>Base64 Decode</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Free Online Base64 Decode.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">
                            {/* <h1>
                                <div className="title">
                                URL Encoder/Decoder
                                </div>
                            </h1> */}
                            <ToastContainer/>
                            {/* <hr/> */}

                            <div className="blog-page-content">
                            <div>
                                <div><h5><strong>Paste or Enter you plain text below : </strong></h5></div>
                                <div className="jsonFormat-textarea" >
                                    <textarea type="text" placeholder="Enter your PLAIN text here ..." onChange={(event) => {handleInputString(event)}} />
                        
                                </div>
                                <br/>
                                <div className="text-center">
                                    <span className="autoButton"><input type="checkbox" checked={checkBox} onChange={handleCheckBox}/><b> Auto</b></span> &nbsp;&nbsp; 
                                    <i className="json-copyLink convert-button-size1"><button onClick={EncodeUrl}>Base64 Decode</button></i>
                                    {/* <i className="json-copyLink convert-button-size2"><button onClick={DecodeUrl}>Decode</button></i>
                                    <CopyToClipboard text={urlString}>
                                        <i className="json-copyLink convert-button-size2" title="copy" ><button onClick={diffToast}>Copy</button></i>
                                    </CopyToClipboard> */}

                                </div>
                            </div>
                           <br/>
                            <br/>
                           <div className="json-output-border">
                               <div>
                                    {/* <div><h5><strong>Formatted View : </strong></h5></div> */}
                                    <div className="encoder-output-top">
                                        <div >
                                            <div>
                                                <strong className="jsonblock-title">Output : </strong>

                                                <CopyToClipboard text={encodeString}>
                                                   <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="encoder-textarea" >
                                        <textarea type="text" placeholder="Base64 Encoded output goes here..." value={checkBox ? encodeString:outputString}/>                        
                                    </div>
                                    <br/>
                                    <div className="BugNote">
                                        <strong >Note :</strong>
                                        <i> The tool uses UTF-8 charset. Found a bug?</i>
                                        <a href="mailto:?subject=Bug Report on Base64 Encode  &amp;body= Bug Report :&amp;to=contact%40intereviews.com" title="Share by Email" className="reportBug">
                                           report!
                                        </a>
                                    </div>


                                    {/* <div className="json-border">

                                    </div> */}
                                </div>
                            </div>

                        </div>
                        
                    </div>                    
                </div>

                <div className="blogLayout">

                    <div className="json-innerPage-layout">
                        <div className="blog-page-content">
                            <div ><h5><b className="fas fa-question-circle navbar-titles" > </b>
                                <strong> About</strong></h5></div>
                                <hr/>
                                <div><h5><strong>Base64 Decode Tool</strong></h5></div>
                            <div>Check this online Base64 Decode Tool, which will helps to convert an string to base64 Decode.
                            </div>

                            <br/>
                            <div><h5><strong>What is Base64 ?</strong></h5></div>
                            <div><strong>Base64</strong> is a binary to text encoding scheme that represents binary data in an ASCII string format.
                            it contains 64 basic ASCII characters which are used to encode the data. 
                            {/* It is designed to carry data stored in binary format across the network channels. */}

                            </div>
                            <div>To learn more about Base64, Check this <a href="/blog/base64" className="reportBug" ><i>Base64 Blog</i></a></div>
                            <br/>
                            <div><h5><strong>Example : </strong></h5></div>
                            <div className="tools-in-example">SW50ZVJldmlld3M=</div>
                            <br/>
                            <div> <strong>Output:</strong> </div>
                            <div className="tools-out-example">InteReviews</div>

                            <div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        <div>                

        <div className="tools-topics-div">
            <div className="tools-topics">
                <div className="title">
                <b className="fas fa-cog" />
                     <c> Other Tools</c>
                </div>
                {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

                return(
                    <div className="tools-topic-value">   
                    <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                        <div className="blog-all-topics-name" >
                            {items.name}
                        </div>       
                    </NavLink>
                   </div>
                   )
                })}


            </div>
        </div>
        <div className="tools-topics-div">
            <div>
                {/* <!-- side-vertical --> */}
                <ins class="adsbygoogle"
                     style={{display:'block'}}
                    data-ad-client="ca-pub-8123049304648947"
                    data-ad-slot="5905496439"
                    data-ad-format="auto"
                    data-full-width-responsive="true">     
                </ins>
            </div>
        </div>
        </div>
    </div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default Base64Decode;