import React, { useState, useRef } from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiniFooter from "../MiniFooter";
import { NavLink } from 'react-router-dom'
import { BlogPages } from '../InterviewQuestionPages/Blog'

import {Helmet} from 'react-helmet';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


// class GmailPaginationUsingReact extends Component{

function GmailPaginationUsingReact(){
    

    //const [clipboardData, setCliqboardData] = useState("");

    const cpbrdData1 = "<i className=\"fas fa-chevron-left\"> </i> \n<i className=\"fas fa-chevron-right\"> </i>";
    const cpbrdData2 = "<div style={{textAlign:\"right\"}}> \n<i> 1-50 of 2,183 </i> \n<i className=\"fas fa-chevron-left\"> </i>\n<i className=\"fas fa-chevron-right\"> </i> \n</div>";
    const cpbrdData3 = ".arrow-btn { padding : 10px; } \n.arrow-btn:hover { background-color : lightgrey; border-radius : 20px; } \n.arrow-btn-disabled { color : lightgrey; }";
    const cpbrdData4 = "<div style={{textAlign:\"right\"}}> \n<i> 1-50 of 2,183 </i> \n<i className=\"fas fa-chevron-left arrow-btn-disabled\"> </i>\n<i className=\"fas fa-chevron-right arrow-btn\"> </i> \n</div>";
    const cpbrdData5 = "const [isActiveLeft, setActiveLeft] = useState(false); \nconst [isActiveRight, setActiveRight] = useState(true);";
    const cpbrdData6 = "<div style={{textAlign:\"right\"}}>\n\t<i> 1-50 of 2,183 </i>\n\t<i className={ isActiveLeft ? \"fas fa-chevron-left arrow-btn\" : \"fas fa-chevron-left arrow-btn-disabled\"}> </i>\n\t<i className={ isActiveRight ? \"fas fa-chevron-right arrow-btn\" : \"fas fa-chevron-right arrow-btn-disabled\"}> </i>\n</div>";
    const cpbrdData7 = "const [isActiveLeft, setActiveLeft] = useState(false);\nconst [isActiveRight, setActiveRight] = useState(true);\nconst [pageNumber, setPageNumber] = useState(0);\nconst perPage = 10;\nconst pageVisited = perPage * pageNumber;\nconst totalPages = dataSet.length;";
    const cpbrdData8 = "const display = dataSet.slice(pageVisited, pageVisited + perPage).map((items) => {\n\treturn (\n\t\t<div>{ items.value }</div>\n\t)\n})\n\n//Function To Operate Left Button\nconst onChangeLeft = () => {\n\tif ( pageVisited > 0 ) {\n\t\tsetPageNumber ( pageNumber - 1 );\n\t\tif ( pageNumber > 1 ) {\n\t\t\tsetActiveRight ( true );\n\t\t} else {\n\t\t\tsetActiveLeft ( false );\n\t\t}\n\t}\n};\n\n//Function To Operate Right Button\nconst onChangeRight = () => {\n\tif ( totalPages > pageVisited + perPage ) {\n\t\tsetPageNumber ( pageNumber + 1 );\n\t\tsetActiveLeft ( true );\n\t\tif ( pageNumber+3 > totalPages/10 ) {\n\t\t\tsetActiveRight ( false );\n\t\t}\n\t}\n};\n\n//Html code and Calling the Above Functions\n<div style={{textAlign:\"right\"}}>\n\t<i> { pageVisited + 1 } - { pageVisited + perPage } of { totalPages } </i>\n\t<i className={ isActiveLeft ? \"fas fa-chevron-left arrow-btn\" : \"fas fa-chevron-left arrow-btn-disabled\"} onClick={ onChangeLeft }> </i>\n\t<i className={ isActiveRight ? \"fas fa-chevron-right arrow-btn\" : \"fas fa-chevron-right arrow-btn-disabled\"} onClick={ onChangeRight }> </i>\n</div>\n<div>\n\t{ display }\n</div>";


    const relatedTopics = "React";


    const myRef = useRef(null);
    const scrollPage = () => myRef.current.scrollIntoView() 

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }


    // const handleClick = () => {
    //     alert(val)
    //     var val = 0;
    //     var data = "<i className=\"fas fa-chevron-left\"> </i> \n<i className=\"fas fa-chevron-right\"> </i>"
    //     if (val === 1){
    //         data = "<div style={{textAlign:\"right\"}}> \n<i> 1-50 of 2,183 </i> \n<i className=\"fas fa-chevron-left\"> </i>\n<i className=\"fas fa-chevron-right\"> </i> \n</div>";
    //     } else if (val === 2){

    //     }

    //     setCliqboardData(data);

    //     // window.clipboardData.setData("Text", data);
    //     alert(data)
    //   }


    const [sidebar, setSidebar] = useState(false);
    const [copyText, setCopyText] = useState("Copy Text");

    const showSidebar = () => {

        //alert("asd ");

        setSidebar(!sidebar);
        if (sidebar) {
            setCopyText("Copy Text");
        }

    }

    const changeCopyText = () => {
        setCopyText("Copied");
        diffToast();
    }

        return(
            < >
                <Helmet>
                    <title>Gmail Pagination View Using React</title>
                    <meta name="description" content="In this Tutorials, we are going to learn about how to create GMail Pagination view using react" />
                </Helmet>
                <div className="mainBody" >
                    <div className="blog-page-layout ">
                        <h1><div className="title">
                            Gmail Pagination View Using React
                        </div></h1>
                        <ToastContainer/>
                        <br/>
                        <div className="blog-page-tag-border">
                            <div className="parent">
                                <div className="blog-page-tag" onClick={scrollPage}>React</div>
                            </div>
                        </div>
                        <hr/>
                        

                        {/*blog-page-content */}

                        <div className="blog-page-content">
                        <div>
                            In this Tutorials, we are going to learn about how to create GMail Pagination view using react.
                            Pagination is a important topics, which helps for navigating between the pages and to show the minimum records from the huge list of items.
                            </div>
                            <br/>
                            <div>
                                <div>This is the Gmail pagination view. Now we are going to implement this in react.</div>
                                <img src=".././gmail_pagination.jpg" className="blog-page-image" alt="logo" />
                            </div>

                            <br/>
                            <div>
                                <div className="blog-page-highlight">
                                    <strong>Requirements : </strong>
                                    <ul>
                                        <li>
                                            Font Awesome
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <div>
                                    After adding Font Awesome to react Js, Now we need to get the NEXT and PREVIOUS buttons from awesome.
                                            </div>

                                go to  <a href="https://fontawesome.com/">fontawesome</a> search for Arrow icons, 
                                u will find the <strong>chevron-left</strong> (<i className="fas fa-chevron-left"/>) and <strong>chevron-right</strong> (<i className="fas fa-chevron-right"/>)
                                <p>
                                    Now add these icons into your page
                                    <div className="blog-page-codeblock">
                                    <CopyToClipboard text={cpbrdData1}>
                                            <i className="far fa-copy codeblock-copy" onMouseEnter = {() => showSidebar()} onMouseLeave = {() => showSidebar()} onClick = {() => changeCopyText()}>
                                            {sidebar ?
                                                        <div class="dropdown-copyText-content">
                                                            <div className="copyTextItems">{copyText}</div>
                                                        </div>
                                                    : null}
                                            </i>
                                        </CopyToClipboard>
                                    <div className="blogpage-codeblock-inner">
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-left\""}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>


                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-right\""}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>
                                    </div>
                                    </div>
                                </p>
                                </li>
                                <li>
                                    <p>
                                        Now create a <strong><i>DIV</i></strong> tag inside your Component, and Add above tags in that.
                                        And create a new tag for displaying minimum records and total no. of records.
                                    <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData2}>
                                        <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                    <div className="blogpage-codeblock-inner">
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-classname">{" style"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                            <c className="codeblock-classname">{"textAlign:"}</c>
                                            <c className="codeblock-classnamevalue">{"\"right\""}</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{"> " }</c>
                                            <c className="codeblock-classnameEqual">{" 1-50 of 2,183"}</c>
                                            <c className="codeblock-tag">{"  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-left\""}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>


                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-right\""}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"</"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>
                                    </div>
                                    </div>
                                    View :
                                    <div style={{textAlign:"right", backgroundColor:"lightgrey", padding:"10px", color:"black"}}>
                                    <i> 1-50 of 2,183 </i>
                                    <i className="fas fa-chevron-left"> </i>
                                    <i className="fas fa-chevron-right"> </i> 
                                    </div>

                                    </p>
                                </li>
                                <li>
                                    <p>
                                    Now add some CSS to the icons as Disbaled and hover.

                                    <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData3}>
                                        <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                    <div className="blogpage-codeblock-inner">
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-css">{".arrow-btn "}</c>
                                            <c className="codeblock-classnameEqual">{"{ "}</c>
                                            <c className="codeblock-classname">{"padding :"}</c>
                                            <c className="codeblock-cssval">{" 10px"}</c>
                                            <c className="codeblock-classnameEqual">{"; "}</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-css">{".arrow-btn:hover"}</c>
                                            <c className="codeblock-classnameEqual">{" { "}</c>
                                            <c className="codeblock-classname">{"background-color :"}</c>
                                            <c className="codeblock-classnamevalue">{" lightgrey"}</c>
                                            <c className="codeblock-classnameEqual">{"; "}</c>
                                            <c className="codeblock-classname">{"border-radius :"}</c>
                                            <c className="codeblock-cssval">{" 20px"}</c>
                                            <c className="codeblock-classnameEqual">{"; "}</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-css">{".arrow-btn-disabled"}</c>
                                            <c className="codeblock-classnameEqual">{" { "}</c>
                                            <c className="codeblock-classname">{"color :"}</c>
                                            <c className="codeblock-classnamevalue">{" lightgrey"}</c>
                                            <c className="codeblock-classnameEqual">{"; "}</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                        </code>
                                        </div>
                                    </div>
                                    </div>
                                      .  
                                    <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData4}>
                                        <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                    <div className="blogpage-codeblock-inner">
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-classname">{" style"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                            <c className="codeblock-classname">{"textAlign:"}</c>
                                            <c className="codeblock-classnamevalue">{"\"right\""}</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{"> " }</c>
                                            <c className="codeblock-classnameEqual">{" 1-50 of 2,183"}</c>
                                            <c className="codeblock-tag">{"  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-left arrow-btn-disabled\""}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-right arrow-btn\""}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"</"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>
                                    </div>
                                    </div>

                                    View :
                                    <div style={{textAlign:"right", backgroundColor:"lightgrey", padding:"5px", color:"black"}}>
                                    <i> 1-50 of 2,183 </i>
                                    <i className="fas fa-chevron-left arrow-btn-disabled"> </i>
                                    <i className="fas fa-chevron-right arrow-btn"> </i> 
                                    </div>
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        Now add variables of ActiveLeft and ActiveRight which is helpfull for enabling and disabling the left and right arrows on the Function Component.

                                        <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData5}>
                                        <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                    <div className="blogpage-codeblock-inner">

                                    <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const"}</c>
                                            <c className="codeblock-classnameEqual">{" ["}</c>
                                            <c className="codeblock-classname">{"isActiveLeft"}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{" setActiveLeft"}</c>
                                            <c className="codeblock-classnameEqual">{"]"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-setconst">{" useState"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{"false"}</c>
                                            <c className="codeblock-classnameEqual">{");"}</c>
                                        </code>
                                        </div>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const"}</c>
                                            <c className="codeblock-classnameEqual">{" ["}</c>
                                            <c className="codeblock-classname">{"isActiveRight"}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{" setActiveRight"}</c>
                                            <c className="codeblock-classnameEqual">{"]"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-setconst">{" useState"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{"true"}</c>
                                            <c className="codeblock-classnameEqual">{");"}</c>
                                        </code>
                                        </div>
                                        </div></div>
                                        .

                                    <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData6}>
                                        <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                    <div className="blogpage-codeblock-inner">

                                    
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-classname">{" style"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                            <c className="codeblock-classname">{"textAlign:"}</c>
                                            <c className="codeblock-classnamevalue">{"\"right\""}</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{"> " }</c>
                                            <c className="codeblock-classnameEqual">{" 1-50 of 2,183"}</c>
                                            <c className="codeblock-tag">{"  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{ isActiveLeft "}</c>
                                            <c className="codeblock-classnameEqual">{" ? "}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-left arrow-btn\""}</c>
                                            <c className="codeblock-classnameEqual">{" : "}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-left arrow-btn-disabled\""}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{ isActiveRight "}</c>
                                            <c className="codeblock-classnameEqual">{" ? "}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-right arrow-btn\""}</c>
                                            <c className="codeblock-classnameEqual">{" : "}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-right arrow-btn-disabled\""}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"</"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                    </div>
                                    </div>

                                    </p>
                                </li>

                                <li>
                                    <p>

                                        Now add some additional variables which is useful for storing pageNumber, pagesVisited and number of items for page.


                                    <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData7}>
                                        <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                    <div className="blogpage-codeblock-inner">

                                    <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const"}</c>
                                            <c className="codeblock-classnameEqual">{" ["}</c>
                                            <c className="codeblock-classname">{"isActiveLeft"}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{" setActiveLeft"}</c>
                                            <c className="codeblock-classnameEqual">{"]"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-setconst">{" useState"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{"false"}</c>
                                            <c className="codeblock-classnameEqual">{");"}</c>
                                        </code>
                                        </div>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const"}</c>
                                            <c className="codeblock-classnameEqual">{" ["}</c>
                                            <c className="codeblock-classname">{"isActiveRight"}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{" setActiveRight"}</c>
                                            <c className="codeblock-classnameEqual">{"]"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-setconst">{" useState"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{"true"}</c>
                                            <c className="codeblock-classnameEqual">{");"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const"}</c>
                                            <c className="codeblock-classnameEqual">{" ["}</c>
                                            <c className="codeblock-classname">{"pageNumber"}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{" setPageNumber"}</c>
                                            <c className="codeblock-classnameEqual">{"]"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-setconst">{" useState"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{"0"}</c>
                                            <c className="codeblock-classnameEqual">{");"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const"}</c>
                                            <c className="codeblock-classname">{" perPage"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-setconst">{"10"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const"}</c>
                                            <c className="codeblock-classname">{" pageVisited"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classname">{" perPage"}</c>
                                            <c className="codeblock-setconst">{" * "}</c>
                                            <c className="codeblock-classname">{" pageNumber"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const"}</c>
                                            <c className="codeblock-classname">{" totalPages"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classname">{" dataSet"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-classname">{"length"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                    
                                        </div></div>
                                    </p>
                                </li>

                                <li>
                                    <p>
                                    Now add some functions to get the data from the dataset and to operate buttons action.

                                    <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData8}>
                                        <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>

                                    <div className="blogpage-codeblock-inner">
                                    <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const "}</c>
                                            <c className="codeblock-classname">{" display"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classLightBlue">{" dataSet"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-setconst">{"slice"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classname">{"pageVisited"}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-classname">{" pageVisited"}</c>
                                            <c className="codeblock-classnameEqual">{" + "}</c>
                                            <c className="codeblock-classname">{" perPage"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-setconst">{"map"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classname">{"items"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-tagvalue">{" => "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}>
                                            <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classPink">{" return"}</c>
                                            <c className="codeblock-classnameEqual">{" ("}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}>
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classGrey">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-classGrey">{">"}</c>
                                            <c className="codeblock-classname">{"{"}</c>
                                            <c className="codeblock-classLightBlue">{" items"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-classLightBlue">{"value "}</c>
                                            <c className="codeblock-classname">{"}"}</c>
                                            <c className="codeblock-classGrey">{"</"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-classGrey">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{" )"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classnameEqual">{"})"}</c>
                                        </code>
                                        </div>

                                        <p></p>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classParrotGreen">{"//Function To Operate Left Button"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const "}</c>
                                            <c className="codeblock-setconst">{" onChangeLeft "}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-tagvalue">{" => "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classPink">{" if "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-setconst">{" pageVisited "}</c>
                                            <c className="codeblock-classnameEqual">{" > "}</c>
                                            <c className="codeblock-classLightGreen">{" 0 "}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-setconst">{" setPageNumber "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{" pageNumber "}</c>
                                            <c className="codeblock-classnameEqual">{"-"}</c>
                                            <c className="codeblock-classLightGreen">{" 1 "}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classPink">{" if "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-setconst">{" pageNumber "}</c>
                                            <c className="codeblock-classnameEqual">{" > "}</c>
                                            <c className="codeblock-classLightGreen">{" 1 "}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-setconst">{" setActiveRight "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{" true "}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{" } "}</c>
                                            <c className="codeblock-classPink">{" else "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-setconst">{" setActiveLeft "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{" false "}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>
                                    
                                        <p></p>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classParrotGreen">{"//Function To Operate Right Button"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{" const "}</c>
                                            <c className="codeblock-setconst">{" onChangeRight "}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-tagvalue">{" => "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classPink">{" if "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classLightBlue">{" totalPages"}</c>
                                            <c className="codeblock-classnameEqual">{" > "}</c>
                                            <c className="codeblock-classname">{" pageVisited "}</c>
                                            <c className="codeblock-classnameEqual">{"+ "}</c>
                                            <c className="codeblock-classname">{" perPage "}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>
                                        
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-setconst">{" setPageNumber "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{" pageNumber "}</c>
                                            <c className="codeblock-classnameEqual">{"+"}</c>
                                            <c className="codeblock-classLightGreen">{" 1 "}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-setconst">{" setActiveLeft "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{" true "}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classPink">{" if "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classLightBlue">{" pageNumber"}</c>
                                            <c className="codeblock-classnameEqual">{"+"}</c>
                                            <c className="codeblock-classLightGreen">{"3 "}</c>
                                            <c className="codeblock-classnameEqual">{" > "}</c>
                                            <c className="codeblock-classname">{" totalPages"}</c>
                                            <c className="codeblock-classnameEqual">{"/"}</c>
                                            <c className="codeblock-classLightGreen">{"10 "}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-setconst">{" setActiveRight "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{" false "}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                        </code>
                                        </div>


                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c></c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <p></p>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c className="codeblock-classParrotGreen">{"//Html code and Calling the Above Functions"}</c>
                                        </code>
                                        </div>

                                    
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-classname">{" style"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                            <c className="codeblock-classname">{"textAlign:"}</c>
                                            <c className="codeblock-classnamevalue">{"\"right\""}</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c>&emsp;</c>
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{"> " }</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-classname">{" pageVisited "}</c>
                                            <c className="codeblock-classnameEqual">{"+ "}</c>
                                            <c className="codeblock-classLightGreen">{" 1 "}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-classnameEqual">{" - "}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-classname">{" pageVisited "}</c>
                                            <c className="codeblock-classnameEqual">{"+ "}</c>
                                            <c className="codeblock-classname">{" perPage "}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-classnameEqual">{" of "}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-classname">{" totalPages "}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{"  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c>&emsp;</c>
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{ isActiveLeft "}</c>
                                            <c className="codeblock-classnameEqual">{" ? "}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-left arrow-btn\""}</c>
                                            <c className="codeblock-classnameEqual">{" : "}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-left arrow-btn-disabled\""}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-classname">{" onClick"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-setconst">{" onChangeLeft "}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c>&emsp;</c>
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-classname">{" className"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{ isActiveRight "}</c>
                                            <c className="codeblock-classnameEqual">{" ? "}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-right arrow-btn\""}</c>
                                            <c className="codeblock-classnameEqual">{" : "}</c>
                                            <c className="codeblock-classnamevalue">{"\"fas fa-chevron-right arrow-btn-disabled\""}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-classname">{" onClick"}</c>
                                            <c className="codeblock-classnameEqual">{"="}</c>
                                            <c className="codeblock-tagvalue">{"{"}</c>
                                            <c className="codeblock-setconst">{" onChangeRight "}</c>
                                            <c className="codeblock-tagvalue">{"}"}</c>
                                            <c className="codeblock-tag">{">  </"}</c>
                                            <c className="codeblock-tagvalue">{"i"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"</"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"<"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                        <c>&emsp;&emsp;</c>
                                        <c className="codeblock-tagvalue">{"{"}</c>
                                        <c className="codeblock-classname">{" display "}</c>
                                        <c className="codeblock-tagvalue">{"}"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock"> 
                                            <c className="codeblock-tag">{"</"}</c>
                                            <c className="codeblock-tagvalue">{"div"}</c>
                                            <c className="codeblock-tag">{">"}</c>
                                        </code>
                                        </div>




                                        </div></div>

                                        


                                    </p>

                                    

                                </li>

                                </ol>
    

                            </div>          

                        </div>
                        </div>
                    </div>
                    <div>
                <div className="blog-topics-div">
                    <div className="blog-topics">
                        <div className="title">
                            Related Topics
                        </div>
                        {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                        //if (items.tag === relatedTopics){
                            return(
                                <div className="blog-related-topic-value">   
                                <NavLink className="nav-link" to={items.link} exact>
                                    <div className="blog-all-topics-name">
                                        {items.name}
                                    </div>       
                                </NavLink>
                               </div>
                            )
                        //}
                        })}
                    </div>
                </div>
                <div>
                    {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                        
                    </ins>
                </div>
            </div>
 
                </div>

            <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

                <br/>
        <div className="shareLink">
            <hr/>

            <div className="share-div">
                <span className="shareText">Share : </span>
                <a href="https://web.whatsapp.com/send?text=https://interreview.com" target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site http://www.website.com.&amp;to=interreview@gmail.com" title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>
                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.interreview.com/" title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=Interreview !&amp;url=https://www.interreview.com/&amp;hashtags" title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href="https://telegram.me/share/url?url=https://www.interreview.com/ &amp;text=Interreview" title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
            </div>
            <h ref={myRef} />
            <br/>
            <hr/>
            <br/>
            <div className="">
                <div className="blog-all-topics" >
                    <div className="title">All Topics on {relatedTopics}</div>
                    <hr/>
                    {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                        //if (items.tag === relatedTopics){
                            return(
                                <div className="all-topics-items">   
                                    <NavLink className="nav-link" to={items.link} exact>
                                        <div className="blog-all-topics-name">
                                            {items.name}
                                        </div>

                                    </NavLink>
                                </div>
                            )
                        //}
                    })}

                </div>
            </div>
        </div>
                {/*
                
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1321929293552187"
     crossorigin="anonymous"></script>
                */}

        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

                <MiniFooter/>
            </>
        )
    
}


export default GmailPaginationUsingReact;