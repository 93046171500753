import React, { Component } from 'react'
import "./Pages.css"
import {NavLink} from 'react-router-dom'
import {WhatsappShareButton, LinkedinShareButton, PinterestShareButton} from "react-share"

class Footerpage extends Component {
    render(){

        function refreshPage() {
            setTimeout(()=>{
                window.location.reload(false);
            }, 0);
            console.log('page to reload')
        }

        return(
            <div className="footer-content">
                <div>
                    <NavLink to="/" exact className="footer-line">
                        <img src="/logo2.png" className="footer-app-logo" alt="logo"/>    

                        <img src="/logo192.png" className="footer-app-logo" alt="logo"/>
                    </NavLink>
                </div>
                <hr/>
                <div className="parent">
                    <div className="div1">
                        <ul className="footer-list-link">
                            <li>
                                <NavLink className="nav-link" to="/about" exact>
                                    <div className="footer-link">About Us</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/contributions" exact>
                                    <div className="footer-link">Add an Interview</div>
                                </NavLink>                                
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/URL-Encoder-Decoder" exact>
                                    <div className="footer-link">URL Encoder/Decoder</div>
                                </NavLink>                                
                            </li>
                            
                        </ul>
                    </div>
                    <div className="div2">
                        <ul className="footer-list-link">
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions" exact>
                                    <div className="footer-link">Interview Questions</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/java" exact>
                                    <div className="footer-link">Java</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/javascript" exact>
                                    <div className="footer-link">JavaScript</div>
                                </NavLink>
                                
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/react" exact>
                                    <div className="footer-link">React</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/angular" exact>
                                    <div className="footer-link">Angular</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/python" exact>
                                    <div className="footer-link">Python</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/jquery" exact>
                                    <div className="footer-link">Jquery</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/nodejs" exact>
                                    <div className="footer-link">NodeJs</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/springboot" exact>
                                    <div className="footer-link">Spring Boot</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/interviewQuestions/mysql" exact>
                                    <div className="footer-link">MySQL</div>
                                </NavLink>
                            </li>
                            
                        </ul>
                    </div>
                    <div className="div3">
                        <ul className="footer-list-link">
                            <li>
                                <NavLink className="nav-link" to="/blog" exact>
                                    <div className="footer-link">Blog</div>
                                </NavLink>
                            </li>

                        </ul>

                    </div>
                    <div className="div4">
                        <ul className="footer-list-link">
                            <li>
                                <NavLink className="nav-link" to="/tools" exact>
                                    <div className="footer-link">Tools</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/tools/URL-Encoder-Decoder" exact>
                                    <div className="footer-link">URL Encoder/Decoder</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/tools/Base64-Encode" exact>
                                    <div className="footer-link">Base64 Encode</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/tools/Base64-Decode" exact>
                                    <div className="footer-link">Base64 Decode</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/tools/Base32-Encode" exact>
                                    <div className="footer-link">Base32 Encode</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/tools/Base32-Decode" exact>
                                    <div className="footer-link" onClick={refreshPage}>Base32 Decode</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/tools/HTML-Encode" exact>
                                    <div className="footer-link">HTML Encode</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/tools/HTML-Decode" exact>
                                    <div className="footer-link">HTML Decode</div>
                                </NavLink>
                            </li>
                            
                            <li>
                                <NavLink className="nav-link" to="/tools/JSON-Formatter" exact>
                                    <div className="footer-link">JSON Formatter</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/tools/JSON-To-XML" exact>
                                    <div className="footer-link">JSON To XML</div>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="nav-link" to="/tools/XML-Formatter" exact>
                                    <div className="footer-link">XML Formatter</div>
                                </NavLink>
                            </li>

                        </ul>

                    </div>
                </div>
                <div className="text-center share-div">
                    <WhatsappShareButton url="https://intereviews.com"><span className="fab fa-whatsapp share-icon" ></span></WhatsappShareButton>
                    {/* <a href="https://web.whatsapp.com/send?text=https://intereviews.com" target="_blank" rel="noreferrer">
                        <span className="fab fa-whatsapp share-icon"/>
                    </a> */}
                    {/* <span className = "fab fa-instagram share-icon"/> */}
                    <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.intereviews.com/" title="fb" target="_blank">                        
                        <span className = "fab fa-facebook share-icon"/> 
                    </a>
                    <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=intereviews !&amp;url=https://www.intereviews.com/&amp;hashtags" title="tw" target="_blank"> 
                        <span className = "fab fa-twitter share-icon"/> 
                    </a>
                    <LinkedinShareButton url="https://www.intereviews.com"><span className="fab fa-linkedin share-icon" ></span> </LinkedinShareButton>
                    {/* <span className = "fab fa-snapchat share-icon"> </span> */}
                    <a rel="noreferrer" href="https://telegram.me/share/url?url=https://www.intereviews.com/ &amp;text=intereviews" title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-icon"></span>     
                    <PinterestShareButton url="https://www.intereviews.com"><span className="fab fa-pinterest share-icon" ></span> </PinterestShareButton>           
                </a>
                </div>
                
                {/* <div className="text-center share-div">
                    <a href="https://web.whatsapp.com/send?text=https://interreview.com" target="_blank" rel="noreferrer">
                        <span className="fab fa-whatsapp share-icon"/>
                    </a>
                    <span className = "fab fa-instagram share-icon"/>
                    <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.interreview.com/" title="fb" target="_blank">                        
                        <span className = "fab fa-facebook share-icon"/> 
                    </a>
                    <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=Interreview !&amp;url=https://www.interreview.com/&amp;hashtags" title="tw" target="_blank"> 
                        <span className = "fab fa-twitter share-icon"/> 
                    </a>
                    <span className = "fab fa-snapchat share-icon"> </span>
                    <span className = "fab fa-telegram share-icon"> </span>
                    
                </div> */}
                <div className="text-center share-div">
                    <a href="mailto:?subject=About Intereviews &amp;body=Suggestions : .&amp;to=contact%40intereviews.com" title="Share by Email">
                        <span className="feedback-icon fas fa-envelope"> Email Us</span>
                    </a>

                    <span className="feedback-icon fab fa-twitter"> Follow Us</span>

                    <a href="fb://page/harshit.rock.50">
                    <span className="feedback-icon fab fa-facebook"> Like Us</span>
                    </a>


                    
                </div>
                <div className="text-center ">
                    <NavLink to="/" exact className="footer-line">
                        <span className="footer-link">Interreviews.com</span>
                    </NavLink> 
                    <span>|  </span><i className="far fa-copyright"></i>copyright 2021
                    <NavLink to="/" exact className="footer-line"> </NavLink>
                </div>

            </div>
        );
    }
}

export default Footerpage;

