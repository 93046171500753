import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import { Topics } from '../InterviewQuestions'
import { NavLink } from 'react-router-dom'
import "../Pages.css"
//import Footerpage from '../Footerpage'
import Navbar from '../Navbar'
import MiniFooter from '../MiniFooter'

const ReactJsQuestions = [
    {
        ques: " What is React ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Features of React ?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " Advantages of React ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Virtual DOM ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between Virtual DOM and Real DOM ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is JSX ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is BabelJs ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Why JSX can't read by browser ?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " What is Component in React ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Props ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is States ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between Props and States ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is prop drilling in React ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between Statefull and Stateless Components ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Pure Components ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is HOC(Higher Order Components) ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " life cycle components of React Component ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between Controlled and UnControlled Components ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the use of Keys in React ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the use of SWITCH in React Router ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Advantages of React Router ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between React Router and HTML Routing ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " what are React Hooks ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between useState and useEffect ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Flex ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Redux ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between Flex and Redux ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " List the 3 principles that follow Redux ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Components of Redux ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Advantages of Redux ?",
        ans: "",
        topic : "basic"

    },



]

const JavaQuestions = [
    {
        key : 1,
        ques : " What is Java ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 2,
        ques : " Feature of Object Oriented Programing ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 3,
        ques : " Difference between JDK, JRE and JVM ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 4,
        ques : " What is JIT compiler ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 5,
        ques : " What is Constructor ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 6,
        ques : " What is Static in java ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 7,
        ques :  <> What is <strong>THIS</strong> Keyword in java ?</>,
        ans  : "",
        topic : "basic",
    },
    {
        key : 8,
        ques : <> What is <strong>SUPER</strong> keyword in java ? </>,
        ans  : "",
        topic : "basic",
    },
    {
        key : 9,
        ques : " What is Inheritance?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 10,
        ques : " What is Polymorphism ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 11,
        ques : " What are wrapper classes in Java ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 12,
        ques : " Difference between Method Overloading and Method Overriding ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 13,
        ques : " Can you overload main() method ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 14,
        ques : " What are Access Modifiers ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 15,
        ques : "  what is final variable, final method and final class ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 16,
        ques : "  Difference between final, finally and finalize ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 17,
        ques : "  What is Encapsulation ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 18,
        ques : "  Advantages of Encapsulation ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 19,
        ques : "  What is Abstraction ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 20,
        ques : "  What is Interface ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 21,
        ques : "  Difference between abstraction and interface ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 22,
        ques : "  what is marker interface ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 23,
        ques : "  What is instanceOf operator ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 24,
        ques : "  Difference between compile-time polymorphism and run-time polymorphism ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 25,
        ques : "  Difference between static binding and dynamic binding ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 26,
        ques : "  what is Exception Handling ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 27,
        ques : "  type of Exceptions in java ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 28,
        ques : "  Difference between throw and throws ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 29,
        ques : "  Can finally be used without catch ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 30,
        ques : "  What is package ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 31,
        ques : "  What is String pool ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 32,
        ques : "  Difference between StringBuffer and String ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 33,
        ques : "  Difference between StringBuffer and StringBuilder ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 34,
        ques : "  For storing password why we use CharArray() instead of String ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 35,
        ques : "  what is the super class of all the classes ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 36,
        ques : "  what is nested classes ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 37,
        ques : "  Types of inner classes ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 38,
        ques : "  what is Garbage Collection ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 39,
        ques : "  what is Serialization ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 40,
        ques : "  What is transient keyword ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 41,
        ques : "  what is multithreading ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 42,
        ques : "  what is deadlock ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 43,
        ques : "  what is Collections ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 44,
        ques : "  Difference between ArrayList and Vector ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 45,
        ques : "  Difference between ArrayList and LinkedList ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 46,
        ques : "  Difference between List and Set ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 47,
        ques : "  Difference between Set and Map ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 48,
        ques : "  Difference between HashMap and HashSet ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 49,
        ques : "  Difference between HashMap and TreeMap ?",
        ans  : "",
        topic : "basic",
    },
    {
        key : 50,
        ques : "  Difference between Comparable and Comparator ?",
        ans  : "",
        topic : "basic",
    },
   
]

const angular = [
    {
        ques: " What is Angular Framework ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Typescript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the Key Components of Angular ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Components ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Directives ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between Components and Directives ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Lifecycle Hooks available?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is interpolation ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Differnce between Constructor and ngOnInit ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Dependency injection in Angular ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Purpose of *ngFor, ngIf directives ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are template expression and template statements ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are pipes, explain about parameterized pipe and custom pipe ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between pure and impure pipe ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a bootstrapping module ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Observables ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How do you performing Error Handling ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is RxJS ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between promise and observable ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Custom elements ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " what is multicasting ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " what is subscribing ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What will happen if you do not supply handler for observer ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Angular Elements ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Dynamic Components ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Angular Router ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is router state, router outlet and activated route ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Router links and Router events ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the purpose of Wildcard route ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is JIT and AOT ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Folding ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the three phases of AOT and Advantages of AOT  ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is type narrowing ?",
        ans: "",
        topic : "basic"

    },

    {
        ques: " What is Zone ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is codelyzer ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " what is State Function and Style Function ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Angular Ivy ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Transition Function ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Can we use AOT Compilation with Ivy ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Angular Language Services ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How do you add web workers in your application ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Angular CLI Builder ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Angular DSL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Lazy Loading ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is NgUpgrade ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is DOM sanitizer ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Http Interceptors ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is TestBed, Protractor and Collection  ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are reactive forms, dynamic forms and template driven forms ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to inject the dynamic script in Angular ?",
        ans: "",
        topic : "basic"

    },

]

const cQuestions = []

const pythonQuestions = [
    {
        ques: " What is python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " is Python interpreted language ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is pep 8 ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Benefits of using Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are decorators in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between list and tuples in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is slicing in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between Python Arrays and lists ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between List and set in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Differecen between List and Dictionary ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are local variables and global variables in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is __init__ ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is self in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a lambda function ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How does break, continue and pass work ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How is memory managed in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is type conversion in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are python modules? Name some commonly used built-in modules in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the difference between range & xrange ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is pickling and unpickling ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are docstrings in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the process of compilation and linking in python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the difference between deep and shallow copy ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are negative indexes and why are they used ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the purpose of ‘is’, ‘not’ and ‘in’ operators ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to create a Unicode string in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Does Python have OOps concepts ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Does python make use of access specifiers ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Does python support multiple inheritance ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Define encapsulation in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How do you do data abstraction in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Explain Inheritance in Python with an example ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is map function in Python ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Is python numpy better than lists ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the difference between NumPy and SciPy ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the Python libraries you have used for web scrapping ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How To Save An Image Locally Using Python Whose URL Address I Already Know ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How can you Get the Google cache age of any URL or web page ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " You are required to scrap data from IMDb top 250 movies page. It should only have fields movie name, year, and rating ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Give an example of shuffle() method ?",
        ans: "",
        topic : "basic"

    },



]

const javascriptQuestions = [
    {
        ques: " What is JavaScript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Features of JavaScript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is DOM ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is memoization ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Hoisting ?",
        ans: "",
        topic : "basic"

    },
    {
        ques:  <> Difference between <strong>const</strong>, <strong>let</strong> and <strong>var</strong> ?</>,
        ans: "",
        topic : "basic"

    },
    {
        ques: <> Difference between <strong>==</strong> and <strong>===</strong> operators ?</>,
        ans: "",
        topic : "basic"

    },
    {
        ques: <> Difference between <strong>slice</strong> and <strong>splice</strong> ?</>,
        ans: "",
        topic : "basic"

    },
    {
        ques: <> Difference between <strong>Call()</strong>, <strong>Apply()</strong> and <strong>Bind()</strong> ?</>,
        ans: "",
        topic : "basic"

    },
    {
        ques: <> Explain <strong>“this”</strong> keyword ? </>,
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are lambda or arrow functions ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a higher order function and first order function ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Currying in javascript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is NaN property in JavaScript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Explain WeakSet and WeakMap in javascript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: <> Differences between <strong>cookie</strong>, <strong>local storage</strong> and <strong>session storage</strong> ?</>,
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the Temporal Dead Zone ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is IndexedDB ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How do you decode or encode a URL in JavaScript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Object Destructuring ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a strict mode in javascript ? why do we need it and how to declare it.",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a promise ? why do we need it",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is promise chaining ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is typeof operator ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between null and undefined ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between window and document ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the purpose of isFinite function ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a freeze method ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is tree shaking ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a polyfill ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between proto and prototype ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What would be the result of 1+2+'3' ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is BOM ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the purpose of void 0 ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is an event delegation ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is event bubbling and event capturing ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between uneval and eval ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a comma operator ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Differences between javascript and typescript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Minification ?",
        ans: "",
        topic : "basic"

    },


]

const mysql = [
    {
        ques: " What is MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between MySQL and SQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Is MySQL case-sensitive ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is DDL, DML and DCL in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is constraints? Also explain the different types of constraints ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference Between Primary key & Foreign key ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between primary key and candidate key ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are aggregate functions in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are scalar functions in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Joins in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Stored procedure and how to create it ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a view and how to create it ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a trigger and how to create it ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Cursor and how to create it ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between BETWEEN and IN operators in Mysql ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between having and where clause in Mysql ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What do you mean by % and _ in the LIKE statement ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the difference between the LIKE and REGEXP operators ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between CHAR and VARCHAR ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is BLOB and TEXT in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between DROP, TRUNCATE and DELETE in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the heap table ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between the heap table and the temporary table ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the different tables present in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are Storage Engines in Mysql  ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " what is the default storage engine in Mysql ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between char_length and length in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Differences between MySQL_fetch_array(), MySQL_fetch_object(), MySQL_fetch_row() ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " difference between NOW() and CURRENT_DATE() ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the use of enum in MySQL? How it is different from set ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is federated tables in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the difference between mysql_connect and mysql_pconnect ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is SQLyog?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the difference between MyISAM dynamic and MyISAM static in MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the advantages of MyISAM over InnoDB ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to display top 10 rows in Mysql ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to display even rows in student table using MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to fetch common records between two tables using MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to count the number of records in a MySQL ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to find the second highest salary ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to retrieve a hundred books starting from 20th ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to display the nth highest salary?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to print the FIRST_NAME and LAST_NAME from Worker table into a single column COMPLETE_NAME. A space char should separate them. ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to find the names of employees that begin with ‘H’ ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to create a new table that consists of data and structure copied from the other table ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to retrieve the last 3 records from worker table ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to fetch 50% records from worker table. ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to add email validation to your database ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to fetch all the Employees details from worker table who joined in the Year 2022 ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Write a query to fetch project-wise count of employees sorted by project’s count in descending order ?",
        ans: "",
        topic : "basic"

    },


]

const jquery = [
    {
        ques: " What is jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between JavaScript and jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Advantages of jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Do jQuery HTML works for both HTML and XML documents ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Funtions that provide Effects in jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between find and children methods ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the selectors in jQuery, and what are the types of Selectors ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: "  What is the Fastest selector and Slowest selector in jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the method chaining in jQuery, and its advantages ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between onload() and document.ready() ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: "  Difference between Map and Grep function in jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: "  Explain the usage of jQuery.data() method ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between onload() and document.ready() methods ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: "  What is the purpose of the fade toggle() method in JQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Differentiate among .empty() vs .remove() vs .detach() in jQuery. ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between prop() and attr() ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between jQuery.get() and jQuery.ajax() ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Is jQuery is a client or server scripting ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between size and length of jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is jQuery connect ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Which sign is used as a shortcut for jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between bind() vs live() vs delegate() methods in jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between $(this) and this in jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the use of jQuery filter ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the four parameters used for jQuery Ajax method ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Why jQuery is better than JavaScript ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to add and remove CSS classes to an element using jQuery ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are jQuery plugins ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is CDN ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the jQuery Ajax Events ?",
        ans: "",
        topic : "basic"

    },
]

const springBoot = [
    {
        ques: " What is Spring Boot ?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " Difference between Spring and Spring Boot?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " Advantages of Spring Boot ?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " Why spring boot is used for microservices ?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " Exception handles in Spring Boot ?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " Dependency injection in spring boot ?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " Explain Spring Actuator and its advantages ?",
        ans: "",
        topic : "basic"
    },
    {
        ques: " What is spring Boot dependency Management ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the @RequestMapping and @RestController annotation in Spring Boot used for ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are spring boot annotations ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a bean in spring ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between RequestMapping and GetMapping ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between @RestController and @Controller ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to insert data in mysql using spring boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to use crud repository in spring boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Spring Boot CLI and how to execute the Spring Boot project using boot CLI ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the name of the default H2 database configured by Spring Boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to fetch data from database ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is JPA in spring boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Explain what is thyme leaf and how to use thymeleaf ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Steps to add a custom JS code with Spring Boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the main class in spring boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Mention the steps to connect Spring Boot application to a database using JDBC ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to handle 404 error in spring boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How to do pagination in spring boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is an IOC container ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the actuator-provided endpoints used for monitoring the spring boot application ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the use of profiles in spring boot ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How can we disable the auto-configuration class ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Describe the flow of HTTPS request through the spring boot app ?",
        ans: "",
        topic : "basic"

    },
]

const nodeJS = [
    {
        ques: " What is Node.js and how it works ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Advantages of Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Explain Node.js web application architecture ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between JavaScript and Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How many types of API functions are available in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Explain asynchronous and non-blocking APIs in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " If Node.js is single-threaded, then how does it handle concurrency ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Callback function in node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is callback hell in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the advantages of using promises instead of callbacks ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " difference between fork() and spawn() methods in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a control flow function? what are the steps does it execute ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a test pyramid? How can you implement it when talking about HTTP APIs ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Explain the concept of stub in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is REPL? What purpose it is used for ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is EventEmitter in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is Event loop in Node.js? How does it work ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is crypto in Node.js? How do you cipher the secure information in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How many types of streams are present in node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " How does Node.js handle child threads ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Differentiate between process.nextTick() and setImmediate() ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Which database is more popularly used with Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What are the pros and cons of Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Is it possible to access DOM in Node ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Does Node.js provide Debugger ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is npm? What is the main functionality of npm ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between operational and programmer errors ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is the Punycode in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " Difference between readFile and createReadStream in Node.js ?",
        ans: "",
        topic : "basic"

    },
    {
        ques: " What is a thread pool in Node.js? Which library handles it ?",
        ans: "",
        topic : "basic"

    },
]

// const javaSnippets = []

// const nodeJsQuestions = []

const questions = []

function Java(props){

    Navbar("interviewQuestions");

    const {topics} = useParams();
    let questionCount;
    let questionList = questions;

    if (topics === "java") {
        questionList = JavaQuestions;

    } else if (topics === "react") {
        questionList = ReactJsQuestions;

    } else if (topics === "python") {
        questionList = pythonQuestions;

    } else if (topics === "c") {
        questionList = cQuestions
    } else if (topics === "javascript"){
        questionList = javascriptQuestions;

    } else if (topics === "mysql") {
        questionList = mysql
    } else if (topics === "angular") {
        questionList = angular
    } else if (topics === "jquery") {
        questionList = jquery
    } else if (topics === "springboot") {
        questionList = springBoot
    } else if (topics === "nodejs") {
        questionList = nodeJS
    }

    questionCount = questionList.length;


    //pagination code
    const[ isActiveLeft, setActiveLeft] = useState(false);
    const[ isActiveRight, setActiveRight] = useState(true);

    const [pageNumber, setPageNumber] = useState(0);
    const perPage = 10;
    const pageVisited = pageNumber * perPage;
    const display = questionList.slice(pageVisited, pageVisited + perPage).map((items, index) => {
        return(
            <div key={index} className="questions">
                <hr/>
                <div>{index+1 + pageVisited}){items.ques}</div>
            </div>
            )
    })

    const onChangeRight = () => {
        if (questionCount > pageVisited+perPage){
            setPageNumber(pageNumber+1);
            setActiveLeft(true);
            if (questionCount/10 < pageNumber+3){
                setActiveRight(false);
            }
        }
    };

    const onChangeLeft = () => {
        if (pageVisited > 0){
            setPageNumber(pageNumber-1);
            if (pageNumber > 1){
                setActiveRight(true);
            } else {
                setActiveLeft(false);
            }

        }
    };


    //Recommended
    let recommendCount = 5;

    return(
        <>

        <div className="sidebyside parent">
            <div className="float-right iq-java-content div1">
            <h1><div className="questions-title">Top {questionCount} {topics} Interview Questions</div></h1>
            <div className="prev-next-view">
            <i>{pageVisited+1}-{(pageVisited+perPage) > questionCount ? questionCount : pageVisited+perPage} of {questionCount}</i>
            <i className= {isActiveLeft ? "fas fa-chevron-left prev-btn" : "fas fa-chevron-left prev-btn1 btn-disabled" } onClick={onChangeLeft} ></i>
            <i className= {isActiveRight ? "fas fa-chevron-right next-btn" : "fas fa-chevron-right next-btn1 btn-disabled"} onClick={onChangeRight}></i>
            </div>
            {/* <ReactPaginate
                previousLabel = {"Previous"}
                nextLabel = {"next"}
                onPageChange = {changePage}
                containerClassName = {"paginationBttns"}
                previousLinkClassName = {"previousBttns"}
                nextLinkClassName = {"nextBttns"}
                disabledClassName = {"paginationDisabled"}
                activeClassName = {"paginationActive"}
            /> */}

            {display}
        {/* {questionList.map((items,index)=>{
            return(
            <div key={index} className="questions">
                <hr/>
                <div>{index+1}){items.ques}</div>
            </div>
            )
        })}         */}
        
        </div>
        <div className="div2 recommend-main">
            <div className="recommend">
                <div className="title   ">
                        Recommended 
                </div>
                
                {
                Topics.slice(0,6).filter(items => items.title.toUpperCase() === topics.toUpperCase()).map((items)=>{
                    //if (items.title.toUpperCase() === topics.toUpperCase()){
                        recommendCount = 6
                        return null;
                    //}
                })}
                {Topics.slice(0,recommendCount).filter(items => items.title.toUpperCase() !== topics.toUpperCase()).map((items,index)=>{
                    //if (items.title.toUpperCase() !== topics.toUpperCase()){
                    
                    return(
                        <>
                    <NavLink  to={items.link} exact>
                    <i className={items.icon + "1"}>
                    <img src= {items.icon_link} style={{maxHeight:"25px", maxWidth:"25px"}} alt="" />
                    <span>{items.title}</span>
                    </i>
                    </NavLink>
                    </>
                    )
                    //}
                    
                })}
            </div>
        </div>
    </div>
    <div><MiniFooter/></div>
    </>
    )
}

export default Java