
import React, {useRef} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom';
import { BlogPages } from '../InterviewQuestionPages/Blog'
import MiniFooter from "../MiniFooter";
import {Helmet} from 'react-helmet';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function TryWithResources(){

    const cpbrdData1 = "class InterReviews {\n\tpublic static void main( String[] args ) {\n\t\tInputStream istream = null ;\n\t\ttry {\n\t\t\tistream = new FileInputStream ( \"HLJ.txt\" ) ;\n\t\t\tSystem.out.println ( \"character : \" + (char) istream.read() ) ;\n\t\t} catch ( Exception ex ) {\n\t\t\tex.printStackTrace() ;\n\t\t} finally {\n\t\t\tif ( istream != null ) {\n\t\t\t\tistream.close() ;\n\t\t\t}\n\t\t}\n\t}\n}";
    const cpbrdData2 = "class InterReviews {\n\tpublic static void main( String[] args ) {\n\t\ttry ( InputStream istream = new FileInputStream ( \"HLJ.txt\" ) ; ) {\n\t\t\tSystem.out.println ( \"character : \" + (char) istream.read() ) ;\n\t\t} catch ( Exception ex ) {\n\t\t\tex.printStackTrace() ;\n\t\t}\n\t}\n}";
    const cpbrdData3 = "class InterReviews {\n\tpublic static void main( String[] args ) {\n\t\ttry ( InputStream istream = new FileInputStream ( \"HLJ.txt\" ) ;\n\t\tFileOutputStream ostream = new FileOutputStream ( \"JHH.txt\" ) ; )\n\t\t{\n\t\t\tSystem.out.println ( \"character : \" + (char) istream.read() ) ;\n\t\t\tostream.write( \" \" ) ;\n\t\t} catch ( Exception ex ) {\n\t\t\tex.printStackTrace() ;\n\t\t}\n\t}\n}";

    const relatedTopics = "Java"

    const myRef = useRef(null);
    const scrollPage = () => myRef.current.scrollIntoView() 

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }


    return(
        <>

            <Helmet>
                <title>Try-With-Resources</title>
                <meta name="description" content=" In this tutorial, we are going to learn about the usage of Try-With-Resource. " />
            </Helmet>

        <div className="blogLayout">
            <div className="blog-page-layout">
                <h1>
                <div className="title">
                    Try-With-Resources 
                </div>
                </h1>
                <ToastContainer/>
                <br/>
                <div className="blog-page-tag-border">
                    <div className="parent">
                        <div className="blog-page-tag" onClick={scrollPage}>Java</div>
                    </div>
                </div>

                <hr/>
                <div className="blog-page-content">
                    <div>
                        <strong>In this tutoria,</strong> we are going to learn about the usage of <strong>Try-With-Resource</strong> 
                    </div>
                    <br/>
                    <div>
                        It was first introduce on <strong>Java 7</strong> and then got some update on <strong>Java 9</strong>.
                    </div>
                    <br/>
                    <div>
                        <ul>
                            <li>
                                <strong>Try-With-Resources</strong> are mainly used to close the <strong>resource automatically</strong>.
                            </li>
                            <li>Resources are : Files, Network, Connections ect.,</li>
                            <li>it uses AutoCloseable interface to close the resources automatically.</li>
                            <li><strong>Java 7</strong> has some limitations to implement <strong>Try-With-Resource</strong>, Where they resolved that limitation on <strong>Java 9 Update</strong></li>

                        </ul>
                    </div>

                    <div>
                        <ul>
                            <br/>
                            <li>
                                <p>
                                    <div>
                                    Normal <strong>Try-Catch</strong> Example code.
                                    </div>
                                    <br/>
                                    <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData1}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast} />
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classname bold-font">{"class "}</c>
                                                <c className="codeblock-classnamevalue">{"InterReviews"}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" public "}</c>
                                                <c className="codeblock-classname bold-font">{"static"}</c>
                                                <c className="codeblock-tagvalue bold-font">{" void"}</c>
                                                <c className="codeblock-classnamevalue bold-font">{" main"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"String"}</c>
                                                <c className="codeblock-classnameEqual">{"[] "}</c>
                                                <c className="codeblock-css">{" args"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-tagvalue bold-font">{" InputStream"}</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-classnamevalue">{" null "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" try  "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-classname">{" new "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" FileInputStream"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"HLJ.txt\" "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" System"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"out"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"println"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"character : \" "}</c>
                                                <c className="codeblock-classnameEqual">{" + "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-tagvalue bold-font">{"char"}</c>
                                                <c className="codeblock-classnameEqual">{") "}</c>                                                
                                                <c className="codeblock-css">{"istream"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"read"}</c>
                                                <c className="codeblock-classnameEqual">{"("}</c>
                                                <c className="codeblock-classnameEqual">{")"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>

                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                                <c className="codeblock-classname bold-font">{" catch  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" Exception "}</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"printStackTrace"}</c>
                                                <c className="codeblock-classnameEqual">{"() ; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                                <c className="codeblock-classname bold-font">{" finally  "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" if  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{" != "}</c>
                                                <c className="codeblock-classnamevalue">{" null "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"close"}</c>
                                                <c className="codeblock-classnameEqual">{"() ; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                            </code>
                                            </div>
                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classnameEqual">{"}"}</c>
                                            </code>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <br/>

                                </p>
                                
                            </li>
                            <li>
                                <p>
                                <div>
                                
                                Now change the Above code with <strong>Try-With-Resources</strong>
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData2}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classname bold-font">{"class "}</c>
                                                <c className="codeblock-classnamevalue">{"InterReviews"}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" public "}</c>
                                                <c className="codeblock-classname bold-font">{"static"}</c>
                                                <c className="codeblock-tagvalue bold-font">{" void"}</c>
                                                <c className="codeblock-classnamevalue bold-font">{" main"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"String"}</c>
                                                <c className="codeblock-classnameEqual">{"[] "}</c>
                                                <c className="codeblock-css">{" args"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" try  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" InputStream"}</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-classname">{" new "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" FileInputStream"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"HLJ.txt\" "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" ; "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" System"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"out"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"println"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"character : \" "}</c>
                                                <c className="codeblock-classnameEqual">{" + "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-tagvalue bold-font">{"char"}</c>
                                                <c className="codeblock-classnameEqual">{") "}</c>                                                
                                                <c className="codeblock-css">{"istream"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"read"}</c>
                                                <c className="codeblock-classnameEqual">{"("}</c>
                                                <c className="codeblock-classnameEqual">{")"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>

                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                                <c className="codeblock-classname bold-font">{" catch  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" Exception "}</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"printStackTrace"}</c>
                                                <c className="codeblock-classnameEqual">{"() ; "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classnameEqual">{"}"}</c>
                                            </code>
                                            </div>
                                        
                                        </div>

                                    </div>

                                    <br/>
                                    <div>
                                        - If we check the above code, we don't need to close the InputStream, it will automatically closed by <strong>Try-With-Resources</strong> block.
                                    </div>

                                </p>
                            </li>

                            <li>
                                <p>
                                <div>
                                
                                Multiple resources on <strong>Try-With-Resources</strong>.
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData3}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classname bold-font">{"class "}</c>
                                                <c className="codeblock-classnamevalue">{"InterReviews"}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" public "}</c>
                                                <c className="codeblock-classname bold-font">{"static"}</c>
                                                <c className="codeblock-tagvalue bold-font">{" void"}</c>
                                                <c className="codeblock-classnamevalue bold-font">{" main"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"String"}</c>
                                                <c className="codeblock-classnameEqual">{"[] "}</c>
                                                <c className="codeblock-css">{" args"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" try  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" InputStream"}</c>
                                                <c className="codeblock-css">{" istream"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-classname">{" new "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" FileInputStream"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"HLJ.txt\" "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" ; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-tagvalue bold-font">{" FileOutputStream"}</c>
                                                <c className="codeblock-css">{" ostream"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-classname">{" new "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" FileOutputStream"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"JHH.txt\" "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" ; "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" System"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"out"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classname">{"println"}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tag">{"\"character : \" "}</c>
                                                <c className="codeblock-classnameEqual">{" + "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-tagvalue bold-font">{"char"}</c>
                                                <c className="codeblock-classnameEqual">{") "}</c>                                                
                                                <c className="codeblock-css">{"istream"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"read"}</c>
                                                <c className="codeblock-classnameEqual">{"("}</c>
                                                <c className="codeblock-classnameEqual">{")"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" ostream"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"write"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-tag">{"\" \" "}</c>
                                                <c className="codeblock-classnameEqual">{") ; "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                                <c className="codeblock-classname bold-font">{" catch  "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{" Exception "}</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" ex"}</c>
                                                <c className="codeblock-classnameEqual">{"."}</c>
                                                <c className="codeblock-classnamevalue">{"printStackTrace"}</c>
                                                <c className="codeblock-classnameEqual">{"() ; "}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" } "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classnameEqual">{"}"}</c>
                                            </code>
                                            </div>
                                        
                                        </div>

                                    </div>

                                    <br/>
                                    <div>
                                        - In the Above code both the <strong>InputStream</strong> and <strong>FileOutputStream</strong> are used on a single <strong>Try-With-Resources</strong> block.
                                    </div>

                                </p>
                            </li>
                            <li>
                                <p>
                                <NavLink className="nav-link" to="/blog/Try-With-Resources-on-Java-9" exact>
                                                <c className="iq-whatsNew-name">Click the link</c> </NavLink> to check the update of <strong>Try-With-Resource</strong> on <strong>Java 9</strong>

                                    {/* <ul>
                                        <li>
                                            <NavLink className="nav-link" to="/blog/Try-With-Resources-on-Java-9" exact>
                                                <div className="iq-whatsNew-name">
                                                    Try-with-resource in java 9
                                                </div>

                                            </NavLink>
                                        </li>
                                    </ul> */}

                                </p>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
            <div>

            <div>
                <div className="blog-topics-div">
                    <div className="blog-topics">
                        <div className="title">
                            Related Topics
                        </div>
                        {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                            return(
                                <div className="blog-related-topic-value">   
                                <NavLink className="nav-link" to={items.link} exact>
                                    <div className="blog-all-topics-name">
                                        {items.name}
                                    </div>       
                                </NavLink>
                               </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* <div className="blog-topics-div">
                        <div className="blog-topics">
                            <div className="title">
                                Related Topics
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-react"/>React
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-java"/>Java
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-js-square"/>JavaScript
                            </div>
                        </div>
                    </div> */}
                    <div>

                    {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                        
                    </ins>

                    </div>

            </div>
        </div>

        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

        <br/>
        <div className="shareLink">
            <hr/>

            <div className="share-div">
                <span className="shareText">Share : </span>
                <a href={"https://web.whatsapp.com/send?text="+window.location.href} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site "+window.location.href} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>
                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.interreview.com/" title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=Interreview !&amp;url=https://www.interreview.com/&amp;hashtags" title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href="https://telegram.me/share/url?url=https://www.interreview.com/ &amp;text=Interreview" title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
            </div>
            <h ref={myRef} />
            <br/>
            <hr/>
            <br/>
            <div className="">
                <div className="blog-all-topics" >
                    <div className="title">All Topics on {relatedTopics}</div>
                    <hr/>
                    {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                            return(
                                <div className="all-topics-items">   
                                    <NavLink className="nav-link" to={items.link} exact>
                                        <div className="blog-all-topics-name">
                                            {items.name}
                                        </div>

                                    </NavLink>
                                </div>
                            )
                    })}

                </div>
            </div>
        </div>

        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <MiniFooter/>
        </>
    )

}

export default TryWithResources;