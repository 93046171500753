
import React, { useRef } from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom'
import { BlogPages } from '../InterviewQuestionPages/Blog'
import MiniFooter from "../MiniFooter";
import { Helmet } from "react-helmet";

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function PowerOfANumber(){

    const cpbrdData1 = "class Power {\n\tpublic double getPower( double base, int expo ) {\n\t\tdouble out = 1;\n\t\tfor ( int i = 1; i <= expo; i++ ) {\n\t\t\tout = out * base ;\n\t\t}\n\t\treturn out ;\n\t}\n}";
    const cpbrdData2 = "class Power {\n\tpublic double getPower( double base, int expo ) {\n\t\tif ( expo == 0 )\n\t\t\treturn 1 ;\n\n\t\tif ( expo < 0 )\n\t\t\treturn getPower( 1/base, - expo ) ;\n\n\t\tif ( expo %2 == 0 ) {\n\t\t\treturn getPower( base * base, (int) expo/2 ) ;\n\t\t} else {\n\t\t\treturn base * getPower( base * base, (int) expo/2 ) ;\n\t\t}\n\t}\n}";


    const relatedTopics = "Java";

    const myRef = useRef(null);
    const scrollPage = () => myRef.current.scrollIntoView() 

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }


    return(
        <>
            <Helmet>
                <title>Power Of a Given Number Without Built-in Functions</title>
                <meta name="description" content=" In this tutorial, we are going to learn about how to write the code for Power of a Given Number without built-in Functions. " />
            </Helmet>
        <div className="blogLayout">
            <div className="blog-page-layout">
                <h1>
                <div className="title">
                    Power Of a Given Number Without Built-in Functions
                </div>
                </h1>
                <ToastContainer/>
                <br/>
                <div className="blog-page-tag-border">
                    <div className="parent">
                        <div className="blog-page-tag" onClick={scrollPage}>Java</div>
                    </div>
                </div>
                
                <hr/>

                <div className="blog-page-content">
                    <div>
                        In this tutorial, we are going to learn about how to write the code for Power of a Given Number Without Built-in Functions.
                    </div>    
                    <div>
                        <ul>
                            <br/>
                            <li>
                                <p>
                                    Basic Code : 
                                    <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData1}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classname bold-font">{"class "}</c>
                                                <c className="codeblock-classnamevalue">{"Power"}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" public "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"double"}</c>
                                                <c className="codeblock-classnamevalue">{" getPower"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"double"}</c>
                                                <c className="codeblock-css">{" base"}</c>
                                                <c className="codeblock-classnameEqual">{", "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"int"}</c>
                                                <c className="codeblock-css">{" expo"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-tagvalue bold-font">{" double"}</c>
                                                <c className="codeblock-css">{" out"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-cssval">{" 1"}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" for "}</c>
                                                <c className="codeblock-classnameEqual">{" ( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"int"}</c>
                                                <c className="codeblock-css">{" i"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-cssval">{" 1"}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                                <c className="codeblock-css">{" i"}</c>
                                                <c className="codeblock-classnameEqual">{" <"}</c>
                                                <c className="codeblock-classnameEqual">{"="}</c>
                                                <c className="codeblock-css">{" expo"}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                                <c className="codeblock-css">{" i"}</c>
                                                <c className="codeblock-classnameEqual">{"+"}</c>
                                                <c className="codeblock-classnameEqual">{"+"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-css">{" out"}</c>
                                                <c className="codeblock-classnameEqual">{" = "}</c>
                                                <c className="codeblock-css">{" out "}</c>
                                                <c className="codeblock-classnameEqual">{" * "}</c>
                                                <c className="codeblock-css">{" base "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" return "}</c>
                                                <c className="codeblock-css">{" out "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classnameEqual">{"}"}</c>
                                            </code>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        - <strong>Time Complexity</strong> of the Above code will be <strong>O(Expo)</strong>.
                                    </div>
                                </p>
                                
                            </li>
                            <li>
                                <p>
                                <div>
                                Now can you Think about how to reduce the <strong>Time Complexity</strong> of the Above code.
                                </div>
                                <br/>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData2}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classname bold-font">{"class "}</c>
                                                <c className="codeblock-classnamevalue">{"Power"}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" public "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"double"}</c>
                                                <c className="codeblock-classnamevalue">{" getPower"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"double"}</c>
                                                <c className="codeblock-css">{" base"}</c>
                                                <c className="codeblock-classnameEqual">{", "}</c>
                                                <c className="codeblock-tagvalue bold-font">{"int"}</c>
                                                <c className="codeblock-css">{" expo"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>
                                            </div>


                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" if "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-css">{" expo "}</c>
                                                <c className="codeblock-classnameEqual">{" == "}</c>
                                                <c className="codeblock-cssval">{" 0 "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" return "}</c>
                                                <c className="codeblock-cssval">{" 1 "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>
                                            <br/>
                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" if "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-css">{" expo "}</c>
                                                <c className="codeblock-classnameEqual">{" < "}</c>
                                                <c className="codeblock-cssval">{" 0 "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" return "}</c>
                                                <c className="codeblock-classnamevalue">{" getPower"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-cssval">{" 1"}</c>
                                                <c className="codeblock-classnameEqual">{"/"}</c>
                                                <c className="codeblock-css">{"base"}</c>
                                                <c className="codeblock-classnameEqual">{", "}</c>
                                                <c className="codeblock-classnameEqual">{"-"}</c>
                                                <c className="codeblock-css">{" expo"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <br/>
                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" if "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-css">{" expo "}</c>
                                                <c className="codeblock-classnameEqual">{"%"}</c>
                                                <c className="codeblock-cssval">{"2 "}</c>
                                                <c className="codeblock-classnameEqual">{" == "}</c>
                                                <c className="codeblock-cssval">{" 0 "}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{" {"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" return "}</c>
                                                <c className="codeblock-classnamevalue">{" getPower"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-css">{" base"}</c>
                                                <c className="codeblock-classnameEqual">{" * "}</c>
                                                <c className="codeblock-css">{"base"}</c>
                                                <c className="codeblock-classnameEqual">{", "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-tagvalue bold-font">{"int"}</c>
                                                <c className="codeblock-classnameEqual">{") "}</c>
                                                <c className="codeblock-css">{" expo"}</c>
                                                <c className="codeblock-classnameEqual">{"/"}</c>
                                                <c className="codeblock-cssval">{"2"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                                <c className="codeblock-classname bold-font">{" else "}</c>
                                                <c className="codeblock-classnameEqual">{" { "}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classname bold-font">{" return "}</c>
                                                <c className="codeblock-css">{"base"}</c>                                                
                                                <c className="codeblock-classnameEqual">{" * "}</c>
                                                <c className="codeblock-classnamevalue">{" getPower"}</c>
                                                <c className="codeblock-classnameEqual">{"( "}</c>
                                                <c className="codeblock-css">{" base"}</c>
                                                <c className="codeblock-classnameEqual">{" * "}</c>
                                                <c className="codeblock-css">{"base"}</c>
                                                <c className="codeblock-classnameEqual">{", "}</c>
                                                <c className="codeblock-classnameEqual">{" ("}</c>
                                                <c className="codeblock-tagvalue bold-font">{"int"}</c>
                                                <c className="codeblock-classnameEqual">{") "}</c>
                                                <c className="codeblock-css">{" expo"}</c>
                                                <c className="codeblock-classnameEqual">{"/"}</c>
                                                <c className="codeblock-cssval">{"2"}</c>
                                                <c className="codeblock-classnameEqual">{" ) "}</c>
                                                <c className="codeblock-classnameEqual">{"; "}</c>
                                            </code>
                                            </div>



                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                            <c>&emsp;&emsp;</c>
                                                <c className="codeblock-classnameEqual">{" }"}</c>
                                            </code>
                                            </div>

                                            <div>
                                            <code classname="codeblock"> 
                                                <c className="codeblock-classnameEqual">{"}"}</c>
                                            </code>
                                            </div>
                                        
                                        </div>
                                    </div>

                                    <br/>
                                    <div>
                                        - If we check the <strong>Time Complexity</strong> of the Above code.
                                        it will be <strong>O(logN)</strong>.
                                    </div>

                                </p>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <div>

            <div>
                <div className="blog-topics-div">
                    <div className="blog-topics">
                        <div className="title">
                            Related Topics
                        </div>
                        {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                        //if (items.tag === relatedTopics){
                            return(
                                <div className="blog-related-topic-value">   
                                <NavLink className="nav-link" to={items.link} exact>
                                    <div className="blog-all-topics-name">
                                        {items.name}
                                    </div>       
                                </NavLink>
                               </div>
                            )
                        //}
                        })}
                    </div>
                </div>
            </div>

            {/* <div className="blog-topics-div">
                        <div className="blog-topics">
                            <div className="title">
                                Related Topics
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-react"/>React
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-java"/>Java
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-js-square"/>JavaScript
                            </div>
                        </div>
                    </div> */}

                    <div>
                                            {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                        
                    </ins>
                    </div>

            </div>
        </div>
        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>
        <br/>
        <div className="shareLink">
            <hr/>

            <div className="share-div">
                <span className="shareText">Share : </span>
                <a href="https://web.whatsapp.com/send?text=https://interreview.com" target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site http://www.website.com.&amp;to=interreview@gmail.com" title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>
                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.interreview.com/" title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=Interreview !&amp;url=https://www.interreview.com/&amp;hashtags" title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href="https://telegram.me/share/url?url=https://www.interreview.com/ &amp;text=Interreview" title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
            </div>
            <h ref={myRef} />
            <br/>
            <hr/>
            <br/>
            <div className="">
                <div className="blog-all-topics" >
                    <div className="title">All Topics on {relatedTopics}</div>
                    <hr/>
                    {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                        //if (items.tag === relatedTopics){
                            return(
                                <div className="all-topics-items">   
                                    <NavLink className="nav-link" to={items.link} exact>
                                        <div className="blog-all-topics-name">
                                            {items.name}
                                        </div>

                                    </NavLink>
                                </div>
                            )
                        //}
                    })}

                </div>
            </div>
        </div>

        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

        <MiniFooter/>

        </>
    )

}

export default PowerOfANumber;