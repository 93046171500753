import React, { useEffect, useState } from 'react'
import Footerpage from '../Footerpage'
import "../Pages.css"
//import {interviewExperienceData} from './FirebaseCURDOperations'
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import {db} from './firebase-config'
//import { collection, getDocs, addDoc, doc, updateDoc } from 'firebase/firestore';
//import {db, app} from './firebase-config'
//import { set } from 'firebase/database';
import PopUp from '../PopUp';
//import OutsideAlerter from '../OutsideAlerter';



function LoginApprovalPage(){

    const [contributionList, setContributionList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const [approveList, setApproveList] = useState([]);
    const [nonApproveList, setNonApproveList] = useState([]);
    const [checkApprove, setCheckApprove] = useState(false);


    //pagination code
    const contributionCount = searchList.length

    const[ isActiveLeft, setActiveLeft] = useState(false);
    const[ isActiveRight, setActiveRight] = useState(true);

    const [pageNumber, setPageNumber] = useState(0);
    const perPage = 5;
    const pageVisited = pageNumber * perPage;
    

    const interviewExperienceCollectionRef = collection(db, "Interview-experience")


    const[ approval, setApproval] = useState(false);



    useEffect(()=> {

        const getInterviewExperience = async () => {
            const data = await getDocs(interviewExperienceCollectionRef);
            console.log(data);    
            setContributionList(data.docs.map((doc) => ({ ...doc.data(), id : doc.id})));



            const nonApproveListTemp = []
            const approveListTemp = []
            data.docs.forEach((doc) => {
                //alert("checking 11" + doc.data().approval)
                if (doc.data().approval === 0) {
                   nonApproveListTemp.push( { ...doc.data(), id : doc.id})                   
                } else {
                    approveListTemp.push({...doc.data(), id : doc.id})

                }
            });
            setSearchList(nonApproveListTemp);
            setNonApproveList(nonApproveListTemp);
            setApproveList(approveListTemp);
            setApproval(false);
        };
    
        getInterviewExperience();
    
    }, [approval, setApproval, interviewExperienceCollectionRef]);


/* -- Approval and Non-Approval Operations Start -- */
const displayApproveList = () => {
    setSearchList(approveList);
    setCheckApprove(true);

}
const displayNonApproveList = () => {
    setSearchList(nonApproveList);
    setCheckApprove(false);
}
/* -- Approval and Non-Approval Operations End -- */

    /* Update Firebase Database start*/
    // const TestingAlert = () => {
    //     alert("testing");

    // }

    const updateApproval = async(id) => {

        const userDocs = doc(db, "Interview-experience", id) 

        const newFields = {approval : 1}

        await updateDoc(userDocs, newFields)
        setApproval(true);
    }


    const [state, setState] = useState(false);
    const [interviewId, setInterviewId]= useState({});
    
    const togglePop = (data) => {

        setState(!state);
        setInterviewId(data);
      };

    /* Update Firebase Database end */


    //pagination Variables

    const [pageValue, setPageValue] = useState(pageVisited+perPage);


    const onChangeRight = () => {
        if (contributionCount > pageVisited+perPage){
            setPageNumber(pageNumber+1);
            setPageValue(pageVisited+perPage)
            setActiveLeft(true);
            if (contributionCount/10 < pageNumber+3){
                setActiveRight(false);
                setPageValue(contributionCount);
            }
        }
    };

    const onChangeLeft = () => {
        if (pageVisited > 0){
            setPageValue(pageVisited+perPage)
            setPageNumber(pageNumber-1);
            if (pageNumber > 1){
                setActiveRight(true);
            } else {
                setActiveLeft(false);
            }

        }
    };

    const displayList = searchList.slice(pageVisited, pageVisited + perPage).map((interview) => {
        console.log("interview : "+interview.id)

        var questionsList = interview.interviewQuestions
        var out = "";
        if (questionsList != null){
            for (var i = 0; i < questionsList.length; i++){
                out  = out  + "- " +questionsList[i]+" \n"
            }
        }
        const displayQuestionList = questionsList.map((eachQuesList) => {
            return(
                <div>- {eachQuesList}</div>
            )
        })
        //alert(interview.rating);
        var rating1 = "fa fa-star ";
        var rating2 = "fa fa-star ";
        var rating3 = "fa fa-star ";
        var rating4 = "fa fa-star ";
        var rating5 = "fa fa-star ";

        if (parseInt(interview.rating) !== 0) {
            switch(parseInt(interview.rating)){
                case 5 : 
                rating5 = rating5 + "checked";
                rating4 = rating4 + "checked";
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break
                case 4 : 
                rating4 = rating4 + "checked";
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break
                case 3 : 
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break
                case 2 : 
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break
                case 1 : 
                rating1 = rating1 + "checked";
                break
                default:
                    break;
            }

        }

        console.log("interview : "+interview.interviewQuestions)
                        return (
                            <div>
                                    <div className="inteview-view">
                                        <div className="company-name-div">
                                            <div className="company-Name">
                                                {interview.companyName}
                                            </div>
                                            <div className="comp-name-share">

                                            {checkApprove ? (
                                                <div className="home-approved-view" > 
                                                    <k><i className="fa fa-check"/>Approved</k>
                                                </div>
                                            ) : 
                                            (
                                                <div className="home-approval" onClick={() => updateApproval(interview.id)} > 
                                                    <k><i className="fa fa-check"/>Approve</k>
                                                </div> 
                                            ) }
                                            <div className="like-name"> 
                                               <div onClick={() => {togglePop(interview)}} data-div_name={interview}><i className="fa fa-edit"/>Edit</div>
                                            </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="company-name-div">
                                            <div className="company-Name">{interview.jobRole}</div>
                                            <div >Date : {interview.date}</div>
                                        </div>
                                        <div>
                                            <div className="inteview-title">rating</div>

                                            <span className={rating1}></span>
                                            <span className={rating2}></span>
                                            <span className={rating3}></span>
                                            <span className={rating4}></span>
                                            <span className={rating5}></span>
                                        </div>
                                        <br/>
                                        <div className="inteview-title">Application Process</div>
                                        <div> - {interview.applicationProcess}</div>
                                        <br/>
                                        <div className="inteview-title">Interivew Process</div>
                                        <div> - {interview.interviewProcess} </div>
                                        <br/>
                                        <div className="inteview-title">Inteview Questions</div>
                                        {displayQuestionList}
                                        {/* {(() => {
                                                    if (questionsList != null){
                                                        var out = "";
                                                        for (var i = 0; i < questionsList.length; i++){

                                                            return (
                                                                <div>{questionsList[i]}</div>
                                                              )

                                                            //out  = out  + "- " +questionsList[i]+" ,"
                                                        }
                                                        // return (
                                                        //     <div>{out}</div>
                                                        //   )

                                                    }

                                        })()} */}
                                        {/* <div> - {interview.inerviewQuestions}</div> */}
                                        <br/>
                                        <div className="inteview-title">Suggestions</div>
                                        <div> - {interview.suggestions}</div>
                                        <br/>
                
                                    </div>
                                </div>
                        )

                        })

    // const display =  interviewExperienceData.map((interviewDetails) => {
    //     alert("interviewQuestions :" +interviewDetails)
    //                     console.log("interviewDetails : "+interviewDetails)
    //                     return(
    //                     interviewDetails.map((interview)=> {
    //                     console.log("interview : "+interview.companyName)
    //                     return(
    //                         <div>
    //                             <h1>{interview.companyName}</h1>
    //                         </div>
    //                     );
    //                 })
    //                     )

    //             })


    //Search Result.
        const [searchText, setSearchText] = useState("");
        
        const searchHandler = (event) => {

            event.preventDefault();

            const newSearchList = [];
            contributionList.forEach((interview) => {
                if(interview.companyName.toLowerCase() === searchText.toLowerCase()){
                    newSearchList.push(interview);
                }
            });
            if (newSearchList.length > 0){
                setSearchList(newSearchList);
            } else {
                setSearchList(contributionList);
                alert("No Search result Found ")
            }

        }
    
        return(
        <div>
            <div>
                <div>
                    <div className="container iq-java-content">


                    <div>
                        {state && 
                        <PopUp
                        content={interviewId}
                        handleClose={togglePop}
                        /> 
                        }
                    </div>


                        <div>
                            <form onSubmit={searchHandler}>
                                <input type="text" list="browsers" placeholder="Search Company Names .." className="search-input" onChange={(event) => {setSearchText(event.target.value)}}/>
                                    <datalist id="browsers">
                                    <option value="Oracle"/>
                                    <option value="Amazon"/>
                                    <option value="Tcs"/>
                                    <option value="Google"/>
                                    <option value="Zoho"/>
                                  </datalist>
                                <button type="submit" className="search-button"><i className="fa fa-search"> Search</i></button>
                            </form>
                        </div>

                    </div>
                    <div className="container home-content">
                    
                    <hr/>
                    <div className="comp-name-share">
                            <div className={ checkApprove ? "home-approved" : "home-approval"} onClick={displayApproveList} > 
                                        <k><i className="fa fa-check"/>Approval List ({approveList.length})</k>
                            </div>
                            
                            <div className={checkApprove ? "like-name" : "home-nonApproved"}> 
                                    <div onClick={displayNonApproveList} ><i className="fa fa-times"/>Non Approval List ({nonApproveList.length})</div> 
                                </div>
                    </div>
                    <hr/>
                    <br/>

                    {/* {display} */}
                    {displayList }
                    {/* checking <FirebaseCURDOperations/> */}
                    <div className="inteview-view">
                        <div className="company-name-div">
                            <div className="company-Name">
                                CompanyNamePhenomPeople
                            </div>
                            <div className="comp-name-share">
                                <div className="fas fa-share-alt"> 
                                    Share 
                                </div>
                                <div className="far fa-thumbs-up like-name"> 
                                    Like
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="company-name-div">
                            <div className="company-Name">Job title</div>
                            <div >Date :</div>
                        </div>
                        <div>
                            <div className="inteview-title">rating</div>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                        </div>
                        <br/>
                <div className="inteview-title">Application Process</div>
                <div> - applied through Interview</div>
                <br/>
                <div className="inteview-title">Interivew Process</div>
                <div> - 2 rounds of interview </div>
                <br/>
                <div className="inteview-title">Inteview Questions</div>
                <div> - what is java </div>
                <br/>
                <div className="inteview-title">Suggestions</div>
                <div> - for code pratice check the LeetCode</div>
                <br/>
                
            </div>
            <div className="inteview-view">
                <div className="company-name-div">
                    <div className="company-Name">Company Name</div>
                    <div className="comp-name-share">
                        <div className="fas fa-share-alt"> Share </div>
                        <div className="far fa-thumbs-up like-name"> Like</div>
                    </div>
                </div>
                <hr/>
                <div className="company-name-div">
                    <div className="company-Name">Job title</div>
                    <div>Date :</div>
                </div>
                <div className="inteview-title">rating</div>
                <br/>
                <div className="inteview-title">Application Process</div>
                <div> - applied through Interview</div>
                <br/>
                <div className="inteview-title">Interivew Process</div>
                <div> - 2 rounds of interview </div>
                <br/>
                <div className="inteview-title">Inteview Questions</div>
                <div> - what is java </div>
                <br/>
                <div className="inteview-title">Suggestions</div>
                <div> - for code pratice check the LeetCode</div>
                <br/>
                
            </div>
            <hr/>

            <div className="prev-next-view">
                <i>{pageVisited+1}-{pageValue} of {contributionCount}</i>
                <i className= {isActiveLeft ? "fas fa-chevron-left prev-btn" : "fas fa-chevron-left prev-btn1 btn-disabled" } onClick={onChangeLeft} ></i>
                <i className= {isActiveRight ? "fas fa-chevron-right next-btn" : "fas fa-chevron-right next-btn1 btn-disabled"} onClick={onChangeRight}></i>
            </div>

            </div>
            </div>
            <div>SideBar</div>
            </div>
            <Footerpage/>
        </div>
        
        )
}


export default LoginApprovalPage;