
import React, {useState} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom'
import { Tools } from '../Tools/ToolsPage'

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../Footerpage";

function URLEncoderAndDecoder(){

    const [urlString, setUrlString] = useState("");
    const currentTool = "URL Encoder/Decoder";


    const EncodeUrl = () => {
        //alert(encodeURIComponent(urlString));
        //alert(window.atob(urlString));
        setUrlString(encodeURIComponent(urlString));
        //setUrlString(btoa(urlString));
        
    }

    const DecodeUrl = () => {
        setUrlString(decodeURIComponent(urlString));
        //setUrlString(atob(urlString));
    } 

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
        console.log('page to reload')
    }

    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>URL Encoder / Decoder</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Free Online URL Encoder/Decoder.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">
                            {/* <h1>
                                <div className="title">
                                URL Encoder/Decoder
                                </div>
                            </h1> */}
                            <ToastContainer/>
                            {/* <hr/> */}

                            <div className="blog-page-content">
                            <div>
                                <div><h5><strong>Paste Your  Data Below : </strong></h5></div>
                                <div className="jsonFormat-textarea" >
                                    <textarea type="text" placeholder="Enter your URL here ..." onChange={(event) => {setUrlString(event.target.value)}} value={urlString}/>
                        
                                </div>
                                <br/>
                                <div className="text-center">
                                    <i className="json-copyLink convert-button-size2"><button onClick={EncodeUrl}>Encode</button></i>
                                    <i className="json-copyLink convert-button-size2"><button onClick={DecodeUrl}>Decode</button></i>
                                    <CopyToClipboard text={urlString}>
                                        <i className="json-copyLink convert-button-size2" title="copy" ><button onClick={diffToast}>Copy</button></i>
                                    </CopyToClipboard>
                                </div>
                            </div>
                           <br/>

                        </div>
                    </div>
                </div>

                <div className="blogLayout">

            <div className="json-innerPage-layout">
                <div className="blog-page-content">
                <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
                <strong> About</strong></h5></div>
                    <hr/>
                    <div><h5><strong>URL Encoder / Decoder Tool</strong></h5></div>
                    <div>Check this online URL Encoder / Decoder Tool, which will helps to convert an Encoded String to Decode and Decoded String to Encode.
                           </div>
                    <div></div>

                </div>
            </div>
        </div>
        </div>



        <div>                

            <div className="tools-topics-div">
                <div className="tools-topics">
                    <div className="title">
                    <b className="fas fa-cog" />
                         <strong> Other Tools</strong>
                    </div>
                    {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

                    return(
                        <div className="tools-topic-value">   
                        <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                            <div className="blog-all-topics-name" >
                                {items.name}
                            </div>       
                        </NavLink>
                       </div>
                       )
                    })}
                </div>
            </div>
            <div className="tools-topics-div">
                <div>
                    {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">     
                    </ins>
                </div>
            </div>
        </div>


    </div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default URLEncoderAndDecoder;