
import React, {useState} from "react";
import "../../Pages.css"
import { NavLink } from 'react-router-dom'
import { Tools } from '../ToolsPage'

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../../Footerpage";
import QRCode from 'qrcode.react'

import Popup from 'reactjs-popup';


export const Topics = [

    {
        title:"URL",
        icon:"fa fa-globe QR-content-value1",
        link : "/tools/URL-QR-Code-Generator",
        select:0,        
    },

    {
        title:"Text",
        icon:"fas fa-file QR-content-value1",
        link : "/tools/Text-QR-Code-Generator",
        select:0,
    },
    {
        title:"SMS",
        icon:"fas fa-sms QR-content-value1",
        link : "/tools/SMS-QR-Code-Generator",
        select:0,
    },
    {
        title:"WIFI",
        icon:"fa fa-wifi QR-content-value1",
        link : "/tools/WIFI-QR-Code-Generator",
        select:0,
    },
    {
        title:"Mail",
        icon:"fa fa-envelope QR-content-value1 selected",
        link : "/tools/Mail-QR-Code-Generator",
        select:1,
    },
    {
        title:"Call",
        icon:"fas fa-phone-alt QR-content-value1",
        link : "/tools/Call-QR-Code-Generator",
        select: 0,
    },
    {
        title:"Youtube",
        icon:"fab fa-youtube QR-content-value1 ",
        link : "/tools/Youtube-QR-Code-Generator",
        select:0
    },
    {
        title:"Twitter",
        icon:"fab fa-twitter QR-content-value1",
        link : "/tools/Twitter-QR-Code-Generator",
        select:0,
    },
    {
        title:"Instagram",
        icon:"fab fa-instagram QR-content-value1",
        link : "/tools/Instagram-QR-Code-Generator",
        select:0,
        //icon_link:"https://img.icons8.com/ios-filled/25/000000/jquery.png"
    },
    // {
    //     title:"More",
    //     icon:"fa  QR-content-value1",        
    //     link : "/interviewQuestions/nodejs",
    //     select:-1,
    // },
    
    
]

function MailQRCodeGenerator(){
    
    const titleImage = "/company_logos/mail.png"

    const [topic, settopic] = useState(Topics.slice(0,9));
    const [topicCheck, settopicCheck] = useState(false);

    const [urlString, setUrlString] = useState("intereviews.com");
    const [outputString, setOutputString] = useState("");
    const [encodeString, setEncodeString] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [colorPickerValue, setColorPickerValue] = useState("black");
    const [QRViewLevel, setQRViewLevel] = useState('H');
    const [imageUrl, setImageUrl] = useState(titleImage);
    const [imageOption, setImageOption] = useState(0);
    const [frame, setFrame] = useState(0);
    const [qrBorderRadius, setQRBorderRadius] = useState(0);
    const [qrBorder, setQRBorder] = useState(0);
    const [topFloatingScanMeTag, setTopFloatingScanMeTag] = useState("none");
    const [bottomFloatingScanMeTag, setBottomFloatingScanMeTag] = useState("none");
    const [attachScanMeTag, setAttachScanMeTag] = useState("none");
    const [attachScanMeTagBorder, setAttachScanMeTagBorder] = useState(0);
    const [fileName, setFileName] = useState("intereviews");
    const [fileFormat, setFileFormat] = useState("jpg");
    const [fileFormatType, setFileFormatType] = useState("image/jpeg");
    const [checkPopup, setCheckPopup] = useState(false);
    const [bgdColorPicker, setBgdColorPicker] = useState("white");
    const [opactiyChng, setOpacityChng] = useState(1);
    const [imgPositionX, setImgPositionX] = useState(null);
    const [imgPositionY, setImgPositionY] = useState(null);

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const qrOptArwAry = [
        {
            id: 1,
            value: "fa fa-chevron-down",
        },
        {
            id: 2,
            value: "fa fa-chevron-down",
        },
        {
            id: 3,
            value: "fa fa-chevron-down",
        },
        {
            id: 4,
            value: "fa fa-chevron-down",
        },
    ]

    const [QROptionArrows, setQROptionArrows] = useState(qrOptArwAry);

  //  const clipboard = "";
    const currentTool = "QR Code Generator"


    const EncodeUrl = () => {
        downloadQRCode();
        //setOutputString(btoa(urlString));
        //alert("after " + base58Encode(urlString))
    }



    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    const handleCheckBox = () => {
        setCheckBox(!checkBox);
    }
    const handleInputString = () => {

        setUrlString("mailto:"+email+"?subject="+encodeURIComponent(subject)+"&body="+encodeURIComponent(message))
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }
    const handleSubject = (event) => {
        setSubject(event.target.value);
    }
    const handleMessage = (event) => {
        setMessage(event.target.value);
    }


    //ytes, and force a conversion before executing,
// and convert outputs back into strings.

function refreshPage() {
    setTimeout(()=>{
        window.location.reload(false);
    }, 0);
    console.log('page to reload')
}

const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const pngUrl = document.getElementById("qrcode")
    .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    // const pngUrl = canvas
    //   .toDataURL("image/png")
    //   .replace("image/png", "image/octet-stream");
    //alert(pngUrl)
    //var img = new Image();    
    let img = document.createElement("img");
 img.src = pngUrl;
 img.style.border = "10px solid orange";
 img.style.borderRadius = "10px";

document.body.appendChild(img);
//img.download = 'sample.png';
//img.click();

    

    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = 'sample.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const toogleQR_Options = (classname, position) => {

    var x = document.getElementsByClassName(classname);
    // alert(position);
    // alert(x[position].style.display);
    if (x[position].style.display === "none" || x[position].style.display === "") {

        for (var i=0; i <4; i++){
            x[i].style.display="none";
        }
      x[position].style.display = "block";

    //   alert(QROptionArrows[position])
    //   QROptionArrows[position] = "fa fa-chevron-up";
    //   alert(QROptionArrows[position])
    //   setQROptionArrows(QROptionArrows);
      setQROptionArrows(
        QROptionArrows.map((QROptionArrow) =>
            // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
            QROptionArrow.id === position+1
                ? { ...QROptionArrow, value: "fa fa-chevron-up" }
                : { ...QROptionArrow, value : "fa fa-chevron-down" }
        )
    );
    //alert(QROptionArrows[position].value)
    // QROptionArrows[position].value = "fa fa-chevron-up";
    // alert(QROptionArrows[position].value);
    } else {
      x[position].style.display = "none";
      setQROptionArrows(
        QROptionArrows.map((QROptionArrow) =>
            // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
            QROptionArrow.id === position+1
                ? { ...QROptionArrow, value: "fa fa-chevron-down" }
                : { ...QROptionArrow, value : "fa fa-chevron-down" }
        )
    );
    }
  }
    

  const selectShape = (level) => {
    setQRViewLevel(level)
  }

  const createImage = (color) => {
    var c = document.getElementById("myCanvas");    
    var ctx = c.getContext("2d");
    ctx.font = "bold 30px arial";
    ctx.fillStyle = color;
    ctx.fillText("SCAN",8, 35 );
    ctx.font = "bold 58px arial";
    ctx.fillStyle = color;
    ctx.fillText("ME", 8, 85);
    //ctx.strokeText("Scan Me",10,50);
    setImageOption(1);
    setImageUrl(document.getElementById('myCanvas').toDataURL());

  }

  const createBorderImage = (color) => {
    var c = document.getElementById("myCanvas1");
    var ctx = c.getContext("2d");
    ctx.rect(5, 5, 90, 90);
    ctx.lineWidth = 3;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.font = "bold 25px arial";
    ctx.fillStyle = color;
    ctx.fillText("SCAN",14, 38 );
    ctx.font = "bold 48px arial";
    ctx.fillStyle = color;
    ctx.fillText("ME", 14, 82);
    //ctx.strokeText("Scan Me",10,50);
    setImageOption(2);
    setImageUrl(document.getElementById('myCanvas1').toDataURL());
  }
  const clear = () => {
    var c = document.getElementById("myCanvas2");
    var ctx = c.getContext("2d");
    ctx.clearRect(0, 0, ctx.width, ctx.height);
  }


  const getFrame1 = () => {
      //Outer Rectangular with Sharpe Edge Border
    var c = document.getElementById("myCanvas2");
    var ctx = c.getContext("2d");

    //background
    if (fileFormat !== "png") {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, 330, 330);
    }

    //image 
    var img = document.getElementById("qrcode");
    ctx.drawImage(img,40,40);

    //reactangular Border
    ctx.rect(10, 10, 310, 310);    
    ctx.lineWidth = 5;
    ctx.strokeStyle = colorPickerValue;
    ctx.stroke();

    //downloadable link 
    var link = document.createElement('a');
    link.download = fileName+"."+fileFormat;
    link.href = c.toDataURL(fileFormatType)
    link.click();
  }


  const getFrame2 = () => {
      //Outer Rectangular with Curve Edge Border
      var c = document.getElementById("myCanvas2");
      var ctx = c.getContext("2d");

      //background
      if (fileFormat !== "png") {
          ctx.fillStyle = "white";
          ctx.fillRect(0, 0, 330, 330);
      }
      //Image
      var img = document.getElementById("qrcode");
      ctx.drawImage(img,40,40);

        //Curve Edge border
        CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
        if (width < 2 * radius) radius = width / 2;
        if (height < 2 * radius) radius = height / 2;
        this.beginPath();
        this.moveTo(x + radius, y);
        this.arcTo(x + width, y, x + width, y + height, radius);
        this.arcTo(x + width, y + height, x, y + height, radius);
        this.arcTo(x, y + height, x, y, radius);
        this.arcTo(x, y, x + width, y, radius);
        this.closePath();
        return this;
      }
      ctx.roundRect(10, 10, 310, 310, 10);
      ctx.lineWidth = 8;
      ctx.strokeStyle = colorPickerValue;
      ctx.stroke();
    
    //   //Rectangular Border
    //   ctx.rect(10, 10, 310, 310);      
    //   ctx.lineWidth = 5;
    //   ctx.strokeStyle = "black";
    //   ctx.stroke();

      //Download Link
      var link = document.createElement('a');
      link.download = fileName+"."+fileFormat;
      link.href = c.toDataURL(fileFormatType)
      link.click();
  }

  const getFrame3 = () => {
      //Outer Sharp Edge Border with attached SCAN ME
    var c = document.getElementById("myCanvas3");
    var ctx = c.getContext("2d");

    //background
    if (fileFormat !== "png") {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, 330, 400);
    }
    //image
    var img = document.getElementById("qrcode");
    ctx.drawImage(img,40,40);

    //border
    ctx.rect(10, 10, 310, 310);    
    ctx.lineWidth = 5;
    ctx.strokeStyle = colorPickerValue;
    ctx.stroke();

    //bottomScanMe
    ctx.fillStyle = colorPickerValue;
    ctx.fillRect(7, 310, 316, 80);

    //text
    ctx.font = "bold 50px arial";
    ctx.fillStyle = "white";
    ctx.fillText("SCAN ME",50, 365 );

    //downloadLink
    var link = document.createElement('a');
    link.download = fileName+"."+fileFormat;
    link.href = c.toDataURL(fileFormatType)
    link.click();
  }


  const getFrame4 = () => {
        //Outer Curve Edge Border with attached SCAN ME
    var c = document.getElementById("myCanvas3");
    var ctx = c.getContext("2d");

    //background
    if (fileFormat !== "png") {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, 330, 400);
    }
    //image
    var img = document.getElementById("qrcode");
    ctx.drawImage(img,40,40);

    //Curve Edge border
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
        if (width < 2 * radius) radius = width / 2;
        if (height < 2 * radius) radius = height / 2;
        this.beginPath();
        this.moveTo(x + radius, y);
        this.arcTo(x + width, y, x + width, y + height, radius);
        this.arcTo(x + width, y + height, x, y + height, radius);
        this.arcTo(x, y + height, x, y, radius);
        this.arcTo(x, y, x + width, y, radius);
        this.closePath();
        return this;
      }
      ctx.roundRect(10, 10, 310, 310, 10);
      ctx.lineWidth = 8;
      ctx.strokeStyle = colorPickerValue;
      ctx.stroke();

    //bottomScanMe
    //ctx.fillRect(6, 315, 318, 80);
    ctx.roundRect(6, 308, 318, 84, 10);
    ctx.fillStyle = colorPickerValue;
    ctx.fill();

    //text
    ctx.font = "bold 50px arial";
    ctx.fillStyle = "white";
    ctx.fillText("SCAN ME",50, 365 );



    //downloadLink
    var link = document.createElement('a');
    link.download = fileName+"."+fileFormat;
    link.href = c.toDataURL(fileFormatType)
    link.click();
  }

  const getFrame5 = () => {
    //Curve Edge-With floating ScanMe on Top
    var c = document.getElementById("myCanvas4");
    var ctx = c.getContext("2d");

    //background
    if (fileFormat !== "png") {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, 330, 452);
    }
    //image
    var img = document.getElementById("qrcode");
    ctx.drawImage(img,40,160);

    //border
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
        if (width < 2 * radius) radius = width / 2;
        if (height < 2 * radius) radius = height / 2;
        this.beginPath();
        this.moveTo(x + radius, y);
        this.arcTo(x + width, y, x + width, y + height, radius);
        this.arcTo(x + width, y + height, x, y + height, radius);
        this.arcTo(x, y + height, x, y, radius);
        this.arcTo(x, y, x + width, y, radius);
        this.closePath();
        return this;
    }
    ctx.roundRect(10, 130, 310, 310, 10);
    ctx.lineWidth = 8;
    ctx.strokeStyle = colorPickerValue;
    ctx.stroke();

    //triangle fill
    let height = 40 * Math.cos(Math.PI / 6);
    ctx.beginPath();
    ctx.moveTo(100, 83);
    ctx.lineTo(150, 83);
    ctx.lineTo(125, 83 + height);
    ctx.fillStyle = colorPickerValue;
    ctx.fill();

    //bottomScanMe
    //ctx.fillRect(6, 315, 318, 80);
    ctx.roundRect(6, 10, 318, 84, 10);
    ctx.fillStyle = colorPickerValue;
    ctx.fill();

    //text
    ctx.font = "bold 50px arial";
    ctx.fillStyle = "white";
    ctx.fillText("SCAN ME",50, 68);

    //downloadLink
    var link = document.createElement('a');
    link.download = fileName+"."+fileFormat;
    link.href = c.toDataURL(fileFormatType)
    link.click();
}



  const getFrame6 = () => {
        //Curve Edge-With floating ScanMe on Bottom
        var c = document.getElementById("myCanvas4");
        var ctx = c.getContext("2d");

        //background
        if (fileFormat !== "png") {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, 330, 452);
        }
        //image
        var img = document.getElementById("qrcode");
        ctx.drawImage(img,40,40);

        //border
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
      if (width < 2 * radius) radius = width / 2;
      if (height < 2 * radius) radius = height / 2;
      this.beginPath();
      this.moveTo(x + radius, y);
      this.arcTo(x + width, y, x + width, y + height, radius);
      this.arcTo(x + width, y + height, x, y + height, radius);
      this.arcTo(x, y + height, x, y, radius);
      this.arcTo(x, y, x + width, y, radius);
      this.closePath();
      return this;
    }
    ctx.roundRect(10, 10, 310, 310, 10);
    ctx.lineWidth = 8;
    ctx.strokeStyle = colorPickerValue;
    ctx.stroke();

    //triangle fill
    let height = 40 * Math.cos(Math.PI / 6);
    ctx.beginPath();
    ctx.moveTo(100, 370);
    ctx.lineTo(150, 370);
    ctx.lineTo(125, 370 - height);
    ctx.fillStyle = colorPickerValue;
    ctx.fill();

      //bottomScanMe
    //ctx.fillRect(6, 315, 318, 80);
    ctx.roundRect(6, 360, 318, 84, 10);
    ctx.fillStyle = colorPickerValue;
    ctx.fill();

    //text
    ctx.font = "bold 50px arial";
    ctx.fillStyle = "white";
    ctx.fillText("SCAN ME",50, 418);

    //downloadLink
    var link = document.createElement('a');
    link.download = fileName+"."+fileFormat;
    link.href = c.toDataURL(fileFormatType)
    link.click();
    }



    

    const getFrame7 = () => {
        //Outer Curve Edge Border with attached SCAN ME
    var c = document.getElementById("myCanvas3");
    var ctx = c.getContext("2d");
    
    //background
    if (fileFormat !== "png") {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, 330, 400);
    }
    //image
    var img = document.getElementById("qrcode");
    ctx.drawImage(img,40,40);

    //Curve Edge border
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
        if (width < 2 * radius) radius = width / 2;
        if (height < 2 * radius) radius = height / 2;
        this.beginPath();
        this.moveTo(x + radius, y);
        this.arcTo(x + width, y, x + width, y + height, radius);
        this.arcTo(x + width, y + height, x, y + height, radius);
        this.arcTo(x, y + height, x, y, radius);
        this.arcTo(x, y, x + width, y, radius);
        this.closePath();
        return this;
      }
      ctx.roundRect(10, 10, 310, 310, 10);
      ctx.lineWidth = 8;
      ctx.strokeStyle = colorPickerValue;
      ctx.stroke();

    //bottomScanMe
    //ctx.fillRect(6, 315, 318, 80);


    //text
    ctx.font = "bold 50px arial";
    ctx.fillStyle = colorPickerValue;
    ctx.fillText("SCAN ME",50, 385 );



    //downloadLink
    var link = document.createElement('a');
    link.download = fileName+"."+fileFormat;
    link.href = c.toDataURL(fileFormatType)
    link.click();
  }


  const getDefaultFrame= () => {
    var c = document.getElementById("myCanvas2");
    var ctx = c.getContext("2d");

    // const pngUrl = document.getElementById("qrcode")
    // .toDataURL(fileFormatType)

    //background
    if (fileFormat !== "png") {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, 330, 330);
    }

        //image 
        var img = document.getElementById("qrcode");
        ctx.drawImage(img,40,40);
    
        // //reactangular Border
        // ctx.rect(10, 10, 310, 310);    
        // ctx.lineWidth = 5;
        // ctx.strokeStyle = colorPickerValue;
        // ctx.stroke();
    
        //downloadable link 
        var link = document.createElement('a');
        link.download = fileName+"."+fileFormat;
        link.href = c.toDataURL(fileFormatType)
        link.click();
        

    // let downloadLink = document.createElement("a");
    // downloadLink.href = pngUrl;
    // downloadLink.download = fileName+"."+fileFormat;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);

}


    const downloadFinalQRCode = () => {
        setCheckPopup(true);
        if (frame === 1){
            getFrame1();
        } else if (frame === 2){
            getFrame2();
        } else if (frame === 3){
            getFrame3();
        } else if (frame === 4){
            getFrame4();
        } else if (frame === 5){
            getFrame5();
        } else if (frame === 6){
            getFrame6();
        } else if (frame === 7){
            getFrame7();
        } else {
            getDefaultFrame();
        }

        
    }



  const selectFrame = (frameId) => {
    setFrame(frameId);
    if (frameId === 1){
        setQRBorder(5);
        setQRBorderRadius(0)
        setAttachScanMeTag("none");
        setAttachScanMeTagBorder(0)
        setBottomFloatingScanMeTag("none")
        setTopFloatingScanMeTag("none")
    } else if (frameId === 2){
        setQRBorder(5);
        setQRBorderRadius(10)
        setAttachScanMeTag("none");
        setAttachScanMeTagBorder(10)
        setBottomFloatingScanMeTag("none")
        setTopFloatingScanMeTag("none")
    } else if (frameId === 3){
        setQRBorder(5);
        setQRBorderRadius(0)
        setAttachScanMeTag("inline-block");
        setAttachScanMeTagBorder(0)
        setBottomFloatingScanMeTag("none")
        setTopFloatingScanMeTag("none")
    } else if (frameId === 4){
        setQRBorder(5);
        setQRBorderRadius(0)
        setAttachScanMeTag("inline-block");
        setAttachScanMeTagBorder(10)
        setBottomFloatingScanMeTag("none")
        setTopFloatingScanMeTag("none")
    } else if (frameId === 5){
        setQRBorder(5);
        setQRBorderRadius(10)
        setAttachScanMeTag("none");
        setAttachScanMeTagBorder(10)
        setBottomFloatingScanMeTag("none")
        setTopFloatingScanMeTag("inline-block")
    } else if (frameId === 6){
        setQRBorder(5);
        setQRBorderRadius(10)
        setAttachScanMeTag("none");
        setAttachScanMeTagBorder(10)
        setBottomFloatingScanMeTag("inline-block")
        setTopFloatingScanMeTag("none")
    } else if (frameId === 7){
        setQRBorder(5);
        setQRBorderRadius(10)
        setAttachScanMeTag("inline-block");
        setAttachScanMeTagBorder(10)
        setBottomFloatingScanMeTag("none")
        setTopFloatingScanMeTag("none")

    } else if (frameId === 8){
        setQRBorder(5);
    } else if (frameId === 9){
        setQRBorder(5);
    } else {
        setQRBorder(0);
        setQRBorderRadius(0)
        setAttachScanMeTag("none");
        setAttachScanMeTagBorder(0)
        setBottomFloatingScanMeTag("none")
        setTopFloatingScanMeTag("none")
    } 

  }


  const selectColor = (color) => {
      setColorPickerValue(color)
      if (imageOption === 1){
            createImage(color)
      }
      if (imageOption === 2){
        createBorderImage(color)
      }

  }

  

  const onFileChange = event => {
    
    // Update the state
    //this.setState({ selectedFile: event.target.files[0] });
    setImageUrl(URL.createObjectURL(event.target.files[0]))
  
  };
//   const [interviewId, setInterviewId]= useState({});
//   const togglePop = (data) => {
//       setCheckPopup(!checkBox);
//       setInterviewId(data);
//   }

  const selectFileFormat = (format) => {
        setFileFormat(format)
        
        if(format === "png"){
            setFileFormatType("image/png")
            setBgdColorPicker("transparent")
        } else if (format === "jpg") {            
            setFileFormatType("image/jpeg")
            setBgdColorPicker("white")
        } else if (format === "tiff") {            
            setFileFormatType("image/tiff")
        } else if (format === "BMP") {            
            setFileFormatType("image/bmp")
        } else {
            setFileFormatType("image/jpeg")
            setBgdColorPicker("white")
        }
        //alert(fileFormatType)
  }
  const closeModal = () => setOpacityChng(1);

  const selectImagePosition = (pos) => {
        if (pos === "TLC"){
            setImgPositionX("0");
            setImgPositionY("0");
        } else if (pos === "TRC") {
            setImgPositionX("150");
            setImgPositionY("0");
        } else if (pos === "BLC") {
            setImgPositionX("0");
            setImgPositionY("150");
        } else if (pos === "BRC") {
            setImgPositionX("150");
            setImgPositionY("150");
        } else if (pos === "CL") {
            setImgPositionX("0");
            setImgPositionY("75");
        } else if (pos === "CR") {
            setImgPositionX("150");
            setImgPositionY("75");
        } else if (pos === "CT") {
            setImgPositionX("75");
            setImgPositionY("0");
        } else if (pos === "CB") {
            setImgPositionX("75");
            setImgPositionY("150");
        } else {
            setImgPositionX(null);
            setImgPositionY(null);
        }

  }


  const selectTopics = () =>  {

        if (topicCheck){
            settopic(Topics.slice(0,9))
            
        } else {
            settopic(Topics);
        }
        settopicCheck(!topicCheck);
  }

    return(
        
        <>
            <div >
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>QR Code Generator for Mail</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Create your QR code for free.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout" style={{opacity:opactiyChng}}>
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">
                            {/* <h1>
                                <div className="title">
                                URL Encoder/Decoder
                                </div>
                            </h1> */}
                            <ToastContainer/>
                            {/* <hr/> */}

                            <div className="blog-page-content">
                            <div>
                                {/* <div >Additional Tools</div> */}
                                <div>

                                    <div className="iq-content">
                                        {topic.map((items,index)=>{
                                            return(
                                                <>
                                                {items.select === -1?
                                                
                                                <div className="QR-content-valueMain" onClick={() => selectTopics()} style={{paddingTop:"6px", paddingLeft:"20px"}}>
                                                <i className={ topicCheck? "fa fa-chevron-up QR-content-value1": "fa fa-chevron-down QR-content-value1"}></i>
                                                <img src={items.icon_link} style={{maxHeight:"25px", maxWidth:"25px"}} alt="" />
                                                
                                                <div className={items.select===1 ? "QR-Content-title1" :"QR-Content-title"}>{items.title}</div>
                                                </div>
                                                
                                                : 
                                                <NavLink className="nav-link" to={items.link} exact>
                                                    <div className="QR-content-valueMain" onClick={items.select===-1?()=>selectTopics:""}>
                                                <i className={items.icon}></i>
                                                <img src={items.icon_link} style={{maxHeight:"25px", maxWidth:"25px"}} alt="" />
                                                
                                                <div className={items.select===1 ? "QR-Content-title1" :"QR-Content-title"}>{items.title}</div>
                                                </div>
                                                </NavLink>
                                                }
                                                </>
                                            )
                                        })}
                                    </div>
                                    
                                <br/>
                                <br/>
                                <br/>

                                </div>
                                <div>

                                <div class="triangle">
                                <div className="commonTitle"><strong><i class="fa fa-envelope "></i>Mail QR Code</strong></div>
                                <br/><br/>
                                    
                                <table className="wifi-inputs">
                                    <tr><td><strong>Email : </strong></td> <td><input type="text" className="wifiQR-inputText1" placeholder="Contact@intereviews.com" onChange={(event) => {handleEmail(event)}}/></td></tr><br/>
                                    <tr><td><strong>Subject : </strong></td> <td><input type="text" className="wifiQR-inputText1" placeholder="About Intereviews" onChange={(event) => {handleSubject(event)}}/></td></tr><br/>
                                    <tr><td><strong>Message : </strong></td>
                                    <div className="tweet-textarea">
                                        <textarea className="QR-inputText1" placeholder="Enter your Message ..." onChange={(event) => {handleMessage(event)}}/>
                                    </div>


                                     <td></td></tr>
                                </table>
                                <br/><br/>
                                <div className="commonTitle"><input className="wifi-generate-bt" type="button" value="Generate QR Code" onClick={() => handleInputString()}/></div>

                                
                                <div className="jsonFormat-textarea" >
                                {/* <input type="text" className="QR-inputText" placeholder="Enter your text ..." onChange={(event) => {handleInputString(event)}} /> */}
                                <br/>
                                <br/>
                        
                                </div>
                                    
                                </div>

                                </div>

                                
                                <br/>


                                <div class="triangle-rev">
                                <div className="QR-output-block">
                                <div className="qrcodeOutput-block">
                                <div className="" style={{background: colorPickerValue , color: "white", padding:"5px", fontSize:"40px", fontFamily:"Arial", fontWeight:"bold", borderRadius:"10px",display:topFloatingScanMeTag, paddingLeft:"40px", paddingRight:"40px", }}>SCAN ME</div>
                                <br style={{display:topFloatingScanMeTag}}/>
                                <div style={{background:colorPickerValue, clipPath:"polygon(50% 70%, 0 0, 100% 0)", width:"30px", height:"30px", left:"45%", position:"absolute", display:topFloatingScanMeTag}}></div> 
                                <br style={{display:topFloatingScanMeTag}}/>
                                <div style={{ border: qrBorder+'px solid '+colorPickerValue, width: 'fit-content',display: 'inline-block', borderRadius: attachScanMeTagBorder+"px "+attachScanMeTagBorder+"px "+qrBorderRadius+"px "+qrBorderRadius+"px", padding:"27.5px"}}>
                                   <QRCode 
                                        value={urlString} 
                                        size={200} 
                                        bgColor={bgdColorPicker}
                                        fgColor={colorPickerValue}
                                        level={QRViewLevel} 
                                        id="qrcode" 
                                        includeMargin={false} 
                                        imageSettings={{
                                            src: imageUrl,
                                            x: imgPositionX,
                                            y: imgPositionY,
                                            height: 50,
                                            width: 50,
                                            excavate: true,
                                          }}  
                                    />
                                </div>
                                <br style={{display:attachScanMeTag}}/>
                                <div style={{background: frame === 7 ? "white" : colorPickerValue, color: frame === 7 ? colorPickerValue : "white", padding:"5px", fontSize:"40px", fontFamily:"Arial", fontWeight:"bold", borderBottomLeftRadius:attachScanMeTagBorder+"px", borderBottomRightRadius:attachScanMeTagBorder+"px", display:attachScanMeTag, paddingLeft:"40px", paddingRight:"40px", }}>SCAN ME</div>
                                <br style={{display:bottomFloatingScanMeTag}}/>
                                <div style={{background:colorPickerValue, clipPath:"polygon(50% 30%, 0 100%, 100% 100%)", width:"30px", height:"30px", left:"45%", position:"absolute", display:bottomFloatingScanMeTag}}></div> 
                                <br style={{lineHeight:"20px", display:bottomFloatingScanMeTag}}/>
                                <div className="" style={{background: colorPickerValue, color:"white", padding:"5px", fontSize:"40px", fontFamily:"Arial", fontWeight:"bold", borderRadius:"10px",display:bottomFloatingScanMeTag, paddingLeft:"40px", paddingRight:"40px", }}>SCAN ME</div>

                                <br/>

                                </div>
                                <br/>
                                <div>

                                
                                <div className="colorpicker-block">
                                    <div className="QR-optionName" onClick={()=>{toogleQR_Options("QR-optionValue", 0)}}>Frames <span className={QROptionArrows[0].value+" floatRight"}></span> </div>
                                    <div className="QR-optionValue">
                                    <div className="QR-mainFrame">
                                        <div className="QR-outFrame"><div className="QR-frame4" onClick={() => selectFrame(0)}><div className="QR-frame-X">X</div></div></div>
                                        <div className="QR-outFrame"><div className="QR-frame4" onClick={() => selectFrame(1)}><div className="QR-frame-square"><img src="/qrFrame1.png" width="40px" height="40px"/></div></div></div>
                                        <div className="QR-outFrame"><div className="QR-frame4" onClick={() => selectFrame(2)}><div className="QR-frame-square"><img src="/qrFrame2.png" width="40px" height="40px"/></div> </div></div>
                                        <div className="QR-outFrame"><div className="QR-frame4" onClick={() => selectFrame(3)}><div className="QR-frame-data"><img src="/qrFrame3.png" width="40px" height="50px"/></div></div></div>
                                    </div>
                                        <div></div>
                                    <div className="QR-mainFrame">
                                        <div className="QR-outFrame"><div className="QR-frame4" onClick={() => selectFrame(4)}><div className="QR-frame-data"><img src="/qrFrame4.png" width="40px" height="50px"/></div></div></div>
                                        <div className="QR-outFrame"><div className="QR-frame4" onClick={() => selectFrame(5)}><div className="QR-frame-data"><img src="/qrFrame5.png" width="40px" height="50px"/></div></div></div>
                                        <div className="QR-outFrame"><div className="QR-frame4" onClick={() => selectFrame(6)}><div className="QR-frame-data"><img src="/qrFrame6.png" width="40px" height="50px"/></div></div></div>
                                        <div className="QR-outFrame"><div className="QR-frame4" onClick={() => selectFrame(7)}><div className="QR-frame-data"><img src="/qrFrame7.png" width="40px" height="50px"/></div></div></div>
                                    </div>
                                    </div>
                                </div>

                                <div className="colorpicker-block">
                                    <div className="QR-optionName" onClick={()=>{toogleQR_Options("QR-optionValue", 1)}}>Image <span className={QROptionArrows[1].value+" floatRight"}></span> </div>
                                    <div className="QR-optionValue">
                                    <div className="QR-mainImage">
                                        <div className="QR-outImage"><div className="QR-image1" onClick={() => setImageUrl("")}> <div className="QR-image-X">X</div></div></div>
                                        <div className="QR-outImage"><div className="QR-image5" onClick={() => setImageUrl(titleImage)}> <div className="QR-image-scan"><img src={titleImage} width="50px" height="60px"/></div></div></div>
                                        <div className="QR-outImage"><div className="QR-image2" onClick={() => createImage(colorPickerValue)}><div className="QR-image-scan">SCAN</div> <div className="QR-image-me">ME</div></div></div>
                                        <div className="QR-outImage"><div className="QR-image3" onClick={() => createBorderImage(colorPickerValue)}><div className="QR-image3-inner"><div className="QR-image3-scan">SCAN</div> <div className="QR-image3-me">ME</div></div> </div></div>
                                        <div className="QR-outImage"><div className="QR-image4" ><div className="QR-image-X"><label for="upload-photo">+</label><input type="file" id="upload-photo" onChange={onFileChange} /></div></div></div>

                                    </div>
                                    { imageUrl !== "" ? (
                                    <div>
                                        <br/>
                                        <div className="QR-optionName">Image Position : </div>
                                        <select id="imageFormat" name="format" className="imgPosSelect" onChange={event => {selectImagePosition(event.target.value)}}>
                                                    <option value="C" selected>Center</option>
                                                    <option value="TLC">Top Left Corner</option>
                                                    <option value="TRC">Top Right Corner</option>
                                                    <option value="BLC">Bottom Left Corner</option>
                                                    <option value="BRC">Bottom Right Corner</option>
                                                    <option value="CL">Center Left</option>
                                                    <option value="CR">Center Right</option>
                                                    <option value="CT">Center Top</option>
                                                    <option value="CB">Center Bottom</option>
 
                                        </select>
                                    </div>
                                    )
                                    : "" }
                                    <br/>
                                    </div>
                                    
                                </div>




                                <div className="colorpicker-block">
                                    <div className="QR-optionName" onClick={()=>{toogleQR_Options("QR-optionValue", 2)}}>Choose a color<span className={QROptionArrows[2].value+" floatRight"}></span></div>
                                    <div className="QR-optionValue">
                                        <div className="QR-mainColor">
                                            <div className="QR-outColor"><div className="QR-color1" onClick={() => selectColor("#4285F4")}></div></div>
                                            <div className="QR-outColor"><div className="QR-color2" onClick={() => selectColor("#DB4437")}></div></div>
                                            <div className="QR-outColor"><div className="QR-color3" onClick={() => selectColor("#F4B400")}></div></div>
                                            <div className="QR-outColor"><div className="QR-color4" onClick={() => selectColor("#0F9D58")}></div></div>
                                            <input id="color-picker" type="color" onChange={(event) => {selectColor(event.target.value)}} className="QR-colorChange" />
                                        </div>
                                    </div>
                                </div>

                                <div className="colorpicker-block">
                                    <div className="QR-optionName" onClick={()=>{toogleQR_Options("QR-optionValue", 3)}}>Shape <span className={QROptionArrows[3].value+" floatRight"}></span></div>
                                    <div className="QR-optionValue">
                                    <div className="QR-mainShape">
                                        <div className="QR-outShape"><div className="QR-shape1" onClick={() => selectShape("L")}><i class="fas fa-qrcode QRShapeIcon fa-2x"></i></div></div>
                                        <div className="QR-outShape"><div className="QR-shape2" onClick={() => selectShape("M")}><i class="fas fa-qrcode QRShapeIcon fa-2x"></i></div></div>
                                        <div className="QR-outShape"><div className="QR-shape3" onClick={() => selectShape("Q")}><i class="fas fa-calculator fa-rotate-180 QRShapeIcon fa-2x"></i></div></div>
                                        <div className="QR-outShape"><div className="QR-shape4" onClick={() => selectShape("H")}><i class="fas fa-building fa-rotate-180 QRShapeIcon fa-2x"></i></div></div>
                                    </div>
                                    </div>
                                </div>





                                </div>
                                </div>

                                </div>
                                <canvas id="myCanvas" hidden width="100" height="100" style={{border:'1px solid #d3d3d3'}}>Your browser does not support the HTML canvas tag.</canvas>
                                <canvas id="myCanvas1" hidden width="100" height="100" style={{border:'1px solid #d3d3d3'}}>Your browser does not support the HTML canvas tag.</canvas>
                                <canvas id="myCanvas2" hidden width="330" height="330" style={{border:'1px solid #d3d3d3'}}>Your browser does not support the HTML canvas tag.</canvas>
                                <canvas id="myCanvas3" hidden width="330" height="400" style={{border:'1px solid #d3d3d3'}}>Your browser does not support the HTML canvas tag.</canvas>
                                <canvas id="myCanvas4" hidden width="330" height="452" style={{border:'1px solid #d3d3d3'}}>Your browser does not support the HTML canvas tag.</canvas>
                                <br/>
                                {/* {checkPopup && 
                                <PopUpFileDownload
                                    content={interviewId}
                                    handleClose={togglePop}
                                /> 

                                } */}
                                <div className="text-center">
                                    {/* <span className="autoButton"><input type="checkbox" checked={checkBox} onChange={handleCheckBox}/><b> Auto</b></span> &nbsp;&nbsp;  */}
                                    {/* <i className="json-copyLink convert-button-size1"><button onClick={downloadFinalQRCode}><span className="fa fa-download" ></span><span>Download</span></button></i> */}
                                    {/* <i className="json-copyLink convert-button-size1"><button onClick={() => togglePop({"fileName": fileName, "fileFormat":fileFormat})}><span className="fa fa-download" ></span><span>Download</span></button></i> */}
                                    <Popup trigger={ <i className="json-copyLink convert-button-size1"><button onClick={() => setOpacityChng(0.3)}><span className="fa fa-download" ></span><span>Download</span></button></i>}
                                        modal
                                        nested
                                        position="center"
                                        onClose={closeModal}
                                        >
                                        {close => (
                                            
                                        <div className="popUpFilename">
                                            <button className="close" onClick={close}>
                                            &#215;
                                            </button>
                                          <div className="popUpFNTitle" >Edit Filename and Format</div>

                                          <div className="popUpFNData">
                                            <input type="text" placeholder="Enter Filename" className="popUpFNinputBox" onChange={event => setFileName(event.target.value)} ></input>

                                                <br/>
                                                <br/>
                                                <select id="imageFormat" name="format" className="popUpFNdropdown" onChange={event => {selectFileFormat(event.target.value)}}>
                                                    <option selected disabled> select Image Format</option>
                                                    <option value="jpg">JPEG</option>
                                                    <option value="png">PNG</option>
                                                </select>
                                                <br/>
                                                <br/>
                                          <i className="json-copyLink convert-button-size3"><button onClick={downloadFinalQRCode}><span className="fa fa-download" ></span><span>Download</span></button></i>
                                          </div>
                                          </div>
                                          )}
                                        </Popup>
                                </div>
                            </div>
                            <br/>
                           <div className="json-output-border">
                               <div>
                                    <br/>
                                    <div className="BugNote">
                                        <strong >Note :</strong>
                                        <i> Found a bug?</i>
                                        <a href="mailto:?subject=Bug Report on Base64 Encode  &amp;body= Bug Report :&amp;to=contact%40intereviews.com" title="Share by Email" className="reportBug">
                                           report!
                                        </a>
                                    </div>


                                    {/* <div className="json-border">

                                    </div> */}
                                </div>
                            </div>

                        </div>
                        
                    </div>                    
                </div>

                <div className="blogLayout">

                    <div className="json-innerPage-layout">
                        <div className="blog-page-content">
                            <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
                                <strong> About</strong></h5></div>
                                <hr/>
                                <div><h5><strong>QR Code Generator Tool</strong></h5></div>
                            <div>Checkout this online Free QR Code Generator Tool, which will helps to Create and Download Your Personal QR Codes® for Free!.
                            </div>
                            <br/>
                            <div><h5><strong>What is QR Code ?</strong></h5></div>
                            
                            <div>A QR Code is a two-dimensional barcode that is readable by smartphones. It allows to encode over 4000 characters in a two dimensional barcode. QR Codes may be used to display text to the user, to open a URL, save a contact to the address book or to compose text messages. "QR Code" is a registered trademark of DENSO WAVE INCORPORATED.

                            </div>
                            <br/>
                            <div><h5><strong>Why is Mail QR Code Generator ?</strong></h5></div>                            
                            <div>
                                By using this Mail QR code generator, you can share the Email and basic details to the users easily. 
                            </div>
                            <br/>


                            <div><h5><strong>Example : </strong></h5></div>
                            <div className="tools-in-example"> 
                                <table className="wifi-example-table"> 
                                    <tr><td>Email </td><td> : </td><td> contact@intereviews.com</td></tr> 
                                    <tr><td>Subject </td><td> : </td><td> About Mail QR Code Generator in Intereviews</td></tr>
                                    <tr><td>Message </td><td> : </td><td> Mail QR code generator is helpful for sending mails via scanning QR code. </td></tr>
                                </table>        
                            </div>

                            <br/>
                            <div> <strong>Output:</strong> </div>
                            <div className="tools-out-example"> 
                                                        <QRCode 
                                        value={"mailto:contact@intereviews.com?subject=About Mail QR Code Generator in Intereviews&body=Mail QR code generator is helpful for sending mails via scanning QR code."} 
                                        size={200} 
                                        bgColor={"#E8E8E8"}
                                        fgColor={"black"}
                                        level='H'
                                        id="qrcode" 
                                        includeMargin={false} 
                                        imageSettings={{
                                            src: titleImage,
                                            x: null,
                                            y: null,
                                            height: 50,
                                            width: 50,
                                            excavate: true,
                                          }}  
                                    />
                            
                            </div>

                            <div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        <div>                

        <div className="tools-topics-div">
            <div className="tools-topics">
                <div className="title">
                <b className="fas fa-cog" />
                     <c> Other Tools</c>
                </div>
                {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

                return(
                    <div className="tools-topic-value">   
                    <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                        <div className="blog-all-topics-name" >
                            {items.name}
                        </div>       
                    </NavLink>
                   </div>
                   )
                })}


            </div>
        </div>
        <div className="tools-topics-div">
            <div>
                {/* <!-- side-vertical --> */}
                <ins class="adsbygoogle"
                     style={{display:'block'}}
                    data-ad-client="ca-pub-8123049304648947"
                    data-ad-slot="5905496439"
                    data-ad-format="auto"
                    data-full-width-responsive="true">     
                </ins>
            </div>
        </div>
        </div>
    </div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default MailQRCodeGenerator;



// referencePage : 
// [
//     //https://codesandbox.io/s/3zcbs?file=/src/index.js:312-326
//     //https://stackoverflow.com/questions/31570798/how-to-make-html-div-with-text-over-image-downloadable-savable-for-users
    
//     //https://www.npmjs.com/package/react-qr-code
//     //output testing - //https://zpao.github.io/qrcode.react/ 
//     //https://www.npmjs.com/package/react-qrcode-logo
//     //https://www.npmjs.com/package/qrcode.react
//      https://react-popup.elazizi.com/react-modal -> popup code changes
// ]


//A QR Code is a two-dimensional barcode that is readable by smartphones. It allows to encode over 4000 characters in a two dimensional barcode. QR Codes may be used to display text to the user, to open a URL, save a contact to the address book or to compose text messages. "QR Code" is a registered trademark of DENSO WAVE INCORPORATED.

//To read QR Codes with a smartphone, you can just use the default camera app.

//On desktop devices, you can just bookmark the Scan section of this web app. Than can be useful to send a link from a phone to your desktop browser for example.

//Are the QR Codes generated on this site restricted to personal use?
//No! There is no restriction of any kind. You can use these QR Code images for whatever you like, also commercially. Please note that the word "QR Code" is a registered trademark of DENSO WAVE INCORPORATED. If you want to use it, you have to add a trademark comment. Please see here for details.

//What are Static QR Codes?
//Static QR Codes encode the content directly in the image itself. You can think of them like regular printed text, just represented in different way. That means you cannot update the content once generated. Because the content is directly encoded in the image, they also grow in size with the amount of content they store.

//What are Dynamic QR Codes?
//The content of a Static QR Code cannot be changed once generated. What is referred to as a Dynamic QR Code, is a QR Code pointing to a static URL that hosts the actual content (e.g. the real URL). The hosted content can be changed after the QR Code has been printed.
//After signing in, this service also allows you to generate Dynamic QR Codes (available under My QR Codes). We automatically create a URL that points to your actual content. We provide simple view tracking statistics for these Codes.


// telphone -> "tel:+12345678"

// sms -> "sms:+12345678"

// Vcard ->  let card = "BEGIN:VCARD\r\n";
//  card += "VERSION:3.0\r\n";
//  card += "FN:John Doe\r\n";
//  card += "TEL;TYPE=home:tel:+1-123-456-789";
//  card += "EMAIL;TYPE=internet:john@doe.com";
//  new QRCode(document.getElementById("qrD"), card);





