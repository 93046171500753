import React from 'react'
import './Sidebar'
import "./Pages.css"
import { NavLink } from 'react-router-dom'

import { BlogPages } from './InterviewQuestionPages/Blog'
import Navbar from './Navbar'
import MiniFooter from './MiniFooter';

import { Helmet } from 'react-helmet'
import { Tools } from './Tools/ToolsPage'

export const NewTopics = [
    {
        title:"C Programing",
        icon:"fab fa-cuttlefish iq-content-value",
        link : "/interviewQuestions/c"
    },
    {
        title:"AWS",
        icon:"fab fa-aws iq-content-value",
        link : "/interviewQuestions/aws"
    },
    {
        title:"Flutter",
        icon:"fab iq-content-value",
        link : "/interviewQuestions/flutter",
        icon_link : "https://img.icons8.com/ios-filled/50/000000/flutter.png"
    },
]

export const Topics = [
    {
        title:"Java",
        icon:"fab fa-java iq-content-value",
        link : "/interviewQuestions/java"
    },

    {
        title:"Python",
        icon:"fab fa-python iq-content-value",
        link : "/interviewQuestions/python"
    },
    {
        title:"JavaScript",
        icon:"fab fa-js-square iq-content-value",
        link : "/interviewQuestions/javascript"
    },
    {
        title:"ReactJs",
        icon:"fab fa-react iq-content-value",
        link : "/interviewQuestions/react"
    },
    {
        title:"NodeJs",
        icon:"fab fa-node iq-content-value",
        link : "/interviewQuestions/nodejs"
    },
    {
        title:"JQuery",
        icon:"fab iq-content-value",
        link : "/interviewQuestions/jquery",
        icon_link:"https://img.icons8.com/ios-filled/25/000000/jquery.png"
    },
    {
        title:"Angular",
        icon:"fab fa-angular iq-content-value",
        link : "/interviewQuestions/angular"
    },

    {
        title:"Spring Boot",
        icon:"fab iq-content-value",
        link : "/interviewQuestions/springboot",
        icon_link:"/spring-boot-logo.svg"
    },
    {
        title:"MySQL",
        icon:"fab iq-content-value",
        link : "/interviewQuestions/mysql",
        icon_link : "https://img.icons8.com/ios-filled/50/000000/mysql-logo.png"
    },
    
]




function InterviewQuestions (){
    


    Navbar("interviewQuestions");

        return(
            <div>
                <Helmet>
                    <title>Interview Questions</title>
                    <meta name="description" content="In this page, we provided topic wise important Interview Questions. " />
                </Helmet>
            <div className="parent">
            {/* <div className="iq-sidebar"> <Sidebar/></div> */}
            <div className=" iq-content-tags" >
                <h1><div className="title">Interview Questions </div></h1>
                <hr/>
                <br/>
                <div className="iq-content">
                {Topics.map((items,index)=>{
                    return(
                    <NavLink className="nav-link" to={items.link} exact>
                    <i className={items.icon}>
                    <img src= {items.icon_link} style={{maxHeight:"25px", maxWidth:"25px"}} alt="" />
                    <span>{items.title}</span>
                    </i>
                    </NavLink>
                    )
                    

                })}
                </div>
                
            </div>

            <div className="iq-whatsNew" >
                <div className="title">What's New</div>
                <hr/>
                <NavLink className="iq-whatsNew-blog" to={"/tools/"} exact>
                <div>&gt; /tools  </div>
                </NavLink>
                <ul>
                {Tools.map((items)=>{
                    return(
                    <li>
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="iq-whatsNew-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </li>
                    )

                })}
                </ul>

                <br/>

                <NavLink className="iq-whatsNew-blog" to={"/blog/"} exact>
                <div>&gt; /blog  </div>
                </NavLink>
                <ul>
                {BlogPages.map((items)=>{
                    return(
                    <li>
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="iq-whatsNew-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </li>
                    )

                })}
                </ul>

            </div>


            </div>

            <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <MiniFooter/>
            </div>
        
        )
}


export default InterviewQuestions;