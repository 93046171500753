
import React, {useState} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom'
import { Tools } from '../Tools/ToolsPage'

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../Footerpage";
import { urls } from "curlrequest";




function HtmlDecode(){

    const relatedTopicsOrder = [];


    const [urlString, setUrlString] = useState("");
    const [outputString, setOutputString] = useState("");
    const [encodeString, setEncodeString] = useState("");
    const [checkBox, setCheckBox] = useState(false);

    const clipboard = "";
    const currentTool = "HTML Decode"

    const a = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
    const pad = "=";

    const base32Encode = (s) => {
        var a = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
        var pad = "=";
        var len = s.length;
        var o = "";
        var w, c, r=0, sh=0; // word, character, remainder, shift
        for(var i=0; i<len; i+=5) {
            // mask top 5 bits
            c = s.charCodeAt(i);
            w = 0xf8 & c;
            o += a.charAt(w>>3);
            r = 0x07 & c;
            sh = 2;

            if ((i+1)<len) {
                c = s.charCodeAt(i+1);
                // mask top 2 bits
                w = 0xc0 & c;
                o += a.charAt((r<<2) + (w>>6));
                o += a.charAt( (0x3e & c) >> 1 );
                r = c & 0x01;
                sh = 4;
            }
            
            if ((i+2)<len) {
                c = s.charCodeAt(i+2);
                // mask top 4 bits
                w = 0xf0 & c;
                o += a.charAt((r<<4) + (w>>4));
                r = 0x0f & c;
                sh = 1;
            }

            if ((i+3)<len) {
                c = s.charCodeAt(i+3);
                // mask top 1 bit
                w = 0x80 & c;
                o += a.charAt((r<<1) + (w>>7));
                o += a.charAt((0x7c & c) >> 2);
                r = 0x03 & c;
                sh = 3;
            }

            if ((i+4)<len) {
                c = s.charCodeAt(i+4);
                // mask top 3 bits
                w = 0xe0 & c;
                o += a.charAt((r<<3) + (w>>5));
                o += a.charAt(0x1f & c);
                r = 0;
                sh = 0;
            } 
        }
        // Encode the final character.
        if (sh != 0) { o += a.charAt(r<<sh); }
        // Calculate length of pad by getting the 
        // number of words to reach an 8th octet.
        var padlen = 8 - (o.length % 8);
        // modulus 
        if (padlen==8) { return o; }
        if (padlen==1) { return o + pad; }
        if (padlen==3) { return o + pad + pad + pad; }
        if (padlen==4) { return o + pad + pad + pad + pad; }
        if (padlen==6) { return o + pad + pad + pad + pad + pad + pad; }
        console.log('there was some kind of error');
        console.log('padlen:'+padlen+' ,r:'+r+' ,sh:'+sh+', w:'+w);

    }


    //const HtmlEncoder = () => {
        var escapeChars = {
            '¢' : 'cent',
            '£' : 'pound',
            '¥' : 'yen',
            '€': 'euro',
            '©' :'copy',
            '®' : 'reg',
            '<' : 'lt',
            '>' : 'gt',
            '"' : 'quot',
            '&' : 'amp',
            '\'' : '#39'
          };
          
          var regexString = '[';
          for(var key in escapeChars) {
            regexString += key;
          }
          regexString += ']';
          
          var regex = new RegExp( regexString, 'g');
          
          function escapeHTML(str) {
            return str.replace(regex, function(m) {
              return '&' + escapeChars[m] + ';';
            });
          };
    //}

    var htmlEntities = {
        nbsp: ' ',
        cent: '¢',
        pound: '£',
        yen: '¥',
        euro: '€',
        copy: '©',
        reg: '®',
        lt: '<',
        gt: '>',
        quot: '"',
        amp: '&',
        apos: '\''
    };
    
    function unescapeHTML(str) {
        return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
            var match;
    
            if (entityCode in htmlEntities) {
                return htmlEntities[entityCode];
                /*eslint no-cond-assign: 0*/
            } else if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
                return String.fromCharCode(parseInt(match[1], 16));
                /*eslint no-cond-assign: 0*/
            } else if (match = entityCode.match(/^#(\d+)$/)) {
                return String.fromCharCode(~~match[1]);
            } else {
                return entity;
            }
        });
    };

    const EncodeUrl = () => {
        //alert(encodeURIComponent(urlString));

        //setUrlString(btoa(urlString));    
        //alert(base32Encode(urlString))
        //setOutputString(unescapeHTML(urlString));
            
        setOutputString(unescapeHTML(urlString));
    }


    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    const handleCheckBox = () => {
        setCheckBox(!checkBox);
    }
    const handleInputString = (event) => {

        setUrlString(event.target.value)
        setEncodeString(unescapeHTML(event.target.value));
    }


    //ytes, and force a conversion before executing,
// and convert outputs back into strings.

function refreshPage() {
    setTimeout(()=>{
        window.location.reload(false);
    }, 0);
    console.log('page to reload')
}


    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>Base64 Encode</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Free Online Base64 Encode.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">
                            {/* <h1>
                                <div className="title">
                                URL Encoder/Decoder
                                </div>
                            </h1> */}
                            <ToastContainer/>
                            {/* <hr/> */}

                            <div className="blog-page-content">
                            <div>
                                <div><h5><strong>Paste or Enter you plain text below : </strong></h5></div>
                                <div className="jsonFormat-textarea" >
                                    <textarea type="text" placeholder="Enter your PLAIN text here ..." onChange={(event) => {handleInputString(event)}} />
                        
                                </div>
                                <br/>
                                <div className="text-center">
                                    <span className="autoButton"><input type="checkbox" checked={checkBox} onChange={handleCheckBox}/><b> Auto</b></span> &nbsp;&nbsp; 
                                    <i className="json-copyLink convert-button-size1"><button onClick={EncodeUrl}>Base64 Encode</button></i>
                                    {/* <i className="json-copyLink convert-button-size2"><button onClick={DecodeUrl}>Decode</button></i>
                                    <CopyToClipboard text={urlString}>
                                        <i className="json-copyLink convert-button-size2" title="copy" ><button onClick={diffToast}>Copy</button></i>
                                    </CopyToClipboard> */}

                                </div>
                            </div>
                           <br/>
                            <br/>
                           <div className="json-output-border">
                               <div>
                                    {/* <div><h5><strong>Formatted View : </strong></h5></div> */}
                                    <div className="encoder-output-top">
                                        <div >
                                            <div>
                                                <strong className="jsonblock-title">Output : </strong>

                                                <CopyToClipboard text={encodeString}>
                                                   <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="encoder-textarea" >
                                        <textarea type="text" placeholder="Base64 Encoded output goes here..." value={checkBox ? encodeString:outputString}/>                        
                                    </div>
                                    <br/>
                                    <div className="BugNote">
                                        <strong >Note :</strong>
                                        <i> The tool uses UTF-8 charset. Found a bug?</i>
                                        <a href="mailto:?subject=Bug Report on Base64 Encode  &amp;body= Bug Report :&amp;to=contact%40intereviews.com" title="Share by Email" className="reportBug">
                                           report!
                                        </a>
                                    </div>


                                    {/* <div className="json-border">

                                    </div> */}
                                </div>
                            </div>

                        </div>
                        
                    </div>                    
                </div>

                <div className="blogLayout">

                    <div className="json-innerPage-layout">
                        <div className="blog-page-content">
                            <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
                                <strong> About</strong></h5></div>
                                <hr/>
                                <div><h5><strong>HTML Decode Tool</strong></h5></div>
                            <div>Check this online Base64 Encode Tool, which will helps Decode, the Encoded Html to HTML format.
                            </div>
                            <div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        <div>                

        <div className="tools-topics-div">
            <div className="tools-topics">
                <div className="title">
                <b className="fas fa-cog" />
                     <c> Other Tools</c>
                </div>
                {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

                return(
                    <div className="tools-topic-value">   
                    <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                        <div className="blog-all-topics-name" >
                            {items.name}
                        </div>       
                    </NavLink>
                   </div>
                   )
                })}


            </div>
        </div>
        <div className="tools-topics-div">
            <div>
                {/* <!-- side-vertical --> */}
                <ins class="adsbygoogle"
                     style={{display:'block'}}
                    data-ad-client="ca-pub-8123049304648947"
                    data-ad-slot="5905496439"
                    data-ad-format="auto"
                    data-full-width-responsive="true">     
                </ins>
            </div>
        </div>
        </div>
    </div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default HtmlDecode;