import React, { useEffect, useState } from 'react'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import Footerpage from '../Footerpage'
import "../Pages.css"
//import {interviewExperienceData} from './database/FirebaseCURDOperations'
import { collection, getDocs} from 'firebase/firestore';
//import { collection, getDocs, addDoc } from 'firebase/firestore';
import {db} from '../database/firebase-config'
//import $ from 'jquery';
import Navbar from '../Navbar'
import PopUp from '../PopUp';
import {EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton} from "react-share"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom'

import { Helmet } from 'react-helmet'

export const companyDetails = {
    "oracle" : {
        "img" : "oracle.jpeg",
        "name" : "Oracle",
        "site" : "www.oracle.com",
        "founded":"16 June 1977",
        "emp":"1,43,000 (2022)",
        "type":"public",
        "revenue":"₹3,49,960 crore (US$42.44 billion) (2022)",
        "jobPortal" : "https://www.oracle.com/in/corporate/careers/",
        "about":"Oracle Corporation is an American multinational computer technology corporation headquartered in Austin, Texas. In 2020, Oracle was the third-largest software company in the world by revenue and market capitalization. The company sells database software and technology (particularly its own brands), cloud engineered systems, and enterprise software products, such as enterprise resource planning (ERP) software, human capital management (HCM) software, customer relationship management (CRM) software (also known as customer experience), enterprise performance management (EPM) software, and supply chain management (SCM) software.",
        "Subsidiaries":"Manage Engine Qntrl Trainer Central",
        "CEO" : "Safra Catz",
        "Headquarters":"Austin, Texas, U.S",
        "Industry":"Enterprise software, Business software, Cloud computing, Computer hardware, Consulting",
        "email":"careers@ovaledge.com, hr@ovaledge.com",
        "twitter":"https://twitter.com/Oracle",
        "facebook" : "https://www.facebook.com/Oracle/",
        "linkedin":"https://www.linkedin.com/company/oracle/",
        "Key people":"Larry Ellison (Executive Chairman & CTO), Jeff Henley (Vice Chairman), Safra Catz (CEO)",
    },
    "infosys" : {
        "img" : "infosys.jpg",
        "name" : "Infosys",
        "site" : "www.infosys.com",
        "CEO" :"Salil Parekh",
        "Headquarters" : "Bangalore, India",
        "founded" : "2 July 1981",
        "emp":"345K+ (2021)",
        "Industry":"Information Technology, Consulting, ",
        "revenue" : "₹123,936 crore (US$17.53 billon) (2022)",
        "type" : "Public",
        "about":"Infosys is a global leader in next-generation digital services and consulting. We enable clients in more than 50 countries to navigate their digital transformation.",
        "jobPortal":"https://career.infosys.com/joblist",
        "email":"careers@ovaledge.com, hr@ovaledge.com",
        "twitter":"https://twitter.com/Infosys",
        "facebook" : "https://www.facebook.com/Infosys/",
        "linkedin":"https://www.linkedin.com/company/infosys/"

    },
    "zoho" : {
        "img" : "zoho.jpeg",
        "name" : "ZOHO",
        "site":"www.zoho.com",
        "founded":"17 March 1996",
        "emp":"13000+ (2022)",
        "type":"private",
        "revenue":"₹5,230 crore (US$650 million) (2021)",
        "jobPortal" : "https://careers.zohocorp.com/jobs/Careers",
        "about":"Zoho Corporation is an Indian multinational technology company that makes web-based business tools. It is best known for the online office suite offering Zoho Office Suite. The company was founded in 1996 by Sridhar Vembu and Tony Thomas and has a presence in seven locations with global headquarters in Chennai, Tamil Nadu, India, and corporate headquarters outside of Austin in Del Valle, Texas. Radha Vembu, Sridhar Vembu's sister, owns a majority stake in the company.",
        "Subsidiaries":"Manage Engine Qntrl Trainer Central",
        "CEO" : "Sridhar Vembu",
        "Headquarters":"Chennai, TamilNadu, India",
        "Industry":"Technology, Information technology, Software development, Cloud computing",
        "email":"careers@ovaledge.com, hr@ovaledge.com",
        "twitter":"https://twitter.com/Zoho",
        "facebook" : "https://www.facebook.com/zoho",
        "linkedin":"https://www.linkedin.com/company/zoho"
    },
    "accenture" : {
        "img" : "accenture.png",
        "name" :"Accenture",
        "site" : "www.accenture.com",
        "founded":"1989",
        "emp":"721,000 (2022)",
        "type":"public",
        "revenue":"₹4,22,960 crore (US$50.53 billion) (2021)",
        "jobPortal" : "https://www.accenture.com/in-en/careers",
        "about":"Accenture plc is an Irish-American professional services company based in Dublin, specializing in information technology (IT) services and consulting. A Fortune Global 500 company, it reported revenues of $50.53 billion in 2021.[2] Accenture's current clients include 91 of the Fortune Global 100 and more than three-quarters of the Fortune Global 500",
        "Subsidiaries":"",
        "CEO" : "Julie Sweet",
        "Headquarters":"Dublin, Ireland ",
        "Industry":"Professional services, Information technology, Consulting",
        "email":"",
        "twitter":"https://twitter.com/Accenture",
        "facebook" : "https://www.facebook.com/accenture",
        "linkedin":"https://www.linkedin.com/company/accenture/"
    },
    "cognizant" : {
        "img" : "cognizant.png",
        "name" :"Cognizant",
        "site" : "www.cognizant.com",
        "founded":"26 January 1994",
        "emp":"341,000 (2022)",
        "type":"public",
        "revenue":"₹31,196 crore (US$18.65 million) (2022)",
        "jobPortal" : "https://careers.cognizant.com/global/en",
        "about":"Cognizant is an American multinational information technology services and consulting company. It is headquartered in Teaneck, New Jersey, United States. Cognizant is part of the NASDAQ-100 and trades under CTSH. It was founded as an in-house technology unit of Dun & Bradstreet in 1994, and started serving external clients in 1996. \nAfter a series of corporate re-organizations there was an initial public offering in 1998. \nCognizant had a period of fast growth during the 2000s and became a Fortune 500 company in 2011; as of 2021, it is ranked 185.",
        "Subsidiaries":"Manage Engine Qntrl Trainer Central",
        "CEO" : "Brian Humphries",
        "Headquarters":"Teaneck, New Jersey, U.S",
        "Industry":"Information technology, Consulting",
        "email":"careers@ovaledge.com, hr@ovaledge.com",
        "twitter":"https://twitter.com/Cognizant",
        "facebook" : "https://www.facebook.com/Cognizant/",
        "linkedin":"https://www.linkedin.com/company/cognizant/"
    },
    "atmecs" : {
        "img" : "atmecs.jpeg",
        "name" :"Atmecs",
        "site" : "www.atmecs.com",
        "founded":"2013",
        "emp":"500 to 1000",
        "type":"private",
        "revenue":"Unknown",
        "jobPortal" : "https://www.atmecs.com/careers/",
        "about":"ATMECS GLOBAL is a result oriented full service engineering and R&D organization. We are Technology Accelerators bringing in visible transformation for our clients through automation, adoption of leading edge integrated development platforms, CI/CD, Dev Ops, Cloud, and Big Data . Several Fortune 500 customers and exciting next gen start-up companies engage us to partner with them to solve critical business challenges. As Innovation Catalysts we help clients lead change through AI/ML, AR/VR, IOT, Conversational BOTs & Blockchain.",
        "Subsidiaries":"",
        "CEO" : "Ravi Velagapudi",
        "Headquarters":"Hyderabad, India",
        "Industry":"Information technology, Support",
        "email":"",
        "twitter":"https://twitter.com/ATMECS",
        "facebook" : "https://www.facebook.com/ATMECS/",
        "linkedin":"https://www.linkedin.com/company/atmecs-inc/"
    },
    "ovaledge":{
        "img" : "ovaledge.jpg",
        "name" :"OvalEdge",
        "site" : "www.ovaledge.com",
        "founded":"2013",
        "emp":"100+",
        "type":"private",
        "revenue":"₹150 crore (US$19 million) (2022)",
        "jobPortal" : "https://www.ovaledge.com/careers",
        "about":"\"Connect more than data. Connect ideas…\" At OvalEdge we believe that when you connect right people to the right data, they can do amazing things with it. To that end, we bring to you a versatile data catalog which is easy to use, quick to build and yet affordable.",
        "Subsidiaries":"",
        "CEO" : "Sharad Varshney",
        "Headquarters":"Alpharetta, Georgia, U.S",
        "Industry":"Software Development",
        "email":"careers@ovaledge.com, hr@ovaledge.com",
        "twitter":"https://twitter.com/ovaledgedata",
        "facebook" : "https://www.facebook.com/ovaledge",
        "linkedin":"https://www.linkedin.com/company/ovaledge/"
    },
    "phenom people":{
        "img" : "phenompeople.png",
        "name" :"Phenom People",
        "site":"www.phenom.com",
        "founded":"2011",
        "emp":"1500+",
        "customers":"500+",
        "type":"private",
        "revenue":"Unknown",
        "jobPortal" : "https://careers.phenompeople.com/us/en/search-results",
        "about":"\"Helping a billion people find the right job\" We're a purpose-driven HR tech company that's helping global enterprises hire faster, develop better, and retain their people longer.",
        "Subsidiaries":"Manage Engine Qntrl Trainer Central",
        "CEO" : "Mahe Bayireddi",
        "Headquarters":"300 Brookside Avenue Building 18, Suite 200 Ambler, PA 19002, U.S",
        "Industry":"Information technology, HR tech company",
        "email":"careers@ovaledge.com, hr@ovaledge.com",
        "twitter":"https://twitter.com/phenom_people",
        "facebook" : "https://www.facebook.com/phenompeople",
        "linkedin":"https://www.linkedin.com/company/phenomtxm/",
        
    },
    "revolution entertainment services":{
        "img" : "revolutionentertainmentservices.jpeg",
        "name" :"Revolution Entertainment Services",
        "site":"www.revolutiones.com",
        "founded":"2017",
        "emp":"50 to 200",
        "customers":"500+",
        "type":"private",
        "revenue":"Unknown",
        "jobPortal" : "http://www.revolutiones.com/#/careers",
        "about":"At Revolution, we are committed to solving the most consistent problems in the entertainment payroll industry.",
        "Subsidiaries":"Manage Engine Qntrl Trainer Central",
        "CEO" : "Charlie Steiner",
        "Headquarters":"Burbank, CA",
        "Industry":"Accounting & Tax",
        "email":"careers@ovaledge.com, hr@ovaledge.com",
        "twitter":"https://twitter.com/askrevolution",
        "facebook" : "https://www.facebook.com/askrevolutiones/",
        "linkedin":"https://www.linkedin.com/company/revolutiones/",
        
    }
}



function CompanyReview(props){

    console.log("CompanyReview ");
    Navbar("home");

    const {companySearchName} = useParams();
    //alert(companySearchName + " "+ props);
    //var companySearchName = compSearchName;
    const history = useHistory();

    const [contributionList, setContributionList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const searchValue = new Set();

    //pagination code
    const contributionCount = searchList.length
  
    const[ isActiveLeft, setActiveLeft] = useState(false);
    const[ isActiveRight, setActiveRight] = useState(true);

    const [pageNumber, setPageNumber] = useState(0);
    const perPage = 5;
    const pageVisited = pageNumber * perPage;
    
    const interviewExperienceCollectionRef = collection(db, "Interview-experience");

    const [sidebar, setSidebar] = useState(false);

    const [searchEventText, setSearchEventText] = useState("");
    const [searchText, setSearchText] = useState(companySearchName.toLowerCase().replaceAll("_"," "));
    const [viewPart, setViewPart] = useState(1);


    useEffect(() => {

        const getInterviewExperience = async () => {
            const data = await getDocs(interviewExperienceCollectionRef);
            console.log(data);    
            // setContributionList(data.docs.map((doc) => ({ ...doc.data(), id : doc.id})));
            // setSearchList(data.docs.map((doc) => ({ ...doc.data(), id : doc.id})));
            const approveListTemp = []
            data.docs.filter(doc => doc.data().approval === 1).map((doc) => {
                //alert("checking 11" + doc.data().approval)
                //if (doc.data().approval === 1) {
                   approveListTemp.push( { ...doc.data(), id : doc.id})                   
                //} 
                return null;
            });

            setContributionList(approveListTemp);

            const newSearchList = [];
            approveListTemp.forEach((interview) => {
                if(interview.companyName.toLowerCase() === searchText){
                    newSearchList.push(interview);
                }
            });
            setSearchList(newSearchList);

        };
    
        getInterviewExperience();

        return () => {
            // this now gets called when the component unmounts
          };

    }, [])


    
    useEffect(()=> {


            const handleWindowClick = () => {
                //alert("onWindow ");
                setcheckSidebar(prevState => prevState.map((item, idx) => false));                
                setSidebar(false)}

            if(sidebar) {
              window.addEventListener('click', handleWindowClick);
            } else {
             window.removeEventListener('click', handleWindowClick)
            }
            return () => window.removeEventListener('click', handleWindowClick);

            // if(sidebar) {
            //     window.addEventListener('click', handleWindowClick("add"));

            //   } else {
            //     window.removeEventListener('click', handleWindowClick("remove"))

            //   }

    
    }, [sidebar, setSidebar]);


    //share operations open
    const shareUrl = window.location.href+"home/";


    const [checkSidebar, setcheckSidebar] = useState([false,false,false,false,false]);

    // const onShareReview = (event) => {
    //     const selectTopic = event.currentTarget.dataset.div_name;
    //     alert(selectTopic);
    // }

    //RefreshPage
    function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
        console.log('page to reload')
    }
    

    const [state, setState] = useState(false);
    const [interviewId, setInterviewId]= useState("");

     const togglePop = (event) => {

        const selectTopic = event.currentTarget.dataset.div_name;
        setState(!state);
        setInterviewId(selectTopic);
      };


      const showSidebar = (index) => {
        //alert(" shareButton")
        //alert("before sidebar : "+sidebar)
        setSidebar(!sidebar);
        //alert("before : "+checkSidebar)

        //let checkvalue = false; 
        checkSidebar.forEach((item, idx) => {

            if (idx === index){
                //alert("items : "+item);
                setSidebar(!item);
                //checkvalue = !item;
            }
        })

        setcheckSidebar(prevState => prevState.map((item, idx) => idx === index ? !item : false))
        // alert("after : "+checkSidebar)
        // alert("after sidebar : "+sidebar)
        // alert("checkvalue : "+checkvalue)
        // if (checkvalue){
        //     window.addEventListener('click', handleWindowClick("add"));
        // } else {
        //     window.removeEventListener('click', handleWindowClick("remove"));
        // }


        //checkSidebar[index] = !checkSidebar[index];
    }


    //share close
    

    //pagination Variables

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };


    //const [pageValue, setPageValue] = useState(pageVisited+perPage);


    const onChangeRight = () => {
        //alert("right "+pageNumber+" "+contributionCount + " " +pageVisited +" "+perPage + " "+(pageNumber+1)*perPage)
        if (contributionCount > pageVisited+perPage){
            scrollToTop();
            setPageNumber(pageNumber+1);
            //setPageValue(pageVisited+perPage)
            setActiveLeft(true);
            if (contributionCount > (pageNumber+2)*perPage){
                setActiveRight(true);
                //setPageValue(contributionCount);
            } else {
                setActiveRight(false);
            }
        }
    };

    const onChangeLeft = () => {
        //alert("onChangeLeft" + pageNumber)
        setActiveRight(true);
        if (pageVisited > 0){
            //setScrollOnNextPrev(true);
            scrollToTop();
            //setPageValue(pageVisited+perPage)
            setPageNumber(pageNumber-1);
            if (pageNumber <= 1){
                //setActiveRight(true);
                setActiveLeft(false);
            } 

        }
    };
    console.log("interview 1234 ")
    

    const displayList = searchList.slice(pageVisited, pageVisited + perPage).map((interview, index) => {
        console.log("interview : "+interview.id)
        //alert("displayList")

        var questionsList = interview.interviewQuestions
        var questionsMap = [
            {"animal": "dog"}
        ]
        if (interview.interviewquestions != null){
            questionsMap = interview.interviewquestions
            //alert(questionsMap)
        }   
        var out = "";
        if (questionsList != null){
            for (var i = 0; i < questionsList.length; i++){
                out  = out  + "- " +questionsList[i]+" \n"
            }
        }
        //alert(interview.rating);
        var rating1 = "fa fa-star ";
        var rating2 = "fa fa-star ";
        var rating3 = "fa fa-star ";
        var rating4 = "fa fa-star ";
        var rating5 = "fa fa-star ";

        if (parseInt(interview.rating) !== 0) {
            switch(parseInt(interview.rating)){
                case 5 : 
                rating5 = rating5 + "checked";
                rating4 = rating4 + "checked";
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 4 : 
                rating4 = rating4 + "checked";
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 3 : 
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 2 : 
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 1 : 
                rating1 = rating1 + "checked";
                break;
                default: break;
            }

        }

        const hyperTag = (key, value) => {
            var tag = <NavLink className="iq-links" to={value} exact>

                    {key}
            </NavLink>
            if (value == null || value.toLowerCase() === "null"){
                tag = key;
            } 
            return tag;
        }

        const displayQuestionList = questionsList.filter(eachQuesList => eachQuesList !== "" && eachQuesList !== "+" && eachQuesList !== " ").map((eachQuesList) => {
            //if (eachQuesList !== "" && eachQuesList !== "+" && eachQuesList !== " ")
            return(
                <div>- {eachQuesList}</div>
            )
        }
        )

        const displayQuestionMap = questionsMap.map((value, index) => {
            //if (eachQuesList !== "" && eachQuesList !== "+" && eachQuesList !== " ")
            //{value == null ? key : hyperTag(key, value)}
            return(
                <div>- {Object.values(value)[0] == null ? Object.keys(value)[0] : hyperTag(Object.keys(value)[0], Object.values(value)[0])}</div>
            )
        }
        )

        //console.log("interview : "+interview.interviewQuestions)
                        return (
                            <div>
                                    <div className="inteview-view">
                                        
                                        <div className="company-name-div">
                                            <div className="company-Name">
                                                {interview.companyName}
                                            </div>
                                            <div className="comp-name-share">

                                                <div class="dropdown" >
                                                    <div onClick={() => showSidebar(index)} > <i className="fas fa-share-alt"/>Share</div>
                                                    {checkSidebar[index] ?
                                                        <div class="dropdown-content">

                                                            <div className="eachShareItems"><EmailShareButton url={shareUrl+interview.id}><span className="fas fa-envelope lightShareItems" onClick={() => showSidebar(index)}> Email</span> </EmailShareButton></div>
                                                            <div className="eachShareItems"><FacebookShareButton url={shareUrl+interview.id}> <span className="fab fa-facebook lightShareItems" onClick={() => showSidebar(index)}> Facebook </span></FacebookShareButton></div>
                                                            <div className="eachShareItems"><WhatsappShareButton url={shareUrl+interview.id}><span className="fab fa-whatsapp lightShareItems" onClick={() => showSidebar(index)}> Whatsapp </span></WhatsappShareButton></div>
                                                            <div className="eachShareItems"><TwitterShareButton url={shareUrl+interview.id}><span className="fab fa-twitter lightShareItems" onClick={() => showSidebar(index)}> Twitter </span></TwitterShareButton></div>
                                                            <CopyToClipboard text={shareUrl+interview.id}><div className="eachShareItems"><span className="fa fa-clone lightShareItems" onClick={() => showSidebar(index)}> Copy Link </span></div></CopyToClipboard>
                                                        </div>
                                                    : null}
                                                </div>

                                                {/* <div className="home-share"> 
                                                     <div onClick={togglePop} data-div_name={interview.id}>
                                                         <i className="fas fa-share-alt"/>Share
                                                    </div> 
                                                </div> */}

                                            </div>
                                        </div>
                                        <hr/>

                                        <div className="watermarked-home">
                                        <img src="/logo.png" alt="intereview_logo"/>
                                        <div className="content">
                                        <div className="company-role-div">
                                            <div className="company-role">{interview.jobRole}</div>
                                            <div >Date : {interview.date}</div>
                                        </div>
                                        <div>
                                            <div className="inteview-title">rating</div>

                                            <span className={rating1}></span>
                                            <span className={rating2}></span>
                                            <span className={rating3}></span>
                                            <span className={rating4}></span>
                                            <span className={rating5}></span>
                                        </div>
                                        <br/>
                                        <div className="inteview-title">Application Process</div>
                                        <div> - {interview.applicationProcess}</div>
                                        <br/>
                                        <div className="inteview-title">Interivew Process</div>
                                        <div> - {interview.interviewProcess} </div>
                                        <br/>
                                        <div className="inteview-title">Inteview Questions</div>
                                        {displayQuestionMap}
                                        {/* {(() => {
                                                    if (questionsList != null){
                                                        var out = "";
                                                        for (var i = 0; i < questionsList.length; i++){

                                                            out  = out  + "- " +questionsList[i]+" ,"
                                                        }
                                                        return (
                                                            <div>{out}</div>
                                                          )

                                                    }

                                        })()} */}
                                        {/* //console.log(" interviewQuestions : "+interview.inerviewQuestions)
                                            interview.interviewQuestions.map((interviewDetails)=> {
                                            console.log("interview : "+interviewDetails)
                                            return(
                                                <div>
                                                    <h1>{interviewDetails}</h1>
                                                </div>
                                            )
                                        })
                                        }  */}
                                        {/* <div> - {interview.inerviewQuestions}</div> */}
                                        <br/>
                                        <div className="inteview-title">Suggestions</div>
                                        <div> - {interview.suggestions}</div>
                                        </div>
                                        <br/>
                                        </div>
                
                                    </div>
                                </div>
                        )

                        })


    const overviewDisplay = () => {
        return(<div className="overviewMain">
            <div className="overviewTitle">{companyDetails[searchText]["name"]} Overview</div>
            <div>
                <table className="overviewTable1">
                    <tr>
                        <td><span className="fa fa-globe overviewIconColor" /></td>
                        <th>Website</th>
                        <td> : </td>
                        <td><a href={companyDetails[searchText]["site"]} target="_blank">{companyDetails[searchText]["site"]}</a></td>
                    </tr>
                    <tr>
                        <td><span className="fa fa-user overviewIconColor" /></td>
                        <th>CEO</th>
                        <td> : </td>
                        <td>{companyDetails[searchText]["CEO"]}</td>
                    </tr>
                    <tr>
                        
                        <td><span className="fa fa-map-marker overviewIconColor" /></td>
                        <th>Headquarters</th>
                        <td> : </td>
                        <td>{companyDetails[searchText]["Headquarters"]}</td>
                    </tr>
                    <tr>
                        <td><span className="fa fa-calendar overviewIconColor" /></td>
                        <th>Founded</th>
                        <td> : </td>
                        <td>{companyDetails[searchText]["founded"]}</td>
                    </tr>
                    <tr>
                        <td><span className="fa fa-hand-holding-usd overviewIconColor" /></td>
                        <th>Revenue</th>
                        <td> : </td>
                        <td>{companyDetails[searchText]["revenue"]}</td>
                    </tr>
                    <tr>
                        <td><span className="fa fa-chart-line overviewIconColor" /></td>
                        <th>Type</th>
                        <td> : </td>
                        <td>{companyDetails[searchText]["type"]}</td>
                    </tr>
                    <tr>
                        <td><span className="fa fa-users overviewIconColor" /></td>
                        <th>No. of Employees</th>
                        <td> : </td>
                        <td>{companyDetails[searchText]["emp"]}</td>
                    </tr>
                    <tr>
                        <td><span className="fa fa-industry overviewIconColor" /></td>
                        <th>Industry</th>
                        <td> : </td>
                        <td>{companyDetails[searchText]["Industry"]}</td>
                    </tr>
                    <tr>
                        <td><span className="fa fa-info-circle overviewIconColor" /></td>
                        <th>About</th>
                        <td> : </td>
                        <td>{companyDetails[searchText]["about"]}</td>
                    </tr>

                    {/* "site":"www.zoho.com",
        "founded":"17 March 1996",
        "emp":"13000+ (2022)",
        "customers":"500+",
        "type":"private",
        "revenue":"₹5,230 crore (US$650 million) (2021)",
        "jobPortal" : "https://careers.phenompeople.com/us/en/search-results",
        "about":"",
        "Subsidiaries":"Manage Engine Qntrl Trainer Central",
        "CEO" : "Sridhar Vembu",
        "Headquarters":"Chennai, TamilNadu, India",
        "Industry":"" */}

                </table>
            </div>
        </div>)

    }

    //Search Result.
        
        const searchHandler = (event) => {

            event.preventDefault();

            const newSearchList = [];
            contributionList.forEach((interview) => {
                if(interview.companyName.toLowerCase() === searchEventText.toLowerCase()){
                    newSearchList.push(interview);
                }
            });
            //alert(searchText)
            if (newSearchList.length > 0){
                setSearchList(newSearchList);
                setSearchText(searchEventText);
                //return <Redirect to={'/'+searchEventText} />

                history.push('/'+searchEventText);
                refreshPage();
            } else {
                //setSearchList(contributionList);
                alert("No Search result Found ")
            }
        }
    
        const searchListView = contributionList.map((interview, index) => {
            if (!searchValue.has(interview.companyName.toLowerCase())){
                searchValue.add(interview.companyName.toLowerCase());                        
            return(                
                <option value={interview.companyName}/>
            )
            }
            return null;
        })

        const setSearchTextValue = (searchVal) => {
            setSearchEventText(searchVal.toLowerCase());
        }

        const changeDisplayView = (val) => {
            setViewPart(val);
        }

        
        return(
        <div>
            <Helmet>
                <title>InteReviews</title>
                <meta name="description" content=" In intereviews, we can view the recent interview experiences added by users." />
            </Helmet>
            <div>
                <div>
                    <div className="container home-search-content">

                    <div>
                        {state && <PopUp
                        content={interviewId}
                        handleClose={togglePop}
                        />}
                    </div>


                        <div>
                            <form onSubmit={searchHandler}>
                                <input type="text" list="browsers" placeholder="Search Company Names .." className="search-input" onChange={(event) => {setSearchTextValue(event.target.value)}}/>
                                    <datalist id="browsers">
                                    {searchListView}

                                  </datalist>
                                <button type="submit" className="search-button"><i className="fa fa-search"/><j className="search-button-name"> Search</j> </button>

                                <span >  <NavLink className="recent-interview-button" to="/contributions" exact><i className="fas fa-user-plus"></i>Add an Interview</NavLink></span>
                            </form>
                        </div>
                    </div>

                    <div className="container home-content-company">
                        
                        {/* {alert(searchText)} */}

                        <div class="company-logo"><img src={"company_logos/"+companyDetails[searchText]["img"]} width="80px" height="80px"/></div>
                        <div className="color-tag"></div>
                        <div>
                        <span className="search-CompanyName"> {companyDetails[searchText]["name"]}</span>
                        <span className="companySocial">
                            <a href={companyDetails[searchText]["linkedin"]} target="_blank" >
                                <span className="fab fa-linkedin linkedinColor socialView" />
                            </a> 
                            <a href={companyDetails[searchText]["facebook"]} target="_blank" >
                                <span className = "fab fa-facebook facebookColor socialView"/>
                            </a> 
                            <a href={companyDetails[searchText]["twitter"]} target="_blank" >
                                <span className = "fab fa-twitter twitterColor socialView"/>
                            </a> 
                        </span>
                        </div>
                        <br/>
                        <span > <a href={companyDetails[searchText]["jobPortal"]} target="_blank" className="jobPortalButton"><i className="fa fa-briefcase"/>Job Portal</a></span>

                        <div className="companyBlocks">
                        <span className="companyBlockViews">
                        <table className="search-table">                            
                            <tr>
                                <th onClick={() => changeDisplayView(0)} >Overview</th>
                                <th onClick={() => changeDisplayView(1)}>Interviews</th>
                            </tr>
                            <tr>
                                <td className={viewPart==0?"td1":"td2"} onClick={() => changeDisplayView(0)}></td
                                ><td className={viewPart==1?"td1":"td2"} onClick={() => changeDisplayView(1)}>{searchList.length}</td>
                            </tr>
                        </table>
                        </span>


                        </div>

                    </div>

                    <div className="container home-content">
                    
                    {/* {display} */}
                    {viewPart==0 ? overviewDisplay() : displayList }

                    {/* checking <FirebaseCURDOperations/> */}
                    {/* <div className="inteview-view">
                        <div className="company-name-div">
                            <div className="company-Name">
                                PhenomPeople
                            </div>
                            <div className="comp-name-share">
                                <div className="fas fa-share-alt"> 
                                    Share 
                                </div>
                                <div className="far fa-thumbs-up like-name"> 
                                    Like
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="company-name-div">
                            <div className="company-Name">Job title</div>
                            <div >Date :</div>
                        </div>
                        <div>
                            <div className="inteview-title">rating</div>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                        </div>
                        <br/>
                <div className="inteview-title">Application Process</div>
                <div> - applied through Interview</div>
                <br/>
                <div className="inteview-title">Interivew Process</div>
                <div> - 2 rounds of interview </div>
                <br/>
                <div className="inteview-title">Inteview Questions</div>
                <div> - what is java </div>
                <br/>
                <div className="inteview-title">Suggestions</div>
                <div> - for code pratice check the LeetCode</div>
                <br/>
                
            </div>
            <div className="inteview-view">
                <div className="company-name-div">
                    <div className="company-Name">Company Name</div>
                    <div className="comp-name-share">
                        <div className="fas fa-share-alt"> Share </div>
                        <div className="far fa-thumbs-up like-name"> Like</div>
                    </div>
                </div>
                <hr/>
                <div className="company-name-div">
                    <div className="company-Name">Job title</div>
                    <div>Date :</div>
                </div>
                <div className="inteview-title">rating</div>
                <br/>
                <div className="inteview-title">Application Process</div>
                <div> - applied through Interview</div>
                <br/>
                <div className="inteview-title">Interivew Process</div>
                <div> - 2 rounds of interview </div>
                <br/>
                <div className="inteview-title">Inteview Questions</div>
                <div> - what is java </div>
                <br/>
                <div className="inteview-title">Suggestions</div>
                <div> - for code pratice check the LeetCode</div>
                <br/>
                
            </div> */}
            {viewPart==0 ?
            null :  
            <>            
            <hr/>

            <div className="prev-next-view">
                
                <i>{pageVisited+1}-{(pageVisited+perPage) > contributionCount ? contributionCount : pageVisited+perPage} of {contributionCount}</i>
                <i className= {isActiveLeft ? "fas fa-chevron-left prev-btn" : "fas fa-chevron-left prev-btn1 btn-disabled" } onClick={onChangeLeft} ></i>
                <i className= {isActiveRight ? "fas fa-chevron-right next-btn" : "fas fa-chevron-right next-btn1 btn-disabled"} onClick={onChangeRight}></i>
            </div>
            </>
            }

            <br/>
            </div>
            </div>
            <br/>
            </div>
            <Footerpage/>
        </div>
        
        )
}


export default CompanyReview;