import {React, useEffect, useState} from 'react'
import './Pages.css'
import Footerpage  from './Footerpage'
import Navbar from './Navbar';

import {Helmet} from "react-helmet";


function About(){
    Navbar("aboutUs");
    useEffect(() => {
       // window.location.reload();
    });

    // const componentDidMount = () => {
    //     window.location.reload();
    // }
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [timeline, setTimeline] = useState("");
    const [message, setMessage] = useState("");
    const sendMail = "mailto:contact%40intereviews.com?subject=ContactUsMessage&message=message&body=";

    const addInterviewExperience = async(e) => {
        e.preventDefault();
        //alert("checkinf");
        //alert("event : "+e.target[0].value);
        // for(var i=12; i< e.target.length-2; i++){
        //     //alert("target "+ i+" : "+e.target[i].value + " checking : "+(e.target[i].value !== "+"));
        //     console.log("target "+ i+" : "+e.target[i].value);
        //     if ((e.target[i].value !== "+") && (e.target[i].value !== "") && 
        //     (e.target[i].value !== null) && (e.target[i].value !== " ") ){
        //         //alert("inner target " + e.target[i].value);
        //             interviewQuestionsList.push( e.target[i].value);
        //             var interviewquestion = {}
        //             interviewquestion[e.target[i].value] = "null";
        //             interviewQuestionsListMap.push(interviewquestion)     
        //         }  
        // }

        

            //alert("Thanks for you Contribution! \n");

            //s("");
            
            //window.location.reload();

    }
        

    

        const dot = ".";
        const atRate = "@";

        return(
        <div>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="Interview is a platform, where users can view the INTERVIEW REVIEWS posted by the users, some interview questions and blogs to learn." />
            </Helmet>
            <div className="container about-page-image">
                <div>
                    <img src="/pexels.jpg" className="about-image" alt="logo"/>
                    <div class="about-centered">About Us</div>
                </div>








                <div>


                </div>
            </div>

            <div className="container what-we-provide">
            <div className="aboutUs-title">Now, What is InteReviews?<br/><br/></div>
            <div className="aboutUs-content">

            <div className="about-page-content">
                <div className="title">
                </div>
                
                <div className="content">

                <div><strong>InteReviews </strong> is a platform where .... 

                    <ul>
                        <li>you can share your recent interviews with Us.</li>
                        <li>you can view the interview process and reviews that shared by the users</li>
                        <li>you can learn some interview questions that mostly asked by an interviewer in interview by topic wise.</li>
                    </ul>
                    My future development are that we can provide tutorials like what to prepare day before interview.

                    <br/>
                    <div> Please share your feedback and Suggestions to my<strong>                <a href="mailto:?subject=About Intereviews &amp;body=Suggestions : .&amp;to=contact%40intereviews.com" title="Share by Email">mail</a></strong></div>
                </div>
                <br/>
                </div>
                <br/><br/><br/><br/><br/>
            </div>

            <div className="about-page-logo">
                <img src="/logo2.png" className="about-page-logoSize" alt="logo"/>
            </div>
            </div>
        </div>


        <div className="container what-we-provide">
            <div className="aboutUs-title">Contact Us<br/><br/></div>

            <div class="contact-form" >
                <form method="post" action={sendMail+"Name : "+name+", \nEmail : "+email+", \nTimeline : "+timeline+", \nMessage : "+message} >        

        <div class="form-cont" >
        <label class="label mt1" for="name">Name</label>
        <input id="name" onChange={(event) => {setName(event.target.value)}} />
        </div><br/>
        <div class="form-cont" >
        <label class="label mt1" for="email">Email</label>
        <input id="email" type="email" onChange={(event) => {setEmail(event.target.value)}} />
        </div><br/>
        <div class="form-cont" >
        <label class="label mt1" for="dates">Timeline</label>
        <input  id="dates" onChange={(event) => {setTimeline(event.target.value)}} />
        </div><br/>
        <div class="form-cont" >
        <label class="label mt1" for="message">Message</label>
        <textarea id="message"  onChange={(event) => {setMessage(event.target.value)}} />
        </div><br/><br/>
        <div class="form-cont-button">
          <button class="btn btn--outline">Send</button>
        </div>

      </form>
      </div>        
      <br/>
        </div>




        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <footer><Footerpage/></footer>
        </div>)
}

export default About;