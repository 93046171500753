import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MiniFooter from '../MiniFooter';
import Navbar from '../Navbar';

import {Helmet} from "react-helmet";



export const BlogPages = [
    {
        name : " Gmail Pagination view using React ",
        link : "/blog/gmail-pagination-view-using-react",
        tag : "React",
        date : "Sep 20 - 2021",
        summary : " In this tutorial, u r going to learn about pagination view of gmail...",
        watermark : "watermarked-react",
        display : true

    },

    {
        name : " Square Root of a Given Number Without build in Functions",
        link : "/blog/square-root-of-given-number",
        tag : "Java",
        date : "Nov 11 - 2021",
        summary : " In this tutorial, u r going to learn about Square Root of a G...",
        watermark : "watermarked-java",
        display : true

    },

    {
        name : " Power of a Number  ",
        link : "    ",
        tag : "Java",
        date : "Nov 11 - 2021",
        summary : " In this tutorial, u r going to learn about Power of a Given...",
        watermark : "watermarked-java",
        display : true

    },

    {
        name : " Try-With-Resources in Java ",
        link : "/blog/Try-With-Resources",
        tag : "Java",
        date : "Nov 16 - 2021",
        summary : " In this tutorial, u r going to learn Try-With-Resources in java7...",
        watermark : "watermarked-java",
        display : true

    },

    {
        name : " Update of Try-With-Resources in Java 9 ",
        link : "/blog/Try-With-Resources-on-Java-9",
        tag : "Java",
        date : "Nov 16 - 2021",
        summary : " In this tutorial, u r going to learn Try-With-Resources in java9...",
        watermark : "watermarked-java",
        display : true

    },

    {
        name : " how to pass values to onClickHandler in reactJs ",
        link : "/blog/How-to-pass-values-to-OnClickHandler-in-react",
        tag : "React",
        date : "Nov 16 - 2021",
        summary : " In this tutorial, u r going to learn about how to pass values to OnClickHandler in react",
        watermark : "watermarked-react",
        display : true

    },



    {
        name : " NPM Start error due to Watcher limit is less in Linux ",
        link : "/blog/NPM-Start-Error-Due-To-Watcher-Limit-Reached-in-Linux",
        tag : "React",
        date : "Nov 16 - 2021",
        summary : " In this tutorial, u r going to learn about how to change the System limit ...",
        watermark : "watermarked-react",
        display : true

    },

    {
        name : " Window Events in react ",
        link : "/blog/Window-Events-in-react",
        tag : "React",
        date : "Jan 06 - 2022",
        summary : " In this tutorial, u r going to learn about how to use window events in react",
        watermark : "watermarked-react",
        display : true

    },

]

export const NewBlogPages = [

    {
        name : " How to connect react Project with Google Analytics  ",
        link : "/blog/How-to-connect-Google-Analytics-in-React-Project",
        tag : "React",
        date : "Feb 23 - 2022",
        summary : " In this tutorial, u r going to learn about how to connect goolge Analytics ...",
        watermark : "watermarked-react",
        display : true,
    },

    {
        name : " ",
        tag : "Java",
    },
    {
        name : " Java 8 features ",
        tag : "Java",
    },
    {
        name : " Popup in react and how to send data to popup page ",
        tag : "react",
    },
    {
        name : " Toast in React ",
        tag : "react",
    },
    {
        name : " scroll to Top when we change another page in react. ",
        tag : "react",
        ref : "https://www.geeksforgeeks.org/how-to-make-your-page-scroll-to-the-top-when-route-changes/"
    },
    {
        name : " Scroll to TOP on Button click in react. ",
        tag : "react",
    },
    {
        name : "PopUp menu options in react. ",
        tag : "react",
    },
    {
        name : "What is Helmet in react. ",
        tag : "react",
        ref : "https://www.youtube.com/watch?v=OGoPhwK_P_Q"
    },
    {
        name : "scrolling in React. ",
        tag : "react",
        ref : "https://stackoverflow.com/questions/43441856/how-to-scroll-to-an-element",
    },
    {
        name : "Hiding Your Email Addresses From Spam Harvesters – Part I",
        ref : "http://www.designchemical.com/blog/index.php/website-coding/hiding-your-email-addresses-from-spam-harvesters/",
        ref2 : "https://www.ionos.com/digitalguide/e-mail/e-mail-security/protecting-your-email-address-how-to-do-it/",

    },
    {
        name: "ads",
        inputs : [
            "https://www.mgid.com/",

        ],

    },
    {
        name : " Controlled Vs UnControlled-components in react ",
        link : "/blog/gmail-pagination-view-using-react",
        tag : "React",
        date : "Sep 20 - 2021",
        summary : " In this tutorial, u r going to learn about pagination view of gmail...",
        watermark : "watermarked-react",
        referenceUrl : "https://reactjs.org/docs/uncontrolled-components.html"    
    },
    {
        name : "z-index property in css ",
        link : "/blog/gmail-pagination-view-using-react",
        tag : "css",
        date : "Sep 20 - 2021",
        summary : " In this tutorial, u r going to learn about pagination view of gmail...",
        watermark : "watermarked-react",
        referenceUrl : "https://www.w3schools.com/cssref/pr_pos_z-index.asp"    
    },

    {
        name : "Axios get and post request in react/node",
        ref : "https://www.codegrepper.com/code-examples/javascript/axios+get+html+page",
        ref1 : "https://masteringjs.io/tutorials/axios/response-body",
        tag : "javascript, node, react"
    },
    {
        name : "How to make the long text to fit inside a small div? ",
        ref : "http://jsfiddle.net/FK477/2/",
        tag : "css"
    },
    {
        name : "How to check if it is a JsonArray or JsonObject in Javascript",
        ref : "https://stackoverflow.com/questions/47436236/how-to-check-if-it-is-a-jsonarray-or-jsonobject-in-javascript",
        tag : "css"
    },
    {
        name : "add character to middle of string javascript” Code Answer’s",
        ref:"https://www.codegrepper.com/code-examples/javascript/add+character+to+middle+of+string+javascript ",
        tag: "javascript"
    },
    {
        name:"JavaScript String search()",
        ref : "https://www.w3schools.com/jsref/jsref_search.asp",
        tag: "jS"
    },
    {
        name : "javascript string search second occurrence” Code Answer",
        ref : "https://www.codegrepper.com/code-examples/javascript/javascript+string+search+second+occurrence",
        tag:"JS"
    },
    {
        name : "line-height: normal;",
        ref : "https://www.w3schools.com/cssref/pr_dim_line-height.asp",
        tag : "css"
    }
    ,{
        name:"trianlge- Clip-path maker tester",
        ref : "https://bennettfeely.com/clippy/"
    }

]




function Blog(){

    Navbar("blog");

    //window.location.reload();

    const [blogList, setBlogList] = useState(BlogPages);

    const [blogListCount, setBlogListCount] = useState(BlogPages.length);

    const[ isActiveLeft, setActiveLeft] = useState(false);
    const[ isActiveRight, setActiveRight] = useState(true);

    const [pageNumber, setPageNumber] = useState(0);
    const perPage = 6;
    const pageVisited = pageNumber * perPage;

    //pagination Variables

    //const [pageValue, setPageValue] = useState(pageVisited+perPage);

    const filterTopic = (event) => {
        setBlogListCount(BlogPages.length)
       // alert(event.currentTarget.dataset.div_name);
       const selectTopic = event.currentTarget.dataset.div_name;
       if (selectTopic === "all") {
           setBlogList(BlogPages);
           setBlogListCount(BlogPages.length)
           setActiveRight(true);
           setActiveLeft(false);
           setPageNumber(0)
           //setPageValue(perPage+pageNumber)
       } else {
            const filterList = [];
    
            BlogPages.filter(eachBlog => selectTopic.toLowerCase() === eachBlog.tag.toLowerCase()).map((eachBlog) => {
                //if (selectTopic.toLowerCase() === eachBlog.tag.toLowerCase()) {
                    filterList.push(eachBlog);
                //}
                return null;
            }
            )
            if (filterList.length > 0){
                setBlogList(filterList);
                setBlogListCount(filterList.length)
                setActiveLeft(false);
                //alert(filterList.length)
                if (filterList.length < 7){
                    setActiveRight(false);
                    //setPageValue(filterList.length)
                } else {
                    setActiveRight(true);
                    //setPageValue(perPage+pageNumber)
                }

                setPageNumber(0)

            } else {
                setBlogList(BlogPages);
                alert("No Topics on "+ selectTopic);     
                setBlogListCount(BlogPages.length)
                setActiveRight(true);
                setActiveLeft(false);
                setPageNumber(0)
            }
            

        }

    }



    const onChangeRight = () => {
        if (blogListCount > pageVisited+perPage){
            setPageNumber(pageNumber+1);
            //setPageValue(pageVisited+perPage)
            setActiveLeft(true);
            if (blogListCount/10 < pageNumber+3){
                setActiveRight(false);
                //setPageValue(blogListCount);
            }
        }
    };

    const onChangeLeft = () => {
        if (pageVisited > 0){
            //setPageValue(pageVisited+perPage)
            setPageNumber(pageNumber-1);
            if (pageNumber <= 1){
                setActiveRight(true);
                setActiveLeft(false);
            } else {
                setActiveLeft(false);
            }

        }
    };


    

    // render(){
    return(
        <div className="float-left">
            <Helmet>
                <title>Blog</title>
                <meta name="description" content="Blog contains all the blog and the Topics that we are providing" />
            </Helmet>

            <div className="blog-view parent">

                < div className="blog-layout">
                    <h1><div className="title">Blog</div></h1>
                    <hr/>
                    <div className="prev-next-view">
                        <i>{pageVisited+1}-{(pageVisited+perPage) > blogListCount ? blogListCount : pageVisited+perPage} of {blogListCount}</i>
                        <i className= {isActiveLeft ? "fas fa-chevron-left prev-btn" : "fas fa-chevron-left prev-btn1 btn-disabled" } onClick={onChangeLeft} ></i>
                        <i className= {isActiveRight ? "fas fa-chevron-right next-btn" : "fas fa-chevron-right next-btn1 btn-disabled"} onClick={onChangeRight}></i>
                    </div>
                            <br/>

                    <div className="container  blog-view parent">
   
                        {
                            blogList.slice(pageVisited, pageVisited + perPage).map((items,index)=>{
                                return(
                                <div className="each-blog-layout ">
                                    <div className="each-blog-view">


                                        <div className="main-blog-tag">
                                            <div className="blog-tag">
                                                {items.tag}
                                            </div>
                                        </div>
                                        <div>
                                        <NavLink className="nav-link" to={items.link} exact>
                                        <div className={"block-content " + items.watermark}>
                                            <NavLink className="nav-link" to={items.link} exact>
                                                <div className="blog-name">

                                                    {items.name}
                                                </div>
                                            </NavLink>
                                            <div className="blog-summary ">
                                                {items.summary}
                                            </div>
                                        </div>
                                        </NavLink>
                                        </div>
                                        <div className="blog-date-view">
                                            <div className="blog-date">
                                                {items.date}
                                            </div>
                                            <NavLink to={items.link} exact>
                                                <div className = "blog-readme-view">
                                                    Read Now
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                    // <NavLink className="nav-link" to={items.link} exact>
                    // <i className={items.icon}>
                    // <span>{items.title}</span>
                    // </i>
                    // </NavLink>
                    // )
                            )

                        })}



                     </div>
                    <hr/>

                    {/* <div className="prev-next-view">
                        <i>{pageVisited+1}-{(pageVisited+perPage) > blogListCount ? blogListCount : pageVisited+perPage} of {blogListCount}</i>
                        <i className= {isActiveLeft ? "fas fa-chevron-left prev-btn" : "fas fa-chevron-left prev-btn1 btn-disabled" } onClick={onChangeLeft} ></i>
                        <i className= {isActiveRight ? "fas fa-chevron-right next-btn" : "fas fa-chevron-right next-btn1 btn-disabled"} onClick={onChangeRight}></i>
                    </div> */}
                    <br/>
                </div>
        
                <div>
                <div className="blog-topics-div">
                    <div className="blog-topics">
                        
                        <div className="title" onClick={filterTopic} data-div_name="all">
                            Topics
                        </div>
                        <div className=" blog-topic-value" onClick={filterTopic} data-div_name="all">
                            <i className="fas fa-sync-alt fa-spin"/>All Topics
                        </div>
                        <div className=" blog-topic-value" onClick={filterTopic} data-div_name="react">
                            <i className="fab fa-react"/>React
                        </div>
                        <div className=" blog-topic-value" onClick={filterTopic} data-div_name="java">
                            <i className="fab fa-java"/>Java
                        </div>
                        <div className=" blog-topic-value" onClick={filterTopic} data-div_name="javaScript">
                            <i className="fab fa-js-square"/>JavaScript
                        </div>

                    </div>
                </div>
                <div>
                    {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                        
                    </ins>

                </div>
                </div>
            </div>
            <MiniFooter></MiniFooter>
        </div>
    );

    
    // }
}


export default Blog;