
import React, {useState} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom'
import { Tools } from '../Tools/ToolsPage'

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../Footerpage";

import { useDropzone } from "react-dropzone";


function JpgToPng(){

    const [urlString, setUrlString] = useState("");
    const [encodeString, setEncodeString] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [hasDownload,setHasDownload] = useState(false);



    const clipboard = "";
    const currentTool = "JPG To PNG"




//! A IIFE function to convert the JPG file to PNG
const downloadLink = document.createElement("a");
const [pngImage, setPngImage] = useState("");

const JPG_to_PNG_converter = (() => {
    function converter(imageFileBlob, options) {
      options = options || {}
  
      //* Creating a canvas element and then putting the image (jpg) there
      const canvas = document.createElement("canvas")
      const context = canvas.getContext("2d")
      const imageElement = createImage()
      setPngImage(imageElement);
  
      //* Here we create our image blob
      function createImage(options) {
        options = options || {}
        const img = document.createElement("img")
  
        img.style.width = options.width ? `${options.width}px` : "auto"
        img.style.height = options.height ? `${options.height}px` : "auto"
  
        return img
      }
  
      function updateDownloadLink(jpgFileName, pngBlob) {
        const linkElement = downloadLink
        const pngFileName = jpgFileName.replace(/jpe?g/i, "png")
  
        linkElement.setAttribute("download", pngFileName)
        linkElement.href = URL.createObjectURL(pngBlob)

        setHasDownload(true); 
      }
  
      function process() {
        const imageUrl = URL.createObjectURL(imageFileBlob)
  
        imageElement.addEventListener("load", e => {
          canvas.width = e.target.width
          canvas.height = e.target.height
          context.drawImage(e.target, 0, 0, e.target.width, e.target.height)
          canvas.toBlob(
            updateDownloadLink.bind(window, imageFileBlob.name),
            "image/png"
          )
        })
  
        imageElement.src = imageUrl
      }
  
      return {
        process: process,
      }
    }
  
    return converter
  })()
  
  //HTML image file field
//   const imageFileElement = document.querySelector(".jpg-upload-input")
  
//   //Add file change event handler
//   imageFileElement.addEventListener("change", event => {
//     const jpgImageFileBlob = event.currentTarget.files[0]
  
//     //Validating if the file's type is JPG or different
//     if (jpgImageFileBlob.type.match(/image\/jpe?g/i) !== null) {
//       JPG_to_PNG_converter(jpgImageFileBlob).process()
//     } else {
//       alert(
//         `Invalid JPG image file! Got an image with type ${jpgImageFileBlob.type}`
//       )
//     }
//   })






    const convertimage = () => {
        if (acceptedFiles[0] !== undefined){
            JPG_to_PNG_converter(acceptedFiles[0]).process();
        } else {
            errorToast()
        }
    }

    const downloadImage = () => {
        downloadLink.click()
    } 

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    const errorToast = () => {
        toast.warning("Please select a File ", {

            position:"top-center",
            autoClose : 1000            
        });
    }

    const handleCheckBox = () => {
        setCheckBox(!checkBox);
    }
    const handleInputString = (event) => {

        setUrlString(event.target.value)
        setEncodeString(btoa(event.target.value));
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }




   const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  
   const files = acceptedFiles.map(file => (
    <>
    <h4>Preview</h4>
    {/* <ul>
     <li key={file.path}>
       {file.path} - {file.size} bytes

       
            
     </li>
     </ul> */}
     <img src={URL.createObjectURL(file)} className="dragPreviewImg" alt={file.path}/>  
     <br/>
     <br/>
     <h5><strong>File Details</strong></h5>
     <table>
         <tr><td>&emsp;&emsp;<strong>FileName</strong> </td><td>&emsp;:&emsp;</td><td>{file.path}</td></tr>
         <tr><td>&emsp;&emsp;<strong>Size</strong> </td><td>&emsp;:&emsp;</td><td>{formatBytes(file.size)}</td></tr>
         <tr><td>&emsp;&emsp;<strong>Type</strong> </td><td>&emsp;:&emsp;</td><td>{file.type}</td></tr>
    </table>

     </>
   ));



    //ytes, and force a conversion before executing,
// and convert outputs back into strings.

function refreshPage() {
    setTimeout(()=>{
        window.location.reload(false);
    }, 0);
    console.log('page to reload')
}

const onFileChange = (event) => {
    
    // Update the state
    JPG_to_PNG_converter(event.target.files[0]).process();
  
  };


    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>JPG To PNG Converter</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Free Online JPG To PNG Converter.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">
                            {/* <h1>
                                <div className="title">
                                URL Encoder/Decoder
                                </div>
                            </h1> */}
                            <ToastContainer/>
                            {/* <hr/> */}

                            <div className="blog-page-content">
                            <div>
                                <div><h5><strong>Paste or Enter you plain text below : </strong></h5></div>
                                {/* <div className="jsonFormat-textarea" >
                                    <textarea type="text" placeholder="Enter your PLAIN text here ..." onChange={(event) => {handleInputString(event)}} />
                                </div> */}
                            <div className="">
                                <div>
                            <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <div className="drag-area">
                            <div className="dragArea-icon"><i className="fas fa-cloud-upload-alt"></i></div>
                            <p className="dragArea-text">Drag &amp; Drop to Upload File</p>
                            <p className="dragArea-text">Or</p>
                            <p className="dragArea-button">click to select files</p>
                            </div>
                            </div>
                            <br/>
                            <aside>

                                {files}
                            </aside>
                            </section>
                            </div>
                            <div>

                            </div>
                            </div>


                                <br/>
                                <div className="text-center">
                                    {/* <span className="autoButton"><input type="checkbox" checked={checkBox} onChange={handleCheckBox}/><b> Auto</b></span> &nbsp;&nbsp;  */}
                                    <i className="json-copyLink convert-button-size1"><button onClick={convertimage}>Convert</button></i>
                                    {/* <i className="json-copyLink convert-button-size2"><button onClick={DecodeUrl}>Decode</button></i>
                                    <CopyToClipboard text={urlString}>
                                        <i className="json-copyLink convert-button-size2" title="copy" ><button onClick={diffToast}>Copy</button></i>
                                    </CopyToClipboard> */}



      {/* <label class="jpg-upload">
        <input type="file" name="image" class="jpg-upload-input" />
        Pick a JPG file to convert to PNG!
      </label>

      <input type="file" onChange={onFileChange} /> */}

                                 </div>
                                 <br/>

                                 {hasDownload ? 

                                <div>
                                    <div className="text-center">

                                    <i className="json-copyLink convert-button-size1"><button onClick={downloadImage}>Download</button></i>
                                    </div>
                                </div>
                                :null}

                            </div>
                           <br/>
                            <br/>
                           <div className="json-output-border">
                               <div>
                                    {/* <div><h5><strong>Formatted View : </strong></h5></div> */}
                                    {/* <div className="encoder-output-top">
                                        <div >
                                            <div>
                                                <strong className="jsonblock-title">Output : </strong>

                                                <CopyToClipboard text={encodeString}>
                                                   <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="encoder-textarea" >
                                        <textarea type="text" placeholder="Base64 Encoded output goes here..." value={checkBox ? encodeString:outputString}/>                        
                                    </div> */}
                                    <br/>
                                    <div className="BugNote">
                                        <strong >Note :</strong>
                                        <i> The tool uses UTF-8 charset. Found a bug?</i>
                                        <a href="mailto:?subject=Bug Report on Base64 Encode  &amp;body= Bug Report :&amp;to=contact%40intereviews.com" title="Share by Email" className="reportBug">
                                           report!
                                        </a>
                                    </div>


                                    {/* <div className="json-border">

                                    </div> */}
                                </div>
                            </div>

                        </div>
                        
                    </div>                    
                </div>

                <div className="blogLayout">

                    <div className="json-innerPage-layout">
                        <div className="blog-page-content">
                            <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
                                <strong> About</strong></h5></div>
                                <hr/>
                                <div><h5>JPG To PNG Convertor Tool</h5></div>
                            <div>Check this online JPG To PNG Converter Tool, which will helps to convert JPG format to PNG format.</div>
                            <div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        <div>                

        <div className="tools-topics-div">
            <div className="tools-topics">
                <div className="title">
                <b className="fas fa-cog" />
                     <c> Other Tools</c>
                </div>
                {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

                return(
                    <div className="tools-topic-value">   
                    <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                        <div className="blog-all-topics-name" >
                            {items.name}
                        </div>       
                    </NavLink>
                   </div>
                   )
                })}


            </div>
        </div>
        <div className="tools-topics-div">
            <div>
                {/* <!-- side-vertical --> */}
                <ins class="adsbygoogle"
                     style={{display:'block'}}
                    data-ad-client="ca-pub-8123049304648947"
                    data-ad-slot="5905496439"
                    data-ad-format="auto"
                    data-full-width-responsive="true">     
                </ins>
            </div>
        </div>
        </div>
    </div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default JpgToPng;