import React , {useEffect} from 'react'
import './Navbar.css';
import { NavLink} from 'react-router-dom';
//import { NavLink, Link} from 'react-router-dom';
import $ from 'jquery';






const Navbar = (props) => {




  function animation(){
    var tabsNewAnim = $('#navbarSupportedContent');
    var activeItemNewAnim = tabsNewAnim.find('.active');
    var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
    var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
    var itemPosNewAnimTop = activeItemNewAnim.position();
    var itemPosNewAnimLeft = activeItemNewAnim.position();
    //alert("left : "+itemPosNewAnimLeft.left)
    //alert("top : "+itemPosNewAnimTop.top)
    //alert("navBarSupport : "+itemPosNewAnimTop.top + " checking :  "+itemPosNewAnimTop.top < 0)  
    if (itemPosNewAnimTop.top < 0) {
      itemPosNewAnimTop.top = 0;
    }

    $(".hori-selector").css({
      "top":itemPosNewAnimTop.top + "px", 
      "left":itemPosNewAnimLeft.left + "px",
      "height": activeWidthNewAnimHeight + "px",
      "width": activeWidthNewAnimWidth + "px"
    });
    $("#navbarSupportedContent").on("click","li",function(e){
      $('#navbarSupportedContent ul li').removeClass("active");
      $(this).addClass('active');
      var activeWidthNewAnimHeight = $(this).innerHeight();
      var activeWidthNewAnimWidth = $(this).innerWidth();
      var itemPosNewAnimTop = $(this).position();
      var itemPosNewAnimLeft = $(this).position();
      //alert(itemPosNewAnimTop.top + " :  "+itemPosNewAnimLeft.left + " : "+activeWidthNewAnimHeight+" : "+activeWidthNewAnimWidth)  
      if (itemPosNewAnimTop.top < 0) {
        itemPosNewAnimTop.top = 0;
      }

      $(".hori-selector").css(        
        {
        "top":itemPosNewAnimTop.top + "px", 
        "left":itemPosNewAnimLeft.left + "px",
        "height": activeWidthNewAnimHeight + "px",
        "width": activeWidthNewAnimWidth + "px"
      });
    });


    console.log(" in animation function :  "+window.location.pathname +" " +interviewQuestions+ " "+tutorials+" "+aboutUs+" "+props.toString())

    // $(".notActive").css({
    //   "color":"#FFFFFF" 
    // });

    // $(".active").css({
    //   "color":"#2a3644"
    // });

    








  }

  useEffect(() => {
    
    animation();
    $(window).on('resize', function(){
      setTimeout(function(){ animation(); }, 500);
    });
    
  },);




  let home = "nav-item home";
  let interviewQuestions = "nav-item interviewQuestions";
  let tutorials = "nav-item tutorials";
  let contributions = "nav-item contributions";
  let aboutUs = "nav-item aboutUs";
  let blog = "nav-item blog";
  let tools = "nav-item tools";

  console.log("checking"+props.toString())
  console.log(props.toString() === 'interviewQuestions')
  //alert(props.toString().toLowerCase());
  if (props.toString().toLowerCase() === "interviewquestions") { 
    console.log('testing');
    //alert("testing ...")
    $('#navbarSupportedContent ul li').removeClass("active");
    $(".interviewQuestions").addClass('active');
  } else if (props.toString() === 'tutorials') {
    $('#navbarSupportedContent ul li').removeClass("active");
    $(".tutorials").addClass('active');
  } else if (props.toString() === 'contributions') {
    $('#navbarSupportedContent ul li').removeClass("active");
    $(".contributions").addClass('active');
  } else if (props.toString() === 'blog') {
    $('#navbarSupportedContent ul li').removeClass("active");
    $(".blog").addClass('active');
  } else if (props.toString() === 'aboutUs') {
    $('#navbarSupportedContent ul li').removeClass("active");
    $(".aboutUs").addClass('active');
  } else if (props.toString() === 'tools') {
    $('#navbarSupportedContent ul li').removeClass("active");
    $(".tools").addClass('active');
  }  else if (props.toString() === "home") {
    $('#navbarSupportedContent ul li').removeClass("active");
    $(".home").addClass('active');
  }


  if (window.location.pathname.includes("/interviewQuestions") || window.location.pathname.includes("/interviewquestions")) {
    interviewQuestions = interviewQuestions +" active"
    // home = home + " notActive"; 
    // tutorials = tutorials + " notActive";
    // contributions = contributions + " notActive";
    // aboutUs = aboutUs + " notActive";
    // blog = blog + " notActive";    

  } else if (window.location.pathname.includes("/contributions")){
    contributions = contributions + " active"

  } else if (window.location.pathname.includes("/about")){
    aboutUs = aboutUs + " active"

  } else if (window.location.pathname.includes("/blog")) {
    blog = blog + " active";
    
  } else if (window.location.pathname.includes("/tools")) {
    tools = tools + " active";
    
  } else {
    home = home + " active";

  }
  

  console.log('I was triggered during componentDidMount '+window.location.pathname +" " +interviewQuestions+ " "+tutorials+" 123aboutUs123 "+aboutUs+" : propsStringName : "+props.toString());

  return (

  <nav className="navbar navbar-expand-lg navbar-mainbg">
    
      <NavLink to="/" className="navbar-brand navbar-logo" exact >
        <div>
        <img src="/logo192.png" className="App-logo" alt="logo"/>
        </div>
      </NavLink>
    
    
      <button 
        className="navbar-toggler"
        onClick={ function(){
          setTimeout(function(){ animation(); });
        }}
        type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i className="fas fa-bars text-white"></i>
      </button>
 
      <div 
        className="collapse navbar-collapse" 
        id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
            
            <div className="hori-selector">
              <div className="left"></div>
              <div className="right"></div>
            </div>
            
            <li className={home}
                        data-toggle="collapse" 
                        data-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" 
                        //aria-expanded="false" 
                        aria-label="Toggle navigation"

            >
              <NavLink className="nav-link" to="/" exact>
                <i 
                className="fas fa-home">
                </i>Home
              </NavLink>
            </li>

            <li className={interviewQuestions} 
            
            data-toggle="collapse" 
            data-target="#navbarSupportedContent" 
            //aria-expanded="false" 
            >
              <NavLink className="nav-link" to="/interviewQuestions" exact>
                <i 
                className="fas fa-question-circle navbar-titles" >
                </i>Interview Questions
              </NavLink> 
            </li>

            <li className={contributions}
            data-toggle="collapse" 
            data-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            //aria-expanded="false" 
            aria-label="Toggle navigation"
            >
              <NavLink className="nav-link" to="/contributions" exact>
                <i
                className="fas fa-user-plus">
                </i>Your Contributions
              </NavLink>
            </li>

            <li className={blog} 
            data-toggle="collapse" 
            data-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            //aria-expanded="false" 
            aria-label="Toggle navigation"
            >
              <NavLink className="nav-link" to="/blog" exact>
                <i 
                className="fas fa-blog">
                </i>Blog
              </NavLink>
            </li>

            <li className={tools} 
            data-toggle="collapse" 
            data-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            //aria-expanded="false" 
            aria-label="Toggle navigation"
            >
              <NavLink className="nav-link" to="/tools" exact>
                <i 
                className="fas fa-tools">
                </i>Tools
              </NavLink>
            </li>

            <li className={aboutUs}
                        data-toggle="collapse" 
                        data-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" 
                        //aria-expanded="false" 
                        aria-label="Toggle navigation"
            >
              <NavLink className="nav-link" to="/about" exact>
                <i 
                className="far fa-address-book">
                </i>About Us
              </NavLink>
            </li>

        </ul>
      </div>
  </nav>
  )
}
export default Navbar;