
import React, {useRef} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiniFooter from "../MiniFooter";
import { NavLink } from 'react-router-dom'
//import {EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton} from "react-share"
import { Helmet} from "react-helmet";
import { BlogPages } from '../InterviewQuestionPages/Blog'

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
//import Footerpage from "../Footerpage";

function WindowEventsInReact(){

    const cpbrdData1 = "window.addEventListener('click' ,handleWindowClick);";
    const cpbrdData2 = "useEffect (() => {\n\treturn () => {};\n}, [ closePopup, setClosePopup ])";
    const cpbrdData3 = "export default function PopupOperations () {\n\tconst [ closePopup, setClosePopup ] = useState(false)\n\n\tuseEffect (() => {\n\t\tconst handleWindowClick = () => setClosePopup (false) ;\n\t}, [ closePopup, setClosePopup ])\n};";
    const cpbrdData4 = "export default function PopupOperations () {\n\tconst [ closePopup, setClosePopup ] = useState(false)\n\n\tuseEffect (() => {\n\t\tconst handleWindowClick = () => setClosePopup (false) ;\n\t\tif (closePopup) {\n\t\t\twindow.addEventListener('click' ,handleWindowClick);\n\t\t} else {\n\t\t\twindow.removeEventListener('click' ,handleWindowClick);\n\t\t}\n\t\treturn () => window.removeEventListener('click' ,handleWindowClick);\n\n\t}, [ closePopup, setClosePopup ])\n};";

    // const popUpAlert= () => {
    //     alert("Copied to Clipboard");
    // }

    const relatedTopics = "React";

    const myRef = useRef(null);
    const scrollPage = () => myRef.current.scrollIntoView() 

    const diffToast = () => {
        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }



    return(
        <>

            <Helmet>
                <title>Window Events in React</title>
                <meta name="description" content=" In this tutorial, we are going to learn about Window Events in ReactJs. " />
            </Helmet>

        <div className="blogLayout">
            <div className="blog-page-layout">
                <h1>
                <div className="title">
                    Window Events in React
                </div>
                </h1>
                <ToastContainer/>
                <br/>
                <div className="blog-page-tag-border">
                <div className="parent">
                    <div className="blog-page-tag" title="copy" onClick={scrollPage}>ReactJs</div>
                </div>
                </div>
                <hr/>

                <div className="blog-page-content">
                    <div>
                        <strong>In this tutorial,</strong> we are going to learn about <strong>Window Events</strong> in <strong>ReactJs</strong>.
                    </div>
                    <br/>
                    <div>
                        <strong>For example,</strong> if there is a <strong>popup window</strong> and if we want to close the that by clicking <strong>outside</strong> of the popup window. 
                    </div>

                    <div>
                        we need to use the <strong>Global event listener</strong> to slove this.
                    </div>

                    <br/>



                    <div>

                        <ul>
                            <li> 
                                <strong>Requirements : </strong>
                                <div> <strong>~</strong> About Event Listener</div>
                                <div> <strong>~</strong> UseEffect</div>

                            </li>
                            <br/>
                            <li>
                                <p>
                                <div>
                                    <strong></strong> Event Listener 
                                </div>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData1}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c></c>
                                            <c className="codeblock-classname2">{"window"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-setconst">{"addEventListener"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classnamevalue">{"'click' "}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{"handleWindowClick"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>
                                        
                                    </div>

                                </div>
                                <br/>
                                <div>
                                <strong> -</strong> While adding Event listener, We need to check twice before adding <strong>Event Listener</strong> in our code. because if we change anything it will re-render the component and add a new event listener everytime and uses lot of memory.                                  
                                </div> 
                                </p>
                            </li>

                            <br/>
                            <li>
                                <p>
                                <div>
                                <strong></strong> 
                                To slove the above problem, we can use a special Hook called <strong>useEffect</strong> in React, which will run only when specific properties change.
                                
                                </div>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData2}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">

                                        <div>
                                        <c></c>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-setconst">{"useEffect "}</c>
                                            <c className="codeblock-classnameEqual">{" (() "}</c>
                                            <c className="codeblock-tagvalue">{"=>"}</c>
                                            <c className="codeblock-classnameEqual">{" { "}</c>

                                        </code>
                                        </div>


                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classPink">{"return "}</c>
                                            <c className="codeblock-classnameEqual">{"()"}</c>
                                            <c className="codeblock-tagvalue">{" => "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c></c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-classnameEqual">{", "}</c>
                                            <c className="codeblock-classnameEqual">{"["}</c>
                                            <c className="codeblock-tagvalue2">{" closePopup"}</c>
                                            <c className="codeblock-classnameEqual">{", "}</c>
                                            <c className="codeblock-setconst">{" setClosePopup "}</c>
                                            <c className="codeblock-classnameEqual">{"]"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        
                                        </div>

                                    </div>

                                    <br/>
                                    <div>- This is the basic look of the <strong>useEffect</strong>. </div>
                                    <div>- there are some items in the array <strong>[closePopup, setClosePopup]</strong> which is called as dependencies. </div>
                                    <div>- if there is any change happened to the dependencies then the Hook will listen and runs the function changes.</div>
                                    <div>- When the component unmounts, the Hook will run the function that we return from the inside.</div>

                                </p>
                            </li>

                            <br/>
                            <li>
                                <p>
                                <div>
                                <strong></strong>
                                Now we need to declare a new state variable <strong>closePopup</strong>, and add those variables in the useEffect dependencies.
                                so that, if any change occured to the dependencies the Hook will runs the function changes.
                                <div>Along with that create a new function <strong>handleWindowClick</strong> in the useEffect, that will make the closePopup variable as <strong>false</strong>.</div>
                                </div>
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData3}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-classPink">{" export "}</c>
                                            <c className="codeblock-classPink">{" default "}</c>                                    
                                            <c className="codeblock-tagvalue">{" function "}</c>
                                            <c className="codeblock-setconst">{" PopupOperations "}</c>
                                            <c className="codeblock-classnameEqual">{" () "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <c>&emsp;&emsp;</c>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{"const "}</c>
                                            <c className="codeblock-classnameEqual">{" [ "}</c>
                                            <c className="codeblock-tagvalue2">{" closePopup"}</c>
                                            <c className="codeblock-classnameEqual">{", "}</c>
                                            <c className="codeblock-setconst">{" setClosePopup "}</c>
                                            <c className="codeblock-classnameEqual">{" ] "}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-setconst">{" useState"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{"false"}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                        </code>
                                        </div>

                                        <br/>
                                        <div>
                                        <c>&emsp;&emsp;</c>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-setconst">{"useEffect "}</c>
                                            <c className="codeblock-classnameEqual">{" (() "}</c>
                                            <c className="codeblock-tagvalue">{"=>"}</c>
                                            <c className="codeblock-classnameEqual">{" { "}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-tagvalue">{"const "}</c>
                                            <c className="codeblock-setconst">{"handleWindowClick"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classnameEqual">{" () "}</c>
                                            <c className="codeblock-tagvalue">{"=> "}</c>
                                            <c className="codeblock-setconst">{"setClosePopup"}</c>
                                            <c className="codeblock-classnameEqual">{" ("}</c>
                                            <c className="codeblock-tagvalue">{"false"}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-classnameEqual">{", "}</c>
                                            <c className="codeblock-classnameEqual">{"["}</c>
                                            <c className="codeblock-tagvalue2">{" closePopup"}</c>
                                            <c className="codeblock-classnameEqual">{", "}</c>
                                            <c className="codeblock-setconst">{" setClosePopup "}</c>
                                            <c className="codeblock-classnameEqual">{"]"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c></c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>
                                        
                                        </div>

                                    </div>

                                    <br/>

                                </p>
                            </li>


                            <br/>
                            <li>
                                <p>
                                <div>
                                <strong>Finally </strong> 
                                we need to add if and else condition, for adding and removing the <strong>Event Listeners</strong>. 
                                <div>- if the variable <strong>closePopup</strong> is <strong>true</strong> then we need to call <strong>window.addEventListener('click', handleWindowClick)</strong></div>
                                <div>- else the variable <strong>closePopup</strong> is <strong>false</strong> then we need to call <strong>window.removeEventListener('click', handleWindowClick)</strong></div>
                                <div>- this will add the event listener whenever we click the popUp and remove whenever the popUp is closed.</div>
                                <div>- Along with these we need to add the return to remove the eventLister after a component unmount.</div>
                                </div>
                                <br/>   
                                <div className="blog-page-codeblock">
                                        <CopyToClipboard text={cpbrdData4}>
                                            <i className="far fa-copy codeblock-copy" title="copy" onClick={diffToast}/>
                                        </CopyToClipboard>
                                        <div className="blogpage-codeblock-inner">

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-classPink">{" export "}</c>
                                            <c className="codeblock-classPink">{" default "}</c>                                    
                                            <c className="codeblock-tagvalue">{" function "}</c>
                                            <c className="codeblock-setconst">{" PopupOperations "}</c>
                                            <c className="codeblock-classnameEqual">{" () "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <c>&emsp;&emsp;</c>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-tagvalue">{"const "}</c>
                                            <c className="codeblock-classnameEqual">{" [ "}</c>
                                            <c className="codeblock-tagvalue2">{" closePopup"}</c>
                                            <c className="codeblock-classnameEqual">{", "}</c>
                                            <c className="codeblock-setconst">{" setClosePopup "}</c>
                                            <c className="codeblock-classnameEqual">{" ] "}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-setconst">{" useState"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue">{"false"}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                        </code>
                                        </div>

                                        <br/>
                                        <div>
                                        <c>&emsp;&emsp;</c>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                            <c className="codeblock-setconst">{"useEffect "}</c>
                                            <c className="codeblock-classnameEqual">{" (() "}</c>
                                            <c className="codeblock-tagvalue">{"=>"}</c>
                                            <c className="codeblock-classnameEqual">{" { "}</c>

                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-tagvalue">{"const "}</c>
                                            <c className="codeblock-setconst">{"handleWindowClick"}</c>
                                            <c className="codeblock-classnameEqual">{" = "}</c>
                                            <c className="codeblock-classnameEqual">{" () "}</c>
                                            <c className="codeblock-tagvalue">{"=> "}</c>
                                            <c className="codeblock-setconst">{"setClosePopup"}</c>
                                            <c className="codeblock-classnameEqual">{" ("}</c>
                                            <c className="codeblock-tagvalue">{"false"}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>
                                        
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classPink">{"if "}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-tagvalue2">{"closePopup"}</c>
                                            <c className="codeblock-classnameEqual">{") "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classname2">{"window"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-setconst">{"addEventListener"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classnamevalue">{"'click' "}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{"handleWindowClick"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-classPink">{" else "}</c>
                                            <c className="codeblock-classnameEqual">{"{"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classname2">{"window"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-setconst">{"removeEventListener"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classnamevalue">{"'click' "}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{"handleWindowClick"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                        </code>
                                        </div>

                                        {/* return () => window.removeEventListener('click', handleWindowClick); */}

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;&emsp;&emsp;</c>
                                            <c className="codeblock-classPink">{"return "}</c>
                                            <c className="codeblock-classnameEqual">{"()"}</c>
                                            <c className="codeblock-tagvalue">{" => "}</c>
                                            <c className="codeblock-classname2">{"window"}</c>
                                            <c className="codeblock-classnameEqual">{"."}</c>
                                            <c className="codeblock-setconst">{"removeEventListener"}</c>
                                            <c className="codeblock-classnameEqual">{"("}</c>
                                            <c className="codeblock-classnamevalue">{"'click' "}</c>
                                            <c className="codeblock-classnameEqual">{","}</c>
                                            <c className="codeblock-setconst">{"handleWindowClick"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>

                                        <br/>
                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c>&emsp;&emsp;</c>
                                            <c className="codeblock-classnameEqual">{"}"}</c>
                                            <c className="codeblock-classnameEqual">{", "}</c>
                                            <c className="codeblock-classnameEqual">{"["}</c>
                                            <c className="codeblock-tagvalue2">{" closePopup"}</c>
                                            <c className="codeblock-classnameEqual">{", "}</c>
                                            <c className="codeblock-setconst">{" setClosePopup "}</c>
                                            <c className="codeblock-classnameEqual">{"]"}</c>
                                            <c className="codeblock-classnameEqual">{")"}</c>
                                        </code>
                                        </div>

                                        <div>
                                        <code classname="codeblock" style={{textAlign:"right"}}> 
                                        <c></c>
                                            <c className="codeblock-classnameEqual">{" }"}</c>
                                            <c className="codeblock-classnameEqual">{";"}</c>
                                        </code>
                                        </div>



{/* const filterTopic = (event) => {
        setBlogListCount(BlogPages.length)
       // alert(event.currentTarget.dataset.div_name);
       const selectTopic = event.currentTarget.dataset.div_name; */}




                                        
                                        </div>

                                    </div>

                                    <br/>

                                </p>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>

            <div>
                <div className="blog-topics-div">
                        <div className="blog-topics">
                            <div className="title">
                                Related Topics
                            </div>
                            {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{

                            return(
                                <div className="blog-related-topic-value">   
                                <NavLink className="nav-link" to={items.link} exact>
                                    <div className="blog-all-topics-name">
                                        {items.name}
                                    </div>       
                                </NavLink>
                               </div>
                               )
                            })}

                            {/* <div className=" blog-topic-value">
                                <i className="fab fa-react"/>React
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-java"/>Java
                            </div>
                            <div className=" blog-topic-value">
                                <i className="fab fa-js-square"/>JavaScript
                            </div> */}
                        </div>
                    </div>

            </div>

            <div>
                    {/* <!-- side-vertical --> */}
                    <ins class="adsbygoogle"
                         style={{display:'block'}}
                        data-ad-client="ca-pub-8123049304648947"
                        data-ad-slot="5905496439"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                        
                    </ins>
            </div>


        </div>


        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>


        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href="https://web.whatsapp.com/send?text=https://interreview.com" target="_blank" rel="noreferrer">
                        <span className="fab fa-whatsapp share-invert"/>
                        </a>

                        <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site http://www.website.com.&amp;to=interreview@gmail.com"
   title="Share by Email">
                               <span className="fas fa-envelope share-invert"/>
                        </a>

                        {/* <span className = "fab fa-instagram share-invert"/> */}


                        <a rel="noreferrer" href="https://www.facebook.com/sharer.php?u=https://www.interreview.com/" title="fb" target="_blank">                        
                            <span className = "fab fa-facebook share-invert"/> 
                        </a>
                        <a rel="noreferrer" href="https://twitter.com/intent/tweet?text=Interreview !&amp;url=https://www.interreview.com/&amp;hashtags" title="tw" target="_blank"> 
                            <span className = "fab fa-twitter share-invert"/> 
                        </a>
                        {/* <span className = "fab fa-snapchat share-invert"></span> */}
                        <a rel="noreferrer" href="https://telegram.me/share/url?url=https://www.interreview.com/ &amp;text=Interreview" title="tw" target="_blank"> 
                        <span className = "fab fa-telegram share-invert"></span>                
                        </a>
                    </div>
                    <h ref={myRef} />
                    <br/>
                    <hr/>
                    <br/>
                    <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on {relatedTopics}</div>
                <hr/>
                {BlogPages.filter(items => items.tag === relatedTopics).map((items)=>{
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                })}

            </div>





                    </div>
                </div>

                <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <MiniFooter></MiniFooter>
        </>
    )

}

export default WindowEventsInReact;