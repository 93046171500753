import { initializeApp } from "@firebase/app";
import { getFirestore } from '@firebase/firestore'
import {getDatabase} from 'firebase/database'


const firebaseConfig = {
    apiKey: "AIzaSyAOsRzAoN_bCo042CIaXviCMAv7elZ-Pwk",
    authDomain: "interreview-a517a.firebaseapp.com",
    projectId: "interreview-a517a",
    storageBucket: "interreview-a517a.appspot.com",
    messagingSenderId: "612340592097",
    appId: "1:612340592097:web:37204d5a939e2644f336a0",
    measurementId: "G-JPPPNNY54P"
  };

  

  export const app = initializeApp(firebaseConfig);

  export const database = getDatabase(app);


  export const db = getFirestore(app);
//  app.firestore()

  // export default app.database().ref();