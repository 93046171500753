import React, {useState} from 'react';
//import FirebaseCURDOperations from './database/FirebaseCURDOperations';
import Navbar from './Navbar';
import "./Pages.css"

import { collection, addDoc } from '@firebase/firestore';
import {db} from './database/firebase-config'
import MiniFooter from './MiniFooter';

import { Helmet } from 'react-helmet';

import {ToastContainer} from 'react-toastify';
//import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'



const questionslist = [

];

export const contributionList = {}

const interviewQuestionsList=[]
const interviewQuestionsListMap=[]

function Contributions (){

    Navbar("contributions");



    const[ questionCount, setQuestionCount] = useState(0);
//    const[ questions, setQuestions] = useState("");

    //const [contributionDetails, setContributionDetails] = useState({});

    //Variables 
    const [companyName, setCompanyName] = useState("");
    const [jobRole, setJobRole] = useState("");
    const [rating, setRating] = useState(0);
    const [applicationProcess, setApplicationProcess] = useState("");
    const [interviewProcess, setInterviewProcess] = useState("");
    const [date, setDate] = useState("");
    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const [suggestions, setSuggestions] = useState("");

    const [checkCollegeName, setCheckCollegeName] = useState(false);
    const [collegeName, setCollegeName] = useState("");
    const [interviewDrive, setInterviewDrive] = useState("");
    
    const interviewExperienceCollectionRef = collection(db, "Interview-experience");



    const addInterviewExperience = async(e) => {
        e.preventDefault();
        //alert("checkinf");
        //alert("event : "+e.target[0].value);
        for(var i=12; i< e.target.length-2; i++){
            //alert("target "+ i+" : "+e.target[i].value + " checking : "+(e.target[i].value !== "+"));
            console.log("target "+ i+" : "+e.target[i].value);
            if ((e.target[i].value !== "+") && (e.target[i].value !== "") && 
            (e.target[i].value !== null) && (e.target[i].value !== " ") ){
                //alert("inner target " + e.target[i].value);
                    interviewQuestionsList.push( e.target[i].value);
                    var interviewquestion = {}
                    interviewquestion[e.target[i].value] = "null";
                    interviewQuestionsListMap.push(interviewquestion)     
                }  
        }

        // await fetch('https://interreview-a517a-default-rtdb.asia-southeast1.firebasedatabase.app/database.json',
        //         {
        //             method:"POST",
        //             headers : {
        //                 "Content-type":"application/json",
        //             },
        //             body : JSON.stringify({
        //                 companyName,
        //                 jobRole
        //             }),
        //         });       

        //alert("database : "+interviewExperienceCollectionRef)

        await addDoc(interviewExperienceCollectionRef, {companyName:companyName, 
            jobRole : jobRole, 
            date : date, 
            rating : Number(rating),
            interviewProcess : interviewProcess, 
            applicationProcess : applicationProcess, 
            interviewQuestions : interviewQuestionsList,
            interviewquestions : interviewQuestionsListMap,
            suggestions : suggestions, 
            approval : Number(0),
            collegeName : collegeName, 
            interviewDrive : interviewDrive } )

            alert("Thanks for you Contribution! \n");
            //diffToast();

            setCompanyName("");
            setJobRole("");
            setRating(0)
            setApplicationProcess("");
            setInterviewProcess("");
            setDate("");
            setInterviewQuestions(interviewQuestions);
            setInterviewQuestions([]);
            setSuggestions("");
            window.location.reload();

    }

    // const handleSubmit = (event) => {

    //     alert("length : "+ event.target.length);
    //     for(var i=10; i< event.target.length-2; i++){
    //         interviewQuestionsList.push( event.target[i].value);            
    //     }

    //     const data = {'companyName':companyName,
    //     'jobRole':jobRole,
    //     "rating" : rating,
    //     "applicationProcess" : applicationProcess,
    //     "date" : date,
    //     "interviewProcess" : interviewProcess,
    //     "interviewQuestions" : interviewQuestionsList }
        
    //     //FirebaseCURDOperations(data);
    //     setContributionDetails(data);
    //     FirebaseCURDOperations(contributionDetails)

    //     console.log('SubmitForm data : '
    //     + " companyName : "+companyName 
    //     +" jobRole : "+ jobRole
    //     +" rating : "+rating
    //     +" applicationProcess : "+ applicationProcess
    //     +" interviewProcess : "+interviewProcess
    //     +" data : "+date
    //     +" interviewQuestions : "+interviewQuestions);

        

    //     alert("submitted" 
    //     + " companyName : "+companyName 
    //     +" jobRole : "+ jobRole
    //     +" rating : "+rating
    //     +" applicationProcess : "+ applicationProcess
    //     +" interviewProcess : "+interviewProcess
    //     +" data : "+date
    //     +" interviewQuestions : "+interviewQuestions
    //     +" taret length : "+event.target.length
    //     +" target 1 : "+event.target[0].value
    //     +" target 2 : "+event.target[1].value
    //     +" target 3 : "+event.target[2].value
    //     +" target 4 : "+event.target[3].value
    //     +" target 5 : "+event.target[4].value
    //     +" target 6 : "+event.target[5].value
    //     +" target 7 : "+event.target[6].value
    //     +" target 8 : "+event.target[7].value
    //     +" target 9 : "+event.target[8].value
    //     +" target 10 : "+event.target[9].value
    //     +" target 11 : "+event.target[10].value
    //     +" target 12 : "+event.target[11].value
    //     +" questionCount : "+questionCount);


    // }

    // const onClickQuestionCount = (e) => {
    //     //setQuestionCount(questionCount + 1);
    //     // var data = {}
    //     // data.text = e.target.value;
    //     // data.key = questionCount + 1;
    //     // questionslist.push(data);
    //     console.log('questionCount'+questionCount);
    //     console.log('questionList : '+questionslist.length);
    //     console.log('questionKey : '+e.target.key);
    //     console.log('questionvalue : '+e.target.value);
    //   };

    const onClickAddQuestion = () => {
      setQuestionCount(questionCount + 1);
      var data = {}
      data.text = "";
      data.key = questionCount + 1
      questionslist.push(data);
      console.log('questionCount'+questionCount);
      //console.log('questionList : '+questions);
    };

    const display = questionslist.map((items, index) => {
        return(
            <div>
                <div className="input-box">
                    <span className="details">
                    Interview Questions {items.key}</span><br/>
                    <textarea type="text" placeholder="Enter Your Interview Questions ..." className={items.key} />            
                </div>
            </div>
            )
    });    

    const collegeNameSelect = () =>{
        setCheckCollegeName(true);
    }   

    const collegeNameDeselect = () => {
        setCheckCollegeName(false);
    }

    // const diffToast = () => {
    //     toast("Thank you for your contribution! ", {
    //         position:"top-center",
    //         autoClose : 8000            
    //     });
    //     // toast.success("success", {
    //     //     position:"top-right",            
    //     // })

    //     // toast.error("error", {
    //     //     position:"bottom-center",            
    //     // })
    //     // toast.warn("warn", {
    //     //     position:"top-left",            
    //     // })
    //     // toast.info("update", {
    //     //     position:"bottom-right",            
    //     // })
    //     // toast.dark("dark", {
    //     //     position:"bottom-left",            
    //     // })
    // }


        return(

            <div>

                <Helmet>
                    <title>Your Contributions</title>
                    <meta name="description" content="In this page, User can share their recent interview experience to help others. " />
                </Helmet>

            <div className="container parent flex-direction ">
            <div className="container contribution-container div1">

            <h1><div className= "title">Add your recent Interview Experience</div></h1>
            <hr/>
            <br/>
            <form method="POST" onSubmit={addInterviewExperience}>
                <div className="user-details">
                    <div className="input-box">
                        <span className="details">
                            Company Name</span><br/>
                        <input 
                            type="text" 
                            placeholder="Enter Company Name" 
                            onChange={(event) => {setCompanyName(event.target.value)}}
                            value = {companyName}
                        />

                    </div>

                    <div className="input-box">
                        <span className="details">
                        Title of job</span><br/>
                        <input type="text" placeholder="Enter Job Title" onChange={(event) => {setJobRole(event.target.value)}}/>
                        
                    </div>

                    <div className="input-box">
                        <span className="details">
                            Rate your overall interview</span><br/>
                            <div className="rate" >
                                <input type="radio" id="star5" name="rate" value="5" onChange={(event) => {setRating(event.target.value)}}/>
                                <label htmlFor="star5" title="text">5 stars</label>
                                <input type="radio" id="star4" name="rate" value="4" onChange={(event) => {setRating(event.target.value)}}/>
                                <label htmlFor="star4" title="text">4 stars</label>
                                <input type="radio" id="star3" name="rate" value="3" onChange={(event) => {setRating(event.target.value)}}/>
                                <label htmlFor="star3" title="text">3 stars</label>
                                <input type="radio" id="star2" name="rate" value="2" onChange={(event) => {setRating(event.target.value)}}/>
                                <label htmlFor="star2" title="text">2 stars</label>
                                <input type="radio" id="star1" name="rate" value="1" onChange={(event) => {setRating(event.target.value)}}/>
                                <label htmlFor="star1" title="text">1 star</label>
                            </div>                        
                    </div>

                    <div className="radio-input">
                        <span className="details">
                            How do you get this interview
                        </span><br/>
                        <div className="radioValue">
                        <input type="radio" name="campus" id="campus1" value="onCampus" onClick={collegeNameSelect} onChange={(event) => {setInterviewDrive(event.target.value)}}/>
                        &nbsp;<label htmlFor="campus1" title="text">On-Campus</label><br/>
                        <input type="radio" name="campus" id="campus2" value="offCampus" onClick={collegeNameDeselect} onChange={(event) => {setInterviewDrive(event.target.value)}}/>
                        &nbsp;<label htmlFor="campus2">Off-Campus</label>
                        </div>
                    </div>

                    {checkCollegeName ? 
                    <div>
                    <div className="input-box">
                        <span className="details">
                        Enter College Name </span><br/>
                        <input 
                                type="text" 
                                placeholder="Enter College Name" 
                                onChange={(event) => {setCollegeName(event.target.value)}}
                            />
                    </div>
                </div>
                    : null}

                    <div className="input-box">
                        <span className="details" >
                            Date of Interview</span><br/>
                        <input type="date" onChange={(event) => {setDate(event.target.value)}}/>
                        
                    </div>

                    <div className="input-box">
                        <span className="details">
                            Application Process</span><br/>
                        <textarea type="text" placeholder="Your Application Process ..." onChange={(event) => {setApplicationProcess(event.target.value)}}/>
                        
                    </div>


                    <div className="input-box">
                        <span className="details">
                            Interview Process</span><br/>
                        <textarea type="text" placeholder="Enter Your Interivew Process ..." onChange={(event) => {setInterviewProcess(event.target.value)}}/>
                        
                    </div>

                    <div className="input-box">
                        <span className="details">
                            Interview Questions </span><br/>
                        <textarea type="text" placeholder="Enter Your Interview Questions ..." onChange={(event) => {setInterviewQuestions(event.target.value)}}/>
                        
                    </div>

                    {display}

                    <div className="input-box">
                        <span className="details">
                            Add more Interview Questions.</span><br/>
                            <input type="button" value="+" onClick={onClickAddQuestion}/>                    
                    </div>


                    <div className="input-box">
                        <span className="details">
                            Suggestions </span><br/>
                        <textarea type="text" placeholder="Enter Your Suggestions to Viewers ..." onChange={(event) => {setSuggestions(event.target.value)}}/>
                        
                    </div>

                </div>
                <div className="button">
                        <input type="submit" value="Submit"/>

                <ToastContainer/>                        
                    </div>
                
            </form>
            
            </div>

            <div className="contribution-note-container div2">

            <div className="title">Keep it Real</div>
            <hr/>
            <ul>
                <li>Thank you for your contribution.</li>
                {/* <li>Your opinion will help others make decisions about jobs and companies.</li> */}
                <br/>
                <li>Your contribution will help someone to lead their dream jobs and companies.</li>
                <br/>
                <li>Please don't post any confidential informations, Aggressive language.</li>
                <br/>
                <li>Thank you for contributing and making <strong>INTERREVIEW</strong> as one of the trusted place to find an interview reviews and for learning.</li>
            </ul>

            </div>


        </div>
        <MiniFooter/>
        </div>
        )
}

export default Contributions;
