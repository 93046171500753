import React from 'react'
import '../Pages.css'
import Footerpage from "../Footerpage";
import Navbar from '../Navbar';

import {Helmet} from "react-helmet";


function ErrorPage(){
    Navbar("home");
        return(
        <div>
            <Helmet>
                <title>Error Page</title>
                <meta name="description" content="Interview is a platform, where users can view the INTERVIEW REVIEWS posted by the users, some interview questions and blogs to learn." />
            </Helmet>
        <div className="container about-page">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <hr/>
            <h1 className="center-align">*- - -* <i class="fas fa-exclamation"/> PAGE NOT FOUND *- - -*</h1>
            <hr/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

    


        </div>

        <div>
            {/* <!-- horizontal --> */}
            <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8123049304648947"
                data-ad-slot="1088284024"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>

            <footer><Footerpage/></footer>
        </div>)
}

export default ErrorPage;