import React, { useEffect, useState } from 'react'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import Footerpage from './Footerpage'
import "./Pages.css"
//import {interviewExperienceData} from './database/FirebaseCURDOperations'
import { collection, getDocs} from 'firebase/firestore';
//import { collection, getDocs, addDoc } from 'firebase/firestore';
import {db} from './database/firebase-config'
//import $ from 'jquery';
import Navbar from './Navbar'
import PopUp from './PopUp';
import {EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton} from "react-share"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom'

import { Helmet } from 'react-helmet'



function Home(){

    console.log("homePage ");

    Navbar("home");

   // UseGATracker();

    const componentDidMount = () => {
        //window.scrollTo(0, 0);
//        window.onclick = ({togglePop});
    }


    const [contributionList, setContributionList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const searchValue = new Set();


    //pagination code
    const contributionCount = searchList.length
    

    const[ isActiveLeft, setActiveLeft] = useState(false);
    const[ isActiveRight, setActiveRight] = useState(true);

    const [pageNumber, setPageNumber] = useState(0);
    const perPage = 5;
    const pageVisited = pageNumber * perPage;
    

    const interviewExperienceCollectionRef = collection(db, "Interview-experience");

    const [sidebar, setSidebar] = useState(false);

    const history = useHistory();

    useEffect(() => {

        const getInterviewExperience = async () => {
            const data = await getDocs(interviewExperienceCollectionRef);
            console.log(data);    
            // setContributionList(data.docs.map((doc) => ({ ...doc.data(), id : doc.id})));
            // setSearchList(data.docs.map((doc) => ({ ...doc.data(), id : doc.id})));
            const approveListTemp = []
            data.docs.filter(doc => doc.data().approval === 1).map((doc) => {
                //alert("checking 11" + doc.data().approval)
                //if (doc.data().approval === 1) {
                   approveListTemp.push( { ...doc.data(), id : doc.id})                   
                //} 
                return null;
            });
            setSearchList(approveListTemp);
            setContributionList(approveListTemp);
        };
    
        getInterviewExperience();

        //componentDidMount();

        return () => {
            // this now gets called when the component unmounts
          };

    }, [])


    
    useEffect(()=> {


            const handleWindowClick = () => {
                //alert("onWindow ");
                setcheckSidebar(prevState => prevState.map((item, idx) => false));                
                setSidebar(false)}

            if(sidebar) {
              window.addEventListener('click', handleWindowClick);
            } else {
             window.removeEventListener('click', handleWindowClick)
            }
            return () => window.removeEventListener('click', handleWindowClick);

            // if(sidebar) {
            //     window.addEventListener('click', handleWindowClick("add"));

            //   } else {
            //     window.removeEventListener('click', handleWindowClick("remove"))

            //   }

    
    }, [sidebar, setSidebar]);


    //share operations open
    const shareUrl = window.location.href+"home/";


    const [checkSidebar, setcheckSidebar] = useState([false,false,false,false,false]);

    // const onShareReview = (event) => {
    //     const selectTopic = event.currentTarget.dataset.div_name;
    //     alert(selectTopic);
    // }


    

    const [state, setState] = useState(false);
    const [interviewId, setInterviewId]= useState("");

     const togglePop = (event) => {

        const selectTopic = event.currentTarget.dataset.div_name;
        setState(!state);
        setInterviewId(selectTopic);
      };

    //   const handleWindowClick = () => {
            

    //     //window.removeEventListener('click', handleWindowClick)
    //     alert("empty 123");
    //     setcheckSidebar(prevState => prevState.map((item, idx) => false));                
    //     setSidebar(false);
    // }


    //RefreshPage
    function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
        console.log('page to reload')
    }

    
    // const handleWindowClick = (value) => {
            
    //     if(value === "add"){
    //         //window.addEventListener('click', handleWindowClick(value))
    //         alert("empty : " + value);
    //     } else {
    //         alert("emptyremove : " + value);
    //         //window.removeEventListener('click', handleWindowClick)
    //         setcheckSidebar(prevState => prevState.map((item, idx) => false));                
    //         setSidebar(false);
    //     }

    // }

      const showSidebar = (index) => {
        //alert(" shareButton")
        //alert("before sidebar : "+sidebar)
        setSidebar(!sidebar);
        //alert("before : "+checkSidebar)

        //let checkvalue = false; 
        checkSidebar.forEach((item, idx) => {

            if (idx === index){
                //alert("items : "+item);
                setSidebar(!item);
                //checkvalue = !item;
            }
        })

        setcheckSidebar(prevState => prevState.map((item, idx) => idx === index ? !item : false))
        // alert("after : "+checkSidebar)
        // alert("after sidebar : "+sidebar)
        // alert("checkvalue : "+checkvalue)
        // if (checkvalue){
        //     window.addEventListener('click', handleWindowClick("add"));
        // } else {
        //     window.removeEventListener('click', handleWindowClick("remove"));
        // }


        //checkSidebar[index] = !checkSidebar[index];
    }


    //share close
    

    //pagination Variables

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };


    //const [pageValue, setPageValue] = useState(pageVisited+perPage);


    const onChangeRight = () => {
        //alert("right "+pageNumber+" "+contributionCount + " " +pageVisited +" "+perPage + " "+(pageNumber+1)*perPage)
        if (contributionCount > pageVisited+perPage){
            scrollToTop();
            setPageNumber(pageNumber+1);
            //setPageValue(pageVisited+perPage)
            setActiveLeft(true);
            if (contributionCount > (pageNumber+2)*perPage){
                setActiveRight(true);
                //setPageValue(contributionCount);
            } else {
                setActiveRight(false);
            }
        }
    };

    const onChangeLeft = () => {
        //alert("onChangeLeft" + pageNumber)
        setActiveRight(true);
        if (pageVisited > 0){
            //setScrollOnNextPrev(true);
            scrollToTop();
            //setPageValue(pageVisited+perPage)
            setPageNumber(pageNumber-1);
            if (pageNumber <= 1){
                //setActiveRight(true);
                setActiveLeft(false);
            } 

        }
    };
    console.log("interview 1234 ")

    const displayList = searchList.slice(pageVisited, pageVisited + perPage).map((interview, index) => {
        console.log("interview : "+interview.id)
        //alert("displayList")

        var questionsList = interview.interviewQuestions
        var questionsMap = [
            {"animal": "dog"}
        ]
        if (interview.interviewquestions != null){
            questionsMap = interview.interviewquestions
            //alert(questionsMap)
        }   
        var out = "";
        if (questionsList != null){
            for (var i = 0; i < questionsList.length; i++){
                out  = out  + "- " +questionsList[i]+" \n"
            }
        }
        //alert(interview.rating);
        var rating1 = "fa fa-star ";
        var rating2 = "fa fa-star ";
        var rating3 = "fa fa-star ";
        var rating4 = "fa fa-star ";
        var rating5 = "fa fa-star ";

        if (parseInt(interview.rating) !== 0) {
            switch(parseInt(interview.rating)){
                case 5 : 
                rating5 = rating5 + "checked";
                rating4 = rating4 + "checked";
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 4 : 
                rating4 = rating4 + "checked";
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 3 : 
                rating3 = rating3 + "checked";
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 2 : 
                rating2 = rating2 + "checked";
                rating1 = rating1 + "checked";
                break;
                case 1 : 
                rating1 = rating1 + "checked";
                break;
                default: break;
            }

        }

        const hyperTag = (key, value) => {
            var tag = <NavLink className="iq-links" to={value} exact>

                    {key}
            </NavLink>
            if (value == null || value.toLowerCase() === "null"){
                tag = key;
            } 
            return tag;
        }

        const displayQuestionList = questionsList.filter(eachQuesList => eachQuesList !== "" && eachQuesList !== "+" && eachQuesList !== " ").map((eachQuesList) => {
            //if (eachQuesList !== "" && eachQuesList !== "+" && eachQuesList !== " ")
            return(
                <div>- {eachQuesList}</div>
            )
        }
        )

        const displayQuestionMap = questionsMap.map((value, index) => {
            //if (eachQuesList !== "" && eachQuesList !== "+" && eachQuesList !== " ")
            //{value == null ? key : hyperTag(key, value)}
            return(
                <div>- {Object.values(value)[0] == null ? Object.keys(value)[0] : hyperTag(Object.keys(value)[0], Object.values(value)[0])}</div>
            )
        }
        )

        //console.log("interview : "+interview.interviewQuestions)
                        return (
                            <div>
                                    <div className="inteview-view">
                                        
                                        <div className="company-name-div">
                                            <div className="company-Name">
                                                {interview.companyName}
                                            </div>
                                            <div className="comp-name-share">

                                                <div class="dropdown" >
                                                    <div onClick={() => showSidebar(index)} > <i className="fas fa-share-alt"/>Share</div>
                                                    {checkSidebar[index] ?
                                                        <div class="dropdown-content">

                                                            <div className="eachShareItems"><EmailShareButton url={shareUrl+interview.id}><span className="fas fa-envelope lightShareItems" onClick={() => showSidebar(index)}> Email</span> </EmailShareButton></div>
                                                            <div className="eachShareItems"><FacebookShareButton url={shareUrl+interview.id}> <span className="fab fa-facebook lightShareItems" onClick={() => showSidebar(index)}> Facebook </span></FacebookShareButton></div>
                                                            <div className="eachShareItems"><WhatsappShareButton url={shareUrl+interview.id}><span className="fab fa-whatsapp lightShareItems" onClick={() => showSidebar(index)}> Whatsapp </span></WhatsappShareButton></div>
                                                            <div className="eachShareItems"><TwitterShareButton url={shareUrl+interview.id}><span className="fab fa-twitter lightShareItems" onClick={() => showSidebar(index)}> Twitter </span></TwitterShareButton></div>
                                                            <CopyToClipboard text={shareUrl+interview.id}><div className="eachShareItems"><span className="fa fa-clone lightShareItems" onClick={() => showSidebar(index)}> Copy Link </span></div></CopyToClipboard>
                                                        </div>
                                                    : null}
                                                </div>

                                                {/* <div className="home-share"> 
                                                     <div onClick={togglePop} data-div_name={interview.id}>
                                                         <i className="fas fa-share-alt"/>Share
                                                    </div> 
                                                </div> */}

                                            </div>
                                        </div>
                                        <hr/>

                                        <div className="watermarked-home">
                                        <img src="/logo.png" alt="intereview_logo"/>
                                        <div className="content">
                                        <div className="company-role-div">
                                            <div className="company-role">{interview.jobRole}</div>
                                            <div >Date : {interview.date}</div>
                                        </div>
                                        <div>
                                            <div className="inteview-title">rating</div>

                                            <span className={rating1}></span>
                                            <span className={rating2}></span>
                                            <span className={rating3}></span>
                                            <span className={rating4}></span>
                                            <span className={rating5}></span>
                                        </div>
                                        <br/>
                                        <div className="inteview-title">Application Process</div>
                                        <div> - {interview.applicationProcess}</div>
                                        <br/>
                                        <div className="inteview-title">Interivew Process</div>
                                        <div> - {interview.interviewProcess} </div>
                                        <br/>
                                        <div className="inteview-title">Inteview Questions</div>
                                        {displayQuestionMap}
                                        {/* {(() => {
                                                    if (questionsList != null){
                                                        var out = "";
                                                        for (var i = 0; i < questionsList.length; i++){

                                                            out  = out  + "- " +questionsList[i]+" ,"
                                                        }
                                                        return (
                                                            <div>{out}</div>
                                                          )

                                                    }

                                        })()} */}
                                        {/* //console.log(" interviewQuestions : "+interview.inerviewQuestions)
                                            interview.interviewQuestions.map((interviewDetails)=> {
                                            console.log("interview : "+interviewDetails)
                                            return(
                                                <div>
                                                    <h1>{interviewDetails}</h1>
                                                </div>
                                            )
                                        })
                                        }  */}
                                        {/* <div> - {interview.inerviewQuestions}</div> */}
                                        <br/>
                                        <div className="inteview-title">Suggestions</div>
                                        <div> - {interview.suggestions}</div>
                                        </div>
                                        <br/>
                                        </div>
                
                                    </div>
                                </div>
                        )

                        })

    // const display =  interviewExperienceData.map((interviewDetails) => {
    //     alert("interviewQuestions :" +interviewDetails)
    //                     console.log("interviewDetails : "+interviewDetails)
    //                     return(
    //                     interviewDetails.map((interview)=> {
    //                     console.log("interview : "+interview.companyName)
    //                     return(
    //                         <div>
    //                             <h1>{interview.companyName}</h1>
    //                         </div>
    //                     );
    //                 })
    //                     )

    //             })


    //Search Result.
        const [searchText, setSearchText] = useState("");
        
        const searchHandler = (event) => {

            event.preventDefault();

            const newSearchList = [];
            contributionList.forEach((interview) => {
                if(interview.companyName.toLowerCase() === searchText.toLowerCase()){
                    newSearchList.push(interview);
                }
            });
            //alert(newSearchList.length)
            if (newSearchList.length > 0){
                setSearchList(newSearchList);
                history.push('/'+searchText);
                refreshPage();
            } else {
                setSearchList(contributionList);
                alert("No Search result Found ")
            }

        }
    
        const searchListView = contributionList.map((interview, index) => {
            if (!searchValue.has(interview.companyName.toLowerCase())){
                searchValue.add(interview.companyName.toLowerCase());                        
            return(                
                <option value={interview.companyName}/>
            )
            }
            return null;
        })
        
        return(
        <div>
            <Helmet>
                <title>InteReviews</title>
                <meta name="description" content=" In intereviews, we can view the recent interview experiences added by users." />
            </Helmet>
            <div>
                <div>
                    <div className="container home-search-content">

                    <div>
                        {state && <PopUp
                        content={interviewId}
                        handleClose={togglePop}
                        />}
                    </div>


                        <div>
                            <form onSubmit={searchHandler}>
                                <input type="text" list="browsers" placeholder="Search Company Names .." className="search-input" onChange={(event) => {setSearchText(event.target.value)}}/>
                                    <datalist id="browsers">
                                    {searchListView}

                                  </datalist>
                                <button type="submit" className="search-button"><i className="fa fa-search"/><j className="search-button-name"> Search</j> </button>

                                <span >  <NavLink className="recent-interview-button" to="/contributions" exact><i className="fas fa-user-plus"></i>Add an Interview</NavLink></span>
                            </form>
                        </div>
                    </div>

                    <div className="container home-content">
                    
                    {/* {display} */}
                    {displayList }
                    {/* checking <FirebaseCURDOperations/> */}
                    {/* <div className="inteview-view">
                        <div className="company-name-div">
                            <div className="company-Name">
                                PhenomPeople
                            </div>
                            <div className="comp-name-share">
                                <div className="fas fa-share-alt"> 
                                    Share 
                                </div>
                                <div className="far fa-thumbs-up like-name"> 
                                    Like
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="company-name-div">
                            <div className="company-Name">Job title</div>
                            <div >Date :</div>
                        </div>
                        <div>
                            <div className="inteview-title">rating</div>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star checked"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                        </div>
                        <br/>
                <div className="inteview-title">Application Process</div>
                <div> - applied through Interview</div>
                <br/>
                <div className="inteview-title">Interivew Process</div>
                <div> - 2 rounds of interview </div>
                <br/>
                <div className="inteview-title">Inteview Questions</div>
                <div> - what is java </div>
                <br/>
                <div className="inteview-title">Suggestions</div>
                <div> - for code pratice check the LeetCode</div>
                <br/>
                
            </div>
            <div className="inteview-view">
                <div className="company-name-div">
                    <div className="company-Name">Company Name</div>
                    <div className="comp-name-share">
                        <div className="fas fa-share-alt"> Share </div>
                        <div className="far fa-thumbs-up like-name"> Like</div>
                    </div>
                </div>
                <hr/>
                <div className="company-name-div">
                    <div className="company-Name">Job title</div>
                    <div>Date :</div>
                </div>
                <div className="inteview-title">rating</div>
                <br/>
                <div className="inteview-title">Application Process</div>
                <div> - applied through Interview</div>
                <br/>
                <div className="inteview-title">Interivew Process</div>
                <div> - 2 rounds of interview </div>
                <br/>
                <div className="inteview-title">Inteview Questions</div>
                <div> - what is java </div>
                <br/>
                <div className="inteview-title">Suggestions</div>
                <div> - for code pratice check the LeetCode</div>
                <br/>
                
            </div> */}
            <hr/>

            <div className="prev-next-view">
                
                <i>{pageVisited+1}-{(pageVisited+perPage) > contributionCount ? contributionCount : pageVisited+perPage} of {contributionCount}</i>
                <i className= {isActiveLeft ? "fas fa-chevron-left prev-btn" : "fas fa-chevron-left prev-btn1 btn-disabled" } onClick={onChangeLeft} ></i>
                <i className= {isActiveRight ? "fas fa-chevron-right next-btn" : "fas fa-chevron-right next-btn1 btn-disabled"} onClick={onChangeRight}></i>
            </div>
            
            <br/>
            </div>
            </div>
            <br/>
            </div>
            <Footerpage/>
        </div>
        
        )
}


export default Home;