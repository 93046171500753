
import React, {useState} from "react";
import "./../Pages.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {ToastContainer, toast} from 'react-toastify';
import Footerpage from "../Footerpage";
import { NavLink } from 'react-router-dom'
import { Tools } from '../Tools/ToolsPage'
//import { JsonFormatter } from 'react-json-formatter'

import { useDropzone } from "react-dropzone";



import ReactJson from 'react-json-view'







function QRCodeScanner(){

 
    
    const [base64data, setBase64Data] = useState("");
    const [base64Split, setBase64Split] = useState("");
    const [base64Html, setBase64Html] = useState("");
    const [base64Css, setBase64Css] = useState("");
    const [checkJson, setCheckJson] = useState(false);
    const [clickjsonData, setClickJsonData] = useState("");
    // const [jsonError, setJsonError] = useState("");

    const [clipboard, setClipBoard] = useState("");
    // const [checkCollapse, setCheckCollapse] = useState(true);

    const [errorMsg, setErrorMsg] = useState("");
    const [CheckErrorMsg, setCheckErrorMsg] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(null);

    var fileInput = React.createRef();
 
    const currentTool = "Image To Base64"
    var cpbrdData1 = "copy";
     

    
    const EncodeUrl = () => {

        let formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        fetchRequest(acceptedFiles[0], formData);
    }



    const diffToast = () => {

        setClipBoard(JSON.stringify(clickjsonData, null, '\t'));

        //alert("");
        toast("Copied to Clipboard ", {
            position:"top-center",
            autoClose : 1000            
        });
    }

    const errorToast = () => {

        //setClipBoard(JSON.stringify(clickjsonData, null, '\t'));

        //alert("");
        toast.error("Couldn't scan QR Code ", {
            position:"top-center",
            autoClose : 1000            
        });
    }


    function fetchRequest(file, formData) {
        //infoText.innerText = "Scanning QR Code...";
        fetch("http://api.qrserver.com/v1/read-qr-code/", {
            method: 'POST', body: formData
        }).then(res => res.json()).then(result => {
            result = result[0].symbol[0].data;
            if(!result) {
                errorToast();
                return;
            }
            setBase64Split(result)
            setCheckJson(true);


        }).catch(() => {
            errorToast();
            //alert();
            //infoText.innerText = "Couldn't scan QR Code";
        });
    }


    const WIFIDetails = base64Split.split(";").map(data => (
        <>
        {data==="" ? "" : 
            <tr><td>&emsp;&emsp;<strong>{data.includes("WIFI") ? data.split(":")[1] : (data.split(":")[0] === "P") ? "Password" : (data.split(":")[0] === "S") ? "Username" : (data.split(":")[0] === "T")? "Encryption" : (data.split(":")[0] === "H") ? "Hidden" : (data.split(":")[0]) }</strong> </td><td>&emsp;:&emsp;</td><td>{data.includes("WIFI") ? data.split(":")[2] :data.split(":")[1]}</td></tr>
        }
        </>
    ))





   const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  
   const files = acceptedFiles.map(file => (
    <>
    <h4>Preview</h4>
    {/* <ul>
     <li key={file.path}>
       {file.path} - {file.size} bytes

       
            
     </li>
     </ul> */}
     <img src={URL.createObjectURL(file)} className="dragPreviewImg" alt={file.path}/>  
     <br/>
     <br/>
     <h5><strong>File Details</strong></h5>
     <table>
         <tr><td>&emsp;&emsp;<strong>FileName</strong> </td><td>&emsp;:&emsp;</td><td>{file.path}</td></tr>
         <tr><td>&emsp;&emsp;<strong>Size</strong> </td><td>&emsp;:&emsp;</td><td>{formatBytes(file.size)}</td></tr>
         <tr><td>&emsp;&emsp;<strong>Type</strong> </td><td>&emsp;:&emsp;</td><td>{file.type}</td></tr>
    </table>

     </>
   ));

   function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

   const base64SplitDisplay = (element) => {
    var file = element;
    var reader = new FileReader();
    reader.onloadend = function() {
      console.log('RESULT', reader.result)

      //setBase64Data(reader.result);
       return (<div> {reader.result.split(",")[1]}</div>)

    }
    reader.readAsDataURL(file);
   }

   function Base64HtmlDisplay(element){

    var file = element;
    var reader = new FileReader();

    reader.readAsDataURL(file)    

    reader.onloadend = function() {
      console.log('RESULT', reader.result)
      //setBase64Data(reader.result);
      alert("html ; "+reader.result);
      var output = document.getElementById('output');
      output.value = reader.result

       return (<div>{"<img123 src = '"+reader.result+"' />"}</div>)

    }


//    alert(" html 123 : "+reader.readAs);

   }


//    const AlexaRank = require('alexa-rank-nodejs').default;

// (async () => {
//     console.log("website Rank 123 : ");
//     var data2 = await AlexaRank.topsite();
//     console.log("website Rank 000 : "+data2);
//     var data = await AlexaRank.siteinfo('www.google.com');

//     console.log("website Rank 345 : "+data);
// })()




function refreshPage() {
    setTimeout(()=>{
        window.location.reload(false);
    }, 0);
    console.log('page to reload')
}



    return(
        
        <>
            <div>
                <div className="json-page-layout">
                    <div className="JSON-title">
                        <h1>QR Code Scanner</h1>
                    </div>
                    <div className="JSON-tagLine">
                        <h4>Free Online QR Code Scanner.</h4>
                    </div>
                </div>
            </div>
            <div className="blogLayout">
                <div className="json-mainpage-layout">
                    <div className="blogLayout">
                        <div className="json-innerPage-layout">

                            {/* <div className="JSON-title">
                            <h1>JSON Formatter</h1>
                            </div> */}
                            <ToastContainer/>

                            <div className="blog-page-content">
                                <div>

                                    {/* <div className="json-output-top"> */}
                                    <div><h5><strong>Select Your Image and Get Details : </strong></h5></div>
                                    {/* </div> */}
                                    {/* <div className="jsonFormat-textarea" >
                                        <textarea type="text" placeholder="Enter your JSON Data here ..." onChange={(event) => {setJsonData(event.target.value)}} />
                                    </div> */}

                            <div className="">
                                <div>
                            <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <div className="drag-area">
                            <div className="dragArea-icon"><i className="fas fa-cloud-upload-alt"></i></div>
                            <p className="dragArea-text">Drag &amp; Drop to Upload File</p>
                            <p className="dragArea-text">Or</p>
                            <p className="dragArea-button">click to select files</p>
                            </div>
                            </div>
                            <br/>
                            <aside>

                                {files}
                            </aside>
                            </section>
                            </div>
                            <div>

                            </div>
                            </div>
                            <hr/>


                                    <div className="text-center json-copyLink convert-button-size1"><button onClick={EncodeUrl}>Get Barcode Details</button>
                                        {/* <PrettyPrintJson data={jsonData } /> */}
                                    </div>
                    
                    {/* <i className="share-copyLink"><button onClick={DecodeUrl}>Decode</button></i> */}
                    
                    {/* <CopyToClipboard text={jsonData}>
                        <i className="share-copyLink" title="copy" ><button onClick={diffToast}>Copy</button></i>
                    </CopyToClipboard> */}



                    {/* <pre>
        <code>
          {JSON.stringify(jsonData, null, 2)}
        </code>
      </pre> */}
        <br/>
      <br/>
      <div></div>
        <div className="json-output-border">
            {checkJson ?
            <div>
                <div>
                <div className="json-output-top">
                    <div >
                        <div>
                            <strong className="jsonblock-title">Barcode Details : </strong>

                            <CopyToClipboard text={base64Split}>
                               <i className = "collapseblock-copy"><button onClick={diffToast}> <i className="far fa-copy" title="copy" />Copy</button></i>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="json-border">
                    {/* {base64SplitDisplay(acceptedFiles[0])} */}
                    {base64Split}
                    <br/>
                    <br/>
                    {base64Split.includes("WIFI") ?
                    <>
                        <h5><strong>Details</strong></h5>
                        <table>
                        {WIFIDetails}
                        </table>
                    </>
                    :""
                    }

                        
                </div>
                </div>

                <br/>

            </div>
            : CheckErrorMsg ? <div className="errorShow">{errorMsg}</div> : ""
          }
        </div>

                    </div>
                    <br/>

                </div>
            </div>

        </div>

        <div className="blogLayout">

<div className="json-innerPage-layout">
    <div className="blog-page-content">
    <div value="About Url Encoder/Decoder"><h5><b className="fas fa-question-circle navbar-titles" > </b>
    <strong> About</strong></h5></div>
        <hr/>
        <div><h5>QR Code Scanner</h5></div>
        <div>Checkout this online QR Code Scanner Tool, Where this Tools to scan the QR Code image that we drop and the get the details of that QR Code. 
               </div>
        <div></div>

    </div>
</div>
</div>

</div>

<div>                

<div className="tools-topics-div">
    <div className="tools-topics">
        <div className="title">
        <b className="fas fa-cog" />
             <strong> Other Tools</strong>
        </div>
        {Tools.filter(items => items.implemented).filter(items => items.name !== currentTool).map((items)=>{

        return(
            <div className="tools-topic-value" >   
            <NavLink className="nav-link" to={items.link} exact onClick={refreshPage}>
                <div className="blog-all-topics-name">
                    {items.name}
                </div>       
            </NavLink>
           </div>
           )
        })}
    </div>
</div>
<div className="tools-topics-div">
    <div>
        {/* <!-- side-vertical --> */}
        <ins class="adsbygoogle"
             style={{display:'block'}}
            data-ad-client="ca-pub-8123049304648947"
            data-ad-slot="5905496439"
            data-ad-format="auto"
            data-full-width-responsive="true">     
        </ins>
    </div>
</div>
</div>

</div>

        <br/>
        <div className="shareLink">
                    <hr/>

                    <div className="share-div">
                        <span className="shareText">Share : </span>
                        <a href={"https://web.whatsapp.com/send?text=URL ENCODER/DECODER "+window.location.href+"\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} target="_blank" rel="noreferrer">
                    <span className="fab fa-whatsapp share-invert"/>
                </a>

                <a href={"mailto:?subject=I wanted you to see this site &body=Check out this site <a href = "+window.location.href+"> URL ENCODER/DECODER</a> \n\n Intereviews is a page to view the interview reviews posted by the users, some blogs in react, java, javascript, dataStructure ..., and some important questions for interview. "} title="Share by Email">
                    <span className="fas fa-envelope share-invert"/>
                </a>

                {/* <span className = "fab fa-instagram share-invert"/> */}
                <a rel="noreferrer" href={"https://www.facebook.com/sharer.php?u= URL ENCODER/DECODER "+window.location.href} title="fb" target="_blank">                        
                    <span className = "fab fa-facebook share-invert"/> 
                </a>
                <a rel="noreferrer" href={"https://twitter.com/intent/tweet?text=Interreview! URL ENCODER/DECODER &url="+window.location.href+"&hashtags"} title="tw" target="_blank"> 
                    <span className = "fab fa-twitter share-invert"/> 
                </a>
                {/* <span className = "fab fa-snapchat share-invert"></span> */}
                <a rel="noreferrer" href={"https://telegram.me/share/url?url="+window.location.href+" &text=URL ENCODER/DECODER "} title="tw" target="_blank"> 
                    <span className = "fab fa-telegram share-invert"></span>                
                </a>
                    </div>
                    <br/>
                    <hr/>
                    {/* <div className="">
                        <div className="blog-all-topics" >
                        <div className="title">All Topics on Java</div>
                <hr/>
                {BlogPages.map((items)=>{
                    if (items.tag === "React"){
                    return(
                     <div className="all-topics-items">   
                    <NavLink className="nav-link" to={items.link} exact>
                    <div className="blog-all-topics-name">
                            {items.name}
                    </div>

                    </NavLink>
                    </div>
                    )
                    }
                })}

            </div>





                    </div> */}
                </div>

            <Footerpage/>
        </>
    )

}

export default QRCodeScanner;


